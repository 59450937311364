// default panel modular selectors
export const defaultPanelSettingsModalOpenSelector = (state, props) => state['Settings'].modalOpen;
export const defaultPanelSettingsModalDataSelector = (state, props) => state['Settings'].modalData;
export const defaultPanelMessagesIsEditingReservationSelector = (state, props) =>
    state['Messages'].isEditingReservation;
export const defaultPanelDataSensorGraphsVisibleSelector = (state, props) =>
    state['NRS Graph'].sensorGraphsVisible;
export const defaultPanelDataIntervalSelector = (state, props) => ({
    number: state['NRS Graph'].interval.number,
    unit: state['NRS Graph'].interval.unit,
});
export const defaultPanelDataIntervalExpandedSelector = (state, props) =>
    state['NRS Graph'].intervalExpanded;
export const defaultPanelDataUISelector = (state, props) => state['NRS Graph'];
export const defaultPanelDataEventIDSelector = (state, props) => state['NRS Graph'].eventID;
export const defaultPanelGraphEndpointsSelector = (state, props) => ({
    start: state['NRS Graph'].start,
    end: state['NRS Graph'].end,
});
export const defaultPanelNRSSettingsListSelector = (state, props) =>
    state['Sensors'].NRSSettingsList.filter(zone => zone.zoneKey === props.zoneKey);
export const defaultPanelMessagesErrorSelector = (state, props) => state['Messages'].hasError;
export const defaultPanelMessagesModalOpenSelector = (state, props) => state['Messages'].modalOpen;
export const defaultPanelMessagesModalDataSelector = (state, props) => state['Messages'].modalData;
export const defaultPanelMessagesLoadingSelector = (state, props) => state['Messages'].isLoading;
export const defaultPanelEventRangeSelector = (state, props) => ({
    start: state['Events'].start,
    end: state['Events'].end,
});
export const defaultPanelIsAddingNoteSelector = (state, props) => state['Events'].isAddingNote;
export const defaultPanelIsInitSelector = (state, props) => state.init;
export const defaultPanelUISelector = (state, props) => state;
export const defaultPanelKeySelector = (state, props) => state.panelKey;

import { createSelector } from 'reselect';
import tz from 'timezone';
import {
    propertySensorsDataSelector,
    alertsSelector,
    sensorDecibelReportSelector,
    sensorMacCountReportSelector,
    propertiesSelector,
} from './selectors.data';
import { globalizeSelectors } from '../constants';
import { dateFormatString } from '../dates';
export const createDefaultPanelGraphSelector = rootSelector =>
    createSelector(
        propertySensorsDataSelector,
        alertsSelector,
        ...globalizeSelectors(
            [
                defaultPanelGraphEndpointsSelector,
                defaultPanelDataSensorGraphsVisibleSelector,
                defaultPanelIsInitSelector,
                defaultPanelKeySelector,
            ],
            rootSelector
        ),
        (sensorsData, alerts, ends, sensorGraphsVisible, init, panelKey) => {
            const alert = alerts
                .filter(alert => alert.end === null)
                .find(alertByProperty => alertByProperty.propertyKey === panelKey);
            const propertySensorsData = sensorsData.find(
                sensorsDataByProperty => sensorsDataByProperty.propertyKey === panelKey
            ) || { timezone: 'string', propertyKey: 'string', data: [], zones: [] };
            let graph = {
                data: propertySensorsData.data,
                sensorGraphsVisible: sensorGraphsVisible,
                start:
                    alert && !init
                        ? tz(alert.start, '-15 minutes', dateFormatString.RFC3339)
                        : ends.start,
                end:
                    alert && !init
                        ? tz(alert.start, '+15 minutes', dateFormatString.RFC3339)
                        : ends.end,
                timezone: propertySensorsData.timezone,
                sensors: propertySensorsData.zones,
                alert,
            };
            return graph;
        }
    );

export const createDefaultPanelGraphTabIntervals = rootSelector =>
    createSelector(
        ...globalizeSelectors([defaultPanelDataIntervalSelector], rootSelector),
        selected => {
            let selectedText = `${selected.number} ${selected.unit}`;
            let optionsNumbers = [30, 1, 3, 1, 7];
            let optionsUnits = ['minutes', 'hours', 'hours', 'days', 'days'];
            let optionsText = optionsNumbers.map((number, i) => `${number} ${optionsUnits[i]}`);
            let options = optionsText.map((text, id) => ({
                id,
                text,
                selected: text === selectedText,
                number: optionsNumbers[id],
                unit: optionsUnits[id],
            }));
            return options;
        }
    );

export const createDefaultPanelDecibelGraphSelector = rootSelector =>
    createSelector(
        sensorDecibelReportSelector,
        propertySensorsDataSelector,
        ...globalizeSelectors(
            [
                defaultPanelGraphEndpointsSelector,
                defaultPanelDataSensorGraphsVisibleSelector,
                defaultPanelKeySelector,
            ],
            rootSelector
        ),
        (sensorDecibelReport, sensorsData, ends, sensorGraphsVisible, panelKey) => {
            const propertySensorsData = sensorsData.find(
                sensorsDataByProperty => sensorsDataByProperty.propertyKey === panelKey
            ) || { timezone: 'string', propertyKey: 'string', data: [], zones: [] };
            const propertySensorDecibelData = Object.entries(sensorDecibelReport).reduce(
                (acc, [key, value]) => {
                    if (value.propertyKey === panelKey)
                        return {
                            [key]: value.data.map(d => ({ decibels: d.dBspl, time: d.sampleTime })),
                            ...acc,
                        };
                    else return acc;
                },
                {}
            );
            let graph = {
                data: propertySensorDecibelData,
                deviceVisible: sensorGraphsVisible,
                start: ends.start,
                end: ends.end,
                timezone: propertySensorsData.timezone,
            };
            return graph;
        }
    );

export const createDefaultPanelMacCountGraphSelector = rootSelector =>
    createSelector(
        sensorMacCountReportSelector,
        propertiesSelector,
        ...globalizeSelectors(
            [
                defaultPanelGraphEndpointsSelector,
                defaultPanelDataSensorGraphsVisibleSelector,
                defaultPanelKeySelector,
            ],
            rootSelector
        ),
        (sensorMacCountReport, properties, ends, sensorGraphsVisible, panelKey) => {
            const propertySensorMacCountData = sensorMacCountReport[panelKey] || {};
            let graph = {
                data: propertySensorMacCountData,
                sensorGraphsVisible: sensorGraphsVisible,
                start: ends.start,
                end: ends.end,
                timezone: (properties.find(property => property.key === panelKey) || {}).timezone,
            };
            return graph;
        }
    );
