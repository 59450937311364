import {
    DELAY_SLIDE_OPEN_DETAIL_PANEL_COMPLETE,
    POP_ROW_CLICK,
    POP_ROW_BLUR,
    FETCH_SENSORS,
} from '../actions';
import { createFilterReducer, defaultFilterState } from './filter';
import tableHeaderDropdownReducer from './tableHeaderDropdown';
import createAsyncStateReducer from './async';
import { composeReducers } from './utils';

const fetchSensorsReducer = createAsyncStateReducer(FETCH_SENSORS);

const defaultSensorsFiltersState = {
    model: null,
    status: null,
    city: null,
    reservationStatus: null,
    propertyType: null,
    tags: [],
};

export const defaultSensorsTableState = {
    headers: {
        'Sensor Name': null,
        Model: null,
        Status: null,
        Property: { options: ['Ascending', 'Descending'], selected: 'Ascending', open: false },
        'Property Tags': null,
    },
    rows: [],
    isLoading: true,
    hasError: null,
    expandedRow: { popKey: 'u' },
    isExpanded: false,
    filterOpen: defaultFilterState.filterOpen,
    filters: defaultSensorsFiltersState,
};

const sensorsTable = (state, action) => {
    if (state === undefined) {
        state = defaultSensorsTableState;
    }
    switch (action.type) {
        case FETCH_SENSORS.SUCCESS:
            return Object.assign({}, state, {
                rows: action.json.map((sensor, id) => ({
                    index: id,
                    isLoading: false,
                    hasError: null,
                    init: false,
                })),
            });
    }
    if (action.table == 'sensors') {
        switch (action.type) {
            case DELAY_SLIDE_OPEN_DETAIL_PANEL_COMPLETE:
            case POP_ROW_CLICK:
                return Object.assign({}, state, { expandedRow: { popKey: action.popKey } });
            case POP_ROW_BLUR:
                return Object.assign({}, state, { expandedRow: { popKey: 'u' } });
            default:
                return Object.assign(
                    {},
                    state,
                    createFilterReducer(defaultSensorsFiltersState)(
                        { filterOpen: state.filterOpen, filters: state.filters },
                        action
                    ),
                    tableHeaderDropdownReducer({ headers: state.headers }, action)
                );
        }
    } else {
        return state;
    }
};

export default composeReducers([sensorsTable, fetchSensorsReducer], defaultSensorsTableState);
