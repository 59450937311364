import {
    DELETE_SENSOR,
    TOGGLE_SENSOR_PHOTO,
    FETCH_SENSOR_PHOTO,
    READ_JPEG,
    LOAD_IMG_SRC,
    CACHE_SENSOR_PHOTO,
    CLEAR_SENSOR_PHOTO,
    CLOSE_SENSOR_PHOTO,
    FETCH_SENSOR_EVENTS,
    PROPERTY_DETAIL_ICON_CLICK,
} from './actions.types';
import { azSighV1Api } from '../remote2';

export function deleteSensor(propertyKey, zoneKey) {
    return dispatch => {
        // return api.propertiesPropertyKeyZonesZoneKeyDelete(propertyKey, zoneKey);
        return azSighV1Api.aZSighV1DeleteAZ(propertyKey, zoneKey);
    };
}

export const deleteSensorSuccess = () => {
    return {
        type: DELETE_SENSOR.SUCCESS,
    };
};

export const deleteSensorFailure = err => {
    return {
        type: DELETE_SENSOR.FAILURE,
        error: err,
    };
};

export const toggleSensorPhoto = () => ({
    type: TOGGLE_SENSOR_PHOTO,
});

export const fetchSensorPhotoBegin = () => {
    return {
        type: FETCH_SENSOR_PHOTO.BEGIN,
    };
};

export const fetchSensorPhotoSuccess = () => {
    return {
        type: FETCH_SENSOR_PHOTO.SUCCESS,
    };
};

export const fetchSensorPhotoFailure = err => {
    return {
        type: FETCH_SENSOR_PHOTO.FAILURE,
        err,
    };
};

export const readJPEGBegin = () => {
    return {
        type: READ_JPEG.BEGIN,
    };
};

export const readJPEGSuccess = () => {
    return {
        type: READ_JPEG.SUCCESS,
    };
};

export const loadImgSrcBegin = () => {
    return {
        type: LOAD_IMG_SRC.BEGIN,
    };
};

export const loadImgSrcSuccess = () => {
    return {
        type: LOAD_IMG_SRC.SUCCESS,
    };
};

export const cacheSensorPhoto = (dataURL, sensorKey) => {
    return {
        type: CACHE_SENSOR_PHOTO,
        data: dataURL,
        key: sensorKey,
    };
};

export const clearSensorPhoto = sensorKey => {
    return {
        type: CLEAR_SENSOR_PHOTO,
        sensorKey,
    };
};

export const closeSensorPhoto = () => {
    return {
        type: CLOSE_SENSOR_PHOTO,
    };
};

export const fetchSensorEventsBegin = () => {
    return {
        type: FETCH_SENSOR_EVENTS.BEGIN,
        table: 'sensors',
    };
};

export const fetchSensorEventsSuccess = () => {
    return {
        type: FETCH_SENSOR_EVENTS.SUCCESS,
    };
};

export const fetchSensorEventsFailure = () => {
    return {
        type: FETCH_SENSOR_EVENTS.FAILURE,
    };
};

export const propertyDetailIconClick = propertyKey => {
    return {
        type: PROPERTY_DETAIL_ICON_CLICK,
        propertyKey,
    };
};
