import {
    SLIDE_PANEL_END,
} from '../actions';
import createDefaultPanelReducer from './createDefaultPanelReducer';

const { defaultState: defaultPropertiesPanelState, reducer: defaultPanelReducer } = createDefaultPanelReducer('properties');

export { defaultPropertiesPanelState };

const propertiesPanel = (state, action) => {
    if(state === undefined){
        state = defaultPropertiesPanelState;
    }
    switch(action.type){
        case SLIDE_PANEL_END:
            if(action.table === 'properties'){
                return Object.assign({}, state, {expanded: true, slideOutFinished: true, panelKey: action.propertyKey});
            } else {
                return state;
            }
        default: 
            return defaultPanelReducer(state, action);
    }
};

export default propertiesPanel;
