export const defaultMobilePanelState = {
    expanded: false,
    detailsPage: 'NRS Graph',
    isLoading: false,
    init: false,
    hasError: null,
    'NRS Graph': {
        interval: { number: 30, unit: 'minutes' },
        intervalExpanded: false,
        sensorGraphsVisible: [],
        start: null,
        end: null,
    },
    Sensors: {
        modalOpen: false,
    },
    Events: {},
    Settings: {},
};

const mobilePanel = (state, action) => {
    if (state === undefined) {
        state = defaultMobilePanelState;
    }
    if (
        action.table === 'currentAlerts' ||
        action.table === 'properties' ||
        action.table === 'mobileDashboard'
    ) {
        switch (action.type) {
            case 'POP_ROW_CLICK':
                return Object.assign({}, state, { expanded: true });
            case 'POP_ROW_BLUR':
                return Object.assign({}, state, { expanded: false, init: false });
            case 'NAVIGATE_DETAILS_PAGE':
                return Object.assign({}, state, { detailsPage: action.detailsPage });
            case 'FETCH_PROPERTY_DETAIL_BEGIN':
                return Object.assign({}, state, { isLoading: true });
            case 'FETCH_PROPERTY_DETAIL_SUCCESS':
                return Object.assign({}, state, {
                    isLoading: false,
                    init: true,
                    'NRS Graph': {
                        ...state['NRS Graph'],
                        sensorGraphsVisible: action.json.zones.map(o => true),
                    },
                });
            case 'FETCH_PROPERTY_DETAIL_FAILURE':
                return Object.assign({}, state, { isLoading: false, hasError: action.err });
            case 'TOGGLE_DATA_INTERVAL_CLICK':
                return Object.assign({}, state, {
                    'NRS Graph': {
                        ...state['NRS Graph'],
                        intervalExpanded: !state['NRS Graph'].intervalExpanded,
                    },
                });
            case 'TOGGLE_DATA_INTERVAL_SELECT':
                return Object.assign({}, state, {
                    'NRS Graph': {
                        ...state['NRS Graph'],
                        interval: { number: action.option.number, unit: action.option.unit },
                        start: action.start,
                        end: action.end,
                    },
                });
            case 'TOGGLE_DATA_INTERVAL_BLUR':
                return Object.assign({}, state, {
                    'NRS Graph': {
                        ...state['NRS Graph'],
                        intervalExpanded: false,
                    },
                });
            case 'TOGGLE_SENSOR_GRAPH_VISIBILITY':
                return Object.assign({}, state, {
                    'NRS Graph': {
                        ...state['NRS Graph'],
                        sensorGraphsVisible: state['NRS Graph'].sensorGraphsVisible.map(
                            (device, i) => (i === action.index ? !device : device)
                        ),
                    },
                });
            case 'EDIT_NRS':
                return Object.assign({}, state, {
                    Sensors: {
                        ...state['Sensors'],
                        modalOpen: true,
                    },
                });
            case 'CLOSE_SENSORS_MODAL':
                return Object.assign({}, state, {
                    Sensors: {
                        ...state['Sensors'],
                        modalOpen: false,
                    },
                });
            default:
                return state;
        }
    } else {
        return state;
    }
};
export default mobilePanel;
