import { SEND_MESSAGE, SEND_MESSAGE_FAILURE } from './actions.types';

import { reservationSighV1Api } from '../remote2';

export const sendMessageSuccess = () => {
    return {
        type: SEND_MESSAGE.SUCCESS,
    };
};

export const sendMessageFailure = err => {
    return {
        type: SEND_MESSAGE.FAILURE,
        err,
    };
};

export function sendMessage(body, reservationID) {
    return dispatch => {
        // return api
        //     .messagesReservationsReservationIDPost(reservationID, body)
        return reservationSighV1Api
            .reservationsSighV1SendMessage(reservationID, body)
            .then(() => dispatch(sendMessageSuccess()))
            .catch(err => dispatch(sendMessageFailure(err)));
    };
}
