import { FETCH_EVENTS, FETCH_MORE_EVENTS, FETCH_ALERTS } from './actions.types';
import { logoutIfUnauthorized } from './actions.user';
import { eventV1Api } from '../remote2';
import { asyncActionCreator } from './actions.async';
import { fetchAlertsSuccess, fetchAlertsFailure } from './actions.dashboard';
import { PROTECTED } from 'redux-jwt-protected-middleware';

export function justFetchAlerts() {
    const thunk = dispatch => {
        return eventV1Api
            .eventV1GetAlertsWithHttpInfo({ duration: '60' })
            .then(({ data: json }) => {
                dispatch(fetchAlertsSuccess(json));
            })
            .catch(({ response }) => {
                dispatch(fetchAlertsFailure(response));
                dispatch(logoutIfUnauthorized(response));
            });
    };
    thunk[PROTECTED] = true;
    return thunk;
}

export const fetchAlerts = asyncActionCreator({
    type: FETCH_ALERTS,
    createThunk: () => dispatch => {
        return eventV1Api.eventV1GetAlertsWithHttpInfo({}).then(({ data, response }) => {
            dispatch(logoutIfUnauthorized(response));
            return data;
        });
    },
});

export const fetchEvents = (after, { before, propertyKey, zoneKey, types, limit }, table) =>
    asyncActionCreator({
        type: FETCH_EVENTS,
        beginPayload: { table },
        successPayload: { table },
        createThunk: (after, { before, propertyKey, zoneKey, types, limit }) => dispatch => {
            return eventV1Api.eventV1GetEvents(after, {
                before,
                propertyKey,
                zoneKey,
                type: types ? types.join('|') : null,
                offset: 0,
                limit: limit || 20,
            });
        },
    })(after, { before, propertyKey, zoneKey, types, limit }, table);

export const fetchMoreEvents = (after, { before, propertyKey, types, offset, limit }, table) =>
    asyncActionCreator({
        type: FETCH_MORE_EVENTS,
        beginPayload: { table },
        successPayload: { table },
        createThunk: (after, { before, propertyKey, types, offset, limit }) => dispatch => {
            return eventV1Api.eventV1GetEvents(after, {
                before,
                propertyKey,
                offset,
                type: types ? types.join('|') : null,
                limit: limit || 20,
            });
        },
    })(after, { before, propertyKey, types, offset, limit }, table);
