import React from 'react';
import { Provider } from 'react-redux';
import Main from '../components/Main';
import { ConnectedRouter } from 'connected-react-router';
import RootErrorBoundary from '../components/RootErrorBoundary';

const Root = ({ store, history }) => (
    <RootErrorBoundary>
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <Main />
            </ConnectedRouter>
        </Provider>
    </RootErrorBoundary>
);

export default Root;
