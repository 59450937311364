import * as selectors from './selectors.defaultPanel';
import { globalizeSelectors } from '../constants';

const rootSelector = state => state.ui.properties.propertiesPanel;
const globalSelectors = globalizeSelectors(selectors, rootSelector);

export const {
    defaultPanelSettingsModalOpenSelector: getPropertyDetailSettingsModalOpen,
    defaultPanelSettingsModalDataSelector: getPropertyDetailSettingsModalData,
    defaultPanelMessagesIsEditingReservationSelector: getPropertyDetailMessagesIsEditingReservation,
    defaultPanelDataSensorGraphsVisibleSelector: getPropertyDetailDataSensorGraphsVisible,
    defaultPanelDataIntervalSelector: getPropertyDetailDataInterval,
    defaultPanelDataIntervalExpandedSelector: getPropertyDetailDataIntervalExpanded,
    defaultPanelDataUISelector: getPropertyDetailDataUI,
    defaultPanelDataEventIDSelector: getPropertyDetailDataEventID,
    defaultPanelGraphEndpointsSelector: getPropertyDetailGraphEndpoints,
    defaultPanelNRSSettingsListSelector: getPropertyDetailNRSSettingsList,
    defaultPanelMessagesErrorSelector: getPropertyDetailMessagesError,
    defaultPanelMessagesModalOpenSelector: getPropertyDetailMessagesModalOpen,
    defaultPanelMessagesModalDataSelector: getPropertyDetailMessagesModalData,
    defaultPanelMessagesLoadingSelector: getPropertyDetailMessagesLoading,
    defaultPanelEventRangeSelector: getPropertyDetailEventRange,
    defaultPanelIsAddingNoteSelector: propertyDetailEventsIsAddingNoteSelector,
    defaultPanelIsInitSelector: getPropertyDetailPanelInit,
    defaultPanelUISelector: getPropertyDetailPanelUI,
    defaultPanelKeySelector: getPropertyDetailPanelKey,
} = globalSelectors;
export default rootSelector;
