import { propertiesSighV1Api } from '../remote2';
import { FETCH_PROPERTY_DATA, DELETE_PROPERTY, FETCH_PROPERTIES } from './actions.types';
import { fetchPropertiesBegin, fetchPropertiesSuccess } from './actions.properties';
import { finishLoading } from './actions.dashboard';
import {
    fetchPropertyDetailBegin,
    fetchPropertyDetailSuccess,
    fetchPropertyDetailFailure,
} from './actions.properties';
import {
    refreshPropertyDetailSuccess,
    panGraph,
    toggleGraphIntervalSelect,
} from './actions.defaultPanel';
import { dateFormatString } from '../dates';
import tz from 'timezone';
import { asyncActionCreator } from './actions.async';
import { logoutIfUnauthorized } from './actions.user';

export const fetchPropertyDataFailure = err => {
    return {
        type: FETCH_PROPERTY_DATA.FAILURE,
        err,
    };
};

export const fetchPropertyDataSuccess = (json, key) => {
    return {
        type: FETCH_PROPERTY_DATA.SUCCESS,
        json,
        key,
    };
};

export const fetchProperties = asyncActionCreator({
    type: FETCH_PROPERTIES,
    createThunk: () => dispatch => {
        return propertiesSighV1Api
            .propertiesSighV1GetAllPropertiesWithHttpInfo({})
            .then(({ data, response }) => {
                dispatch(logoutIfUnauthorized(response));
                return data;
            });
    },
});

export function fetchPropertyDetailData(propertyKey, start, end, table, eventID) {
    return dispatch => {
        dispatch(fetchPropertyDetailBegin(table));
        return propertiesSighV1Api
            .propertiesSighV1GetGraphData(propertyKey, { start: start, eventId: eventID, end: end })
            .then(json => {
                dispatch(fetchPropertyDataSuccess(json, propertyKey));
                dispatch(fetchPropertyDetailSuccess(table, json));
            })
            .catch(err => {
                dispatch(fetchPropertyDataFailure('Error: property not found'));
                dispatch(fetchPropertyDetailFailure('Error: property not found', table));
            });
    };
}

export function refreshPropertyDetailData(propertyKey, interval, table) {
    return dispatch => {
        let start = tz(
            new Date(),
            `-${interval.number} ${interval.unit}`,
            dateFormatString.toServer
        );
        let end = tz(new Date(), dateFormatString.toServer);
        return propertiesSighV1Api
            .propertiesSighV1GetGraphData(propertyKey, {
                start: start,
                end: end,
            })
            .then(json => {
                dispatch(refreshPropertyDetailSuccess(table, json));
                dispatch(panGraph(table, start, end));
                dispatch(fetchPropertyDataSuccess(json, propertyKey));
            })
            .catch(err => {
                dispatch(fetchPropertyDataFailure('Error loading data'));
            });
    };
}

export function fetchSensorDataAndPanGraph(propertyKey, start, end, table) {
    return dispatch => {
        dispatch(fetchPropertyDetailBegin(table));
        return propertiesSighV1Api
            .propertiesSighV1GetGraphData(propertyKey, {
                start: start,
                end: end,
            })
            .then(json => {
                dispatch(fetchPropertyDataSuccess(json, propertyKey));
                dispatch(fetchPropertyDetailSuccess(table, json));
                dispatch(panGraph(table, start, end));
            })
            .catch(err => {
                dispatch(fetchPropertyDataFailure(err));
                dispatch(fetchPropertyDetailFailure(err, table));
            });
    };
}

export function fetchSensorDataAndSelectInterval(table, propertyKey, start, end, interval) {
    return dispatch => {
        dispatch(fetchPropertyDetailBegin(table));
        return propertiesSighV1Api
            .propertiesSighV1GetGraphData(propertyKey, {
                start: start,
                end: end,
            })
            .then(json => {
                dispatch(fetchPropertyDataSuccess(json, propertyKey));
                dispatch(fetchPropertyDetailSuccess(table, json));
                dispatch(toggleGraphIntervalSelect(table, start, end, interval));
            })
            .catch(err => {
                dispatch(fetchPropertyDataFailure(err));
                dispatch(fetchPropertyDetailFailure(err, table));
            });
    };
}

export const deleteProperty = propertyKey =>
    asyncActionCreator({
        type: DELETE_PROPERTY,
        createThunk: propertyKey => dispatch => {
            return propertiesSighV1Api.propertiesSighV1DeleteProperty(propertyKey);
        },
    })(propertyKey);
