import React from 'react';
import { Loader } from '@noiseaware/elements';

const LoadingOverlay = () => (
    <div className="page-loading-overlay">
        <Loader className="page-loader" />
    </div>
);

export default LoadingOverlay;
