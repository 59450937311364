import { miscSighV1Api } from '../remote2';
import { FETCH_SENSOR_DECIBEL_REPORT } from './actions.types';
import tz from 'timezone';
import { dateFormatString } from '../dates';
import { panGraph, toggleGraphIntervalSelect } from './actions.defaultPanel';

export function fetchSensorDecibelReport(table, sensorKey, start, end) {
    return dispatch => {
        dispatch(fetchSensorDecibelReportBegin(table));
        return miscSighV1Api
            .miscSighV1GetTelemetryData(end, sensorKey, start)
            .then(json => {
                dispatch(fetchSensorDecibelReportSuccess(table, json, sensorKey));
            })
            .catch(err => {
                dispatch(fetchSensorDecibelReportFailure(err, table));
            });
    };
}

export function fetchSensorDecibelReportAndPanGraph(table, sensorKey, propertyKey, start, end) {
    return dispatch => {
        dispatch(fetchSensorDecibelReportBegin(table));
        return miscSighV1Api
            .miscSighV1GetTelemetryData(end, sensorKey, start)
            .then(json => {
                dispatch(fetchSensorDecibelReportSuccess(table, json, sensorKey, propertyKey));
                dispatch(panGraph(table, start, end));
            })
            .catch(err => {
                dispatch(fetchSensorDecibelReportFailure(err, table));
            });
    };
}

export function fetchSensorDecibelReportAndSelectInterval(
    table,
    sensorKey,
    propertyKey,
    start,
    end,
    option
) {
    return dispatch => {
        dispatch(fetchSensorDecibelReportBegin(table));
        return miscSighV1Api
            .miscSighV1GetTelemetryData(end, sensorKey, start)
            .then(json => {
                dispatch(fetchSensorDecibelReportSuccess(table, json, sensorKey, propertyKey));
                dispatch(toggleGraphIntervalSelect(table, start, end, option));
            })
            .catch(err => {
                dispatch(fetchSensorDecibelReportFailure(err, table));
            });
    };
}

export function refreshSensorDecibelReport(sensorKey, interval, table, propertyKey) {
    return dispatch => {
        let start = tz(
            new Date(),
            `-${interval.number} ${interval.unit}`,
            dateFormatString.toServer
        );
        let end = tz(new Date(), dateFormatString.toServer);
        return miscSighV1Api
            .miscSighV1GetTelemetryData(end, sensorKey, start)
            .then(json => {
                dispatch(panGraph(table, start, end));
                dispatch(fetchSensorDecibelReportSuccess(table, json, sensorKey, propertyKey));
            })
            .catch(err => {
                dispatch(fetchSensorDecibelReportFailure(err, table));
            });
    };
}

export const fetchSensorDecibelReportBegin = table => {
    return {
        type: FETCH_SENSOR_DECIBEL_REPORT.BEGIN,
        table,
    };
};

export const fetchSensorDecibelReportSuccess = (table, json, sensorKey, propertyKey) => {
    return {
        type: FETCH_SENSOR_DECIBEL_REPORT.SUCCESS,
        table,
        json,
        sensorKey,
        propertyKey,
    };
};

export const fetchSensorDecibelReportFailure = (err, table) => {
    return {
        type: FETCH_SENSOR_DECIBEL_REPORT.FAILURE,
        table,
        err,
    };
};
