export const defaultMobileAdminState = {
    page: 'Admin Tools',
};

const mobileAdmin = (state, action) => {
    if (state === undefined) {
        state = {
            page: 'Admin Tools',
        };
    }
    if (action.table === 'mobileAdmin') {
        switch (action.type) {
            case 'NAVIGATE_MOBILE_PAGE':
                return Object.assign({}, state, { page: action.page });
            default:
                return state;
        }
    } else {
        return state;
    }
};

export default mobileAdmin;
