import {
    put,
    take,
    call,
    select,
    fork,
    race,
    cancel,
    flush,
    actionChannel,
    all,
} from 'redux-saga/effects';
import { resetPanel, delaySlideOpenDetailPanelComplete } from '../actions';
import {
    POP_ROW_BLUR,
    HIDE_PANEL_CLICK,
    SENSOR_DETAIL_ICON_CLICK,
    POP_ROW_CLICK,
    DELAY_SLIDE_OPEN_DETAIL_PANEL_COMPLETE,
    NAVIGATE_DETAILS_PAGE,
    TOGGLE_DATA_AUTO_REFRESH,
    OPEN_CONFIRM_DELETE_MODAL,
    PAN_GRAPH,
} from '../actions';
import { getCurrentAlertsDetailGraphEndpoints } from '../selectors';
import { sleep } from '../constants';
import {
    watchFetchPropertyData,
    watchFetchPropertyMacCountData,
    watchGraphTypeChangeAndFetchData,
} from './sagas.propertiesPanel';
import { createAnalyticsSaga, sendEventToGoogleAnalytics } from './sagas.analytics';

const actionsThatCloseCurrentAlertsDetailPanel = [
    POP_ROW_BLUR,
    HIDE_PANEL_CLICK,
    '@@router/LOCATION_CHANGE',
    SENSOR_DETAIL_ICON_CLICK,
];

function* openPropertyDetailPanel(propertyKey) {
    yield call(sleep, 100);
    yield put(delaySlideOpenDetailPanelComplete({ table: 'currentAlerts', popKey: propertyKey }));
}

export default function* watchOpenCurrentAlertsDetailPanel() {
    while (true) {
        const { popKey: propertyKey, table } = yield take(POP_ROW_CLICK);
        if (table === 'currentAlerts') {
            const openPropertyDetailPanelSaga = yield fork(openPropertyDetailPanel, propertyKey);
            const { continueAction, cancelAction } = yield race({
                continueAction: take(DELAY_SLIDE_OPEN_DETAIL_PANEL_COMPLETE),
                cancelAction: take(actionsThatCloseCurrentAlertsDetailPanel),
            });
            if (cancelAction) {
                yield cancel(openPropertyDetailPanelSaga);
                continue;
            }
            yield* fetchCurrentAlertsGraphAssets({
                propertyKey,
                eventID: '',
                table: 'currentAlerts',
            });
        }
    }
}

function* fetchCurrentAlertsGraphAssets({ propertyKey, table }) {
    const { start, end } = yield select(getCurrentAlertsDetailGraphEndpoints);
    const cancelActionChannel = yield actionChannel(actionsThatCloseCurrentAlertsDetailPanel);
    yield fork(watchGraphTypeChangeAndFetchData, { propertyKey, table });
    yield all([
        call(watchFetchPropertyData, { table, propertyKey, start, end }),
        // call(watchFetchPropertyMacCountData, { table, propertyKey, start, end }), // MAC_FEAT
    ]);
    const cancelActions = yield flush(cancelActionChannel);
    if (cancelActions.length) {
        yield put(resetPanel(table));
    }
}

export const currentAlertsPanelAnalyticsSaga = createAnalyticsSaga(
    {
        [NAVIGATE_DETAILS_PAGE]: function*(action) {
            yield* sendEventToGoogleAnalytics({
                eventName: 'switch_panel_tab',
                eventLabel: 'currentAlerts',
                tab: action.detailsPage,
            });
        },
        [PAN_GRAPH]: function*(action) {
            yield* sendEventToGoogleAnalytics({
                eventName: 'pan_graph',
                eventLabel: 'currentAlerts',
            });
        },
        [TOGGLE_DATA_AUTO_REFRESH]: function*(action) {
            yield* sendEventToGoogleAnalytics({
                eventName: 'toggle_auto_refresh_graph',
                eventLabel: 'currentAlerts',
            });
        },
        [OPEN_CONFIRM_DELETE_MODAL]: function*(action) {
            yield* sendEventToGoogleAnalytics({
                eventName: 'click_delete_property',
                eventLabel: 'currentAlerts',
            });
        },
    },
    action => action.table === 'currentAlerts'
);
