import { createTableHeadersDropdownSelector } from '../reducers/tableHeaderDropdown';
import { withRoot } from '../constants';
import { getAsyncState } from '../reducers/async';

const mountSelector = state => state.ui.dashboard.currentAlerts.currentAlertsTable;

export const getCurrentAlertsAsyncState = withRoot(mountSelector, getAsyncState);

export const currentAlertsUISelector = state => state.ui.dashboard.currentAlerts.currentAlertsTable;

export const getCurrentAlertsHeaders = state =>
    state.ui.dashboard.currentAlerts.currentAlertsTable.headers;

export const currentAlertsHeadersSelector = createTableHeadersDropdownSelector(
    getCurrentAlertsHeaders
);

export const getCurrentAlertsRow = (state, props) =>
    state.ui.dashboard.currentAlerts.currentAlertsTable.rows[props.id];

export const getExpandedCurrentAlertsRow = state =>
    state.ui.dashboard.currentAlerts.currentAlertsTable.expandedRow;
