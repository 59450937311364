import {
    FETCH_USER,
    SET_USER_TOKEN,
    LOGOUT_SUCCESS,
    UPDATE_USER,
    TOKEN_EXPIRED,
} from './actions.types';
import Cookies from 'js-cookie';
import { externalSites, authCookieDomain } from '../constants';
import { defaultPortalClient, usersSighV1Api } from '../remote2';
import { asyncActionCreator } from './actions.async';

export const fetchUser = asyncActionCreator({
    type: FETCH_USER,
    createThunk: () => dispatch => {
        return usersSighV1Api.usersSighV1GetUserWithHttpInfo().then(({ data, response }) => {
            dispatch(logoutIfUnauthorized(response));
            return data;
        });
    },
});

export const updateUser = (userKey, userObj, table) =>
    asyncActionCreator({
        type: UPDATE_USER,
        beginPayload: { table },
        createThunk: (userKey, userObj) => dispatch => {
            return usersSighV1Api.usersSighV1UpdateUser(userKey, userObj);
        },
    })(userKey, userObj, table);

export const setUserToken = token => {
    return {
        type: SET_USER_TOKEN,
        token,
    };
};

export const logoutSuccess = () => {
    return {
        type: LOGOUT_SUCCESS,
    };
};

export const tokenExpired = () => {
    return {
        type: TOKEN_EXPIRED,
    };
};

export function logout(redirect) {
    return dispatch => {
        // remove refreshToken Cookies
        Cookies.remove('refreshToken', { domain: authCookieDomain });
        // clear userToken
        dispatch(setUserToken(null));
        // clear in-memory API key
        defaultPortalClient.clearApiKey();
        // clear Redux store
        dispatch(logoutSuccess());
        // set location for login app to redirect back to once authentication works
        const redirectBack = window.location;
        // redirect to login app
        const redirectTo = externalSites.loginApp(redirectBack);
        window.location.replace(redirectTo);
    };
}

export function logoutIfUnauthorized(response) {
    return dispatch => {
        if (response.forbidden || response.unauthorized) {
            dispatch(logout());
        }
    };
}
