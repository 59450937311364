import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { Desktop, Mobile } from './Responsive';
import MobilePlaceholder from '../components-mobile/MobilePlaceholder';
import LoadingOverlay from '../components/LoadingOverlay';
import { connect } from 'react-redux';
import { userTokenValid, getRefreshToken, refreshTokenValid } from '../auth';
import { getUserTokenFromStore, getRefreshIsLoading } from '../selectors';
import { refreshUserToken, logout } from '../actions';

export const requiresAuthentication = Component => {
    class ComponentWithAuthentication extends React.Component {
        constructor(props) {
            super(props);
        }
        componentDidMount() {
            if (!userTokenValid(this.props.userToken)) {
                if (refreshTokenValid) {
                    this.props.refreshUserToken(getRefreshToken());
                } else {
                    this.props.logout();
                }
            }
        }
        render() {
            if (this.props.isLoading) {
                return <LoadingOverlay />;
            } else {
                return <Component {...this.props} />;
            }
        }
    }
    const mapStateToProps = state => {
        return {
            userToken: getUserTokenFromStore(state),
            isLoading: getRefreshIsLoading(state),
        };
    };
    const mapDispatchToProps = dispatch => {
        return {
            refreshUserToken: refreshToken => dispatch(refreshUserToken(refreshToken)),
            logout: () => dispatch(logout()),
        };
    };
    return connect(
        mapStateToProps,
        mapDispatchToProps
    )(ComponentWithAuthentication);
};

export const requiresAuthorization = Component => {
    const ComponentAuthorization = (user, ...props) => {
        if (user.isLoading) return null;
        else if (['God', 'Administrator'].includes(user.role)) {
            return <Component {...props} />;
        } else {
            return <Redirect to="/" />;
        }
    };
    ComponentAuthorization.displayName = `requiresAuthorization(${Component.displayName})`;
    return ComponentAuthorization;
};

export const desktopOnly = Component => {
    const DesktopOnlyComponent = props => (
        <Fragment>
            <Desktop>
                <Component {...props} />
            </Desktop>
            <Mobile>
                <MobilePlaceholder />
            </Mobile>
        </Fragment>
    );
    DesktopOnlyComponent.displayName = `desktopOnly(${Component.displayName})`;
    return DesktopOnlyComponent;
};
