import { createAsyncTypes } from './actions.async';
// actions.account
export const TOGGLE_NOTIFICATION_SUBSCRIPTION = 'TOGGLE_NOTIFICATION_SUBSCRIPTION';
export const TOGGLE_NOTIFICATIONS_SUBSCRIPTIONS = 'TOGGLE_NOTIFICATIONS_SUBSCRIPTIONS';
export const TOGGLE_GLOBAL_ALERTS = 'TOGGLE_GLOBAL_ALERTS';
export const FETCH_PROPERTY_PERMISSIONS = createAsyncTypes('FETCH_PROPERTY_PERMISSIONS');
export const UPDATE_USER = createAsyncTypes('UPDATE_USER');
export const TOGGLE_ALERT_PREFERENCE = 'TOGGLE_ALERT_PREFERENCE';
export const FETCH_ACCOUNT_PREFERENCES = createAsyncTypes('FETCH_ACCOUNT_PREFERENCES');
export const FETCH_PROPERTIES_NOTIFICATION_SETTINGS = createAsyncTypes(
    'FETCH_PROPERTIES_NOTIFICATION_SETTINGS'
);
export const FETCH_ALL_ACCOUNT_NOTIFICATION_SETTINGS = createAsyncTypes(
    'FETCH_ALL_ACCOUNT_NOTIFICATION_SETTINGS'
);
export const REMOVE_NOTIFICATION_EMAIL = 'REMOVE_NOTIFICATION_EMAIL';
export const REMOVE_NOTIFICATION_PHONE = 'REMOVE_NOTIFICATION_PHONE';
// actions.admin
export const FETCH_ORGANIZATION_USERS = createAsyncTypes('FETCH_ORGANIZATION_USERS');
export const TOGGLE_EDIT_USER = 'TOGGLE_EDIT_USER';
export const TOGGLE_PERMISSION_SETTING = 'TOGGLE_PERMISSION_SETTING';
export const TOGGLE_ALL_PERMISSION_SETTINGS = 'TOGGLE_ALL_PERMISSION_SETTINGS';
export const TOGGLE_INTEGRATION_SUCCESS = 'TOGGLE_INTEGRATION_SUCCESS';
export const TOGGLE_INTEGRATION_FAILURE = 'TOGGLE_INTEGRATION_FAILURE';
export const FETCH_INTEGRATION_PROPERTIES_SUCCESS = 'FETCH_INTEGRATION_PROPERTIES_SUCCESS';
export const FETCH_INTEGRATION_NEW_PROPERTIES = createAsyncTypes(
    'FETCH_INTEGRATION_NEW_PROPERTIES'
);
export const SETUP_INTEGRATION = 'SETUP_INTEGRATION';
// actions.adminIntegration
export const RESET_INTEGRATION_SETUP = 'RESET_INTEGRATION_SETUP';
export const TOGGLE_ALL_INTEGRATION_PROPERTIES = 'TOGGLE_ALL_INTEGRATION_PROPERTIES';
export const TOGGLE_INTEGRATION_PROPERTY = 'TOGGLE_INTEGRATION_PROPERTY';
export const CREATE_INTEGRATION = createAsyncTypes('CREATE_INTEGRATION');
// actions.dashboard
export const SELECT_HEADER_OPTION = 'SELECT_HEADER_OPTION';
export const TOGGLE_HEADER_DROPDOWN = 'TOGGLE_HEADER_DROPDOWN';
export const TOGGLE_HEADER_BLUR = 'TOGGLE_HEADER_BLUR';
export const TOGGLE_ROW_EXPAND = 'TOGGLE_ROW_EXPAND';
export const TOGGLE_ROW_BEGIN = 'TOGGLE_ROW_BEGIN';
export const TOGGLE_ROW_SUCCESS = 'TOGGLE_ROW_SUCCESS';
export const TOGGLE_ROW_FAIL = 'TOGGLE_ROW_FAIL';
export const TOGGLE_ROW_BLUR = 'TOGGLE_ROW_BLUR';
export const NAVIGATE_ROW_PAGE = 'NAVIGATE_ROW_PAGE';
export const FETCH_ALERTS = createAsyncTypes('FETCH_ALERTS');
// actions.data
export const FETCH_ADMIN_DATA_SUCCESS = 'FETCH_ADMIN_DATA_SUCCESS';
export const FETCH_PROPERTIES_DATA_SUCCESS = 'FETCH_PROPERTIES_DATA_SUCCESS';
export const FETCH_UPDATED_RESERVATION_DATA_SUCCESS = 'FETCH_UPDATED_RESERVATION_DATA_SUCCESS';
export const FETCH_ORGANIZATION_USER_PERMISSION_DATA = createAsyncTypes(
    'FETCH_ORGANIZATION_USER_PERMISSION_DATA'
);
export const FETCH_MESSAGES_DATA_SUCCESS = 'FETCH_MESSAGES_DATA_SUCCESS';
export const FETCH_INTEGRATIONS_LIST_DATA = createAsyncTypes('FETCH_INTEGRATIONS_LIST_DATA');
export const FETCH_INTEGRATIONS_DATA = createAsyncTypes('FETCH_INTEGRATIONS_DATA');
export const FETCH_PROPERTY_DATA = createAsyncTypes('FETCH_PROPERTY_DATA');
export const FETCH_PROPERTY_ICAL_LINKS_DATA_SUCCESS = 'FETCH_PROPERTY_ICAL_LINKS_DATA_SUCCESS';
export const FETCH_SENSOR_DECIBEL_REPORT = createAsyncTypes('FETCH_SENSOR_DECIBEL_REPORT');
export const FETCH_SENSOR_MAC_COUNT_REPORT = createAsyncTypes('FETCH_SENSOR_MAC_COUNT_REPORT');
export const REFRESH_USER_TOKEN = createAsyncTypes('REFRESH_USER_TOKEN');
// actions.dayPicker
export const UPDATE_DAY_PICKER_FROM = 'UPDATE_DAY_PICKER_FROM';
export const UPDATE_DAY_PICKER_TO = 'UPDATE_DAY_PICKER_TO';
// actions.defaultPanel
export const TOGGLE_ADD_NOTE = 'TOGGLE_ADD_NOTE';
export const PAN_GRAPH = 'PAN_GRAPH';
export const TOGGLE_DATA_AUTO_REFRESH = 'TOGGLE_DATA_AUTO_REFRESH';
export const REFRESH_PROPERTY_DETAIL_SUCCESS = 'REFRESH_PROPERTY_DETAIL_SUCCESS';
export const TOGGLE_DATA_INTERVAL_CLICK = 'TOGGLE_DATA_INTERVAL_CLICK';
export const TOGGLE_DATA_INTERVAL_SELECT = 'TOGGLE_DATA_INTERVAL_SELECT';
export const TOGGLE_DATA_INTERVAL_BLUR = 'TOGGLE_DATA_INTERVAL_BLUR';
export const TOGGLE_SENSOR_GRAPH_VISIBILITY = 'TOGGLE_SENSOR_GRAPH_VISIBILITY';
export const TOGGLE_EDIT_RESERVATION = 'TOGGLE_EDIT_RESERVATION';
export const CLOSE_EDIT_RESERVATION = 'CLOSE_EDIT_RESERVATION';
export const FETCH_UPDATED_RESERVATION = createAsyncTypes('FETCH_UPDATED_RESERVATION');
export const SENSOR_DETAIL_ICON_CLICK = 'SENSOR_DETAIL_ICON_CLICK';
export const FETCH_MESSAGES = createAsyncTypes('FETCH_MESSAGES');
export const OPEN_CONFIRM_DELETE_MODAL = 'OPEN_CONFIRM_DELETE_MODAL';
export const CLOSE_CONFIRM_DELETE_MODAL = 'CLOSE_CONFIRM_DELETE_MODAL';
export const FETCH_PROPERTY_ICAL_LINKS = createAsyncTypes('FETCH_PROPERTY_ICAL_LINKS');
// actions.dropdown
export const DROPDOWN_TOGGLE_CLICK = 'DROPDOWN_TOGGLE_CLICK';
export const DROPDOWN_BLUR = 'DROPDOWN_BLUR';
export const DROPDOWN_SELECT_OPTION = 'DROPDOWN_SELECT_OPTION';
// actions.events
export const FETCH_EVENTS = createAsyncTypes('FETCH_EVENTS');
export const FETCH_MORE_EVENTS = createAsyncTypes('FETCH_MORE_EVENTS');
// actions.filter
export const OPEN_FILTER_MENU = 'OPEN_FILTER_MENU';
export const CLOSE_FILTER_MENU = 'CLOSE_FILTER_MENU';
export const UPDATE_FILTERS = 'UPDATE_FILTERS';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const REMOVE_FILTER = 'REMOVE_FILTER';
// actions.header
export const TOGGLE_USER_MENU = 'TOGGLE_USER_MENU';
export const TOGGLE_SEARCH_FOCUS = 'TOGGLE_SEARCH_FOCUS';
export const TOGGLE_SEARCH_BLUR = 'TOGGLE_SEARCH_BLUR';
export const SEARCH_FOR_PROPERTY = createAsyncTypes('SEARCH_FOR_PROPERTY');
export const SELECT_PROPERTY_FROM_SEARCH = 'SELECT_PROPERTY_FROM_SEARCH';
// actions.messages
export const SEND_MESSAGE = createAsyncTypes('SEND_MESSAGE');
// actions.mobile.nav
export const NAVIGATE_MOBILE_PAGE = 'NAVIGATE_MOBILE_PAGE';
// actions.modal
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
// actions.nrsSettings
export const ADD_NRS_SETTING = 'ADD_NRS_SETTING';
export const DELETE_NRS_SETTING = 'DELETE_NRS_SETTING';
export const DRAG_START_SETTING = 'DRAG_START_SETTING';
export const DRAG_END_SETTING = 'DRAG_END_SETTING';
export const EDIT_NRS = 'EDIT_NRS';
export const CLOSE_SENSORS_MODAL = 'CLOSE_SENSORS_MODAL';
// actions.panel
export const NAVIGATE_DETAILS_PAGE = 'NAVIGATE_DETAILS_PAGE';
export const DELAY_SLIDE_OPEN_DETAIL_PANEL_COMPLETE = 'DELAY_SLIDE_OPEN_DETAIL_PANEL_COMPLETE';
export const SLIDE_PANEL_END = 'SLIDE_PANEL_END';
export const HIDE_PANEL_CLICK = 'HIDE_PANEL_CLICK';
export const RESET_PANEL = 'RESET_PANEL';
export const DELETE_PROPERTY = createAsyncTypes('DELETE_PROPERTY');
// actions.properties
export const POP_ROW_CLICK = 'POP_ROW_CLICK';
export const POP_ROW_BLUR = 'POP_ROW_BLUR';
export const FETCH_PROPERTIES = createAsyncTypes('FETCH_PROPERTIES');
export const FETCH_PROPERTY_DETAIL = createAsyncTypes('FETCH_PROPERTY_DETAIL');
// actions.reservations
export const FETCH_RESERVATIONS = createAsyncTypes('FETCH_RESERVATIONS');
export const DELETE_RESERVATION = createAsyncTypes('DELETE_RESERVATION');
// actions.sensors
export const FETCH_SENSORS = createAsyncTypes('FETCH_SENSORS');
// actions.sensors.panel
export const DELETE_SENSOR = createAsyncTypes('DELETE_SENSOR');
export const TOGGLE_SENSOR_PHOTO = 'TOGGLE_SENSOR_PHOTO';
export const FETCH_SENSOR_PHOTO = createAsyncTypes('FETCH_SENSOR_PHOTO');
export const READ_JPEG = createAsyncTypes('READ_JPEG');
export const LOAD_IMG_SRC = createAsyncTypes('LOAD_IMG_SRC');
export const CACHE_SENSOR_PHOTO = 'CACHE_SENSOR_PHOTO';
export const CLEAR_SENSOR_PHOTO = 'CLEAR_SENSOR_PHOTO';
export const CLOSE_SENSOR_PHOTO = 'CLOSE_SENSOR_PHOTO';
export const FETCH_SENSOR_EVENTS = createAsyncTypes('FETCH_SENSOR_EVENTS');
export const PROPERTY_DETAIL_ICON_CLICK = 'PROPERTY_DETAIL_ICON_CLICK';
// actions.store
export const GO_NEXT_CARD = 'GO_NEXT_CARD';
export const GO_BACK_CARD = 'GO_BACK_CARD';
// actions.user
export const FETCH_USER = createAsyncTypes('FETCH_USER');
export const SET_USER_TOKEN = 'SET_USER_TOKEN';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const TOKEN_EXPIRED = 'TOKEN_EXPIRED';
// index.js
export const TOGGLE_NAV = 'TOGGLE_NAV';
