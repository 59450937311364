import { DROPDOWN_BLUR, DROPDOWN_TOGGLE_CLICK, DROPDOWN_SELECT_OPTION } from '../actions';
import { createSelector } from 'reselect';
import { globalizeSelectors } from '../constants';

export const createDefaultDropdownState = defaultOption => ({
    dropdownExpanded: false,
    dropdownOptionSelected: defaultOption,
});

export const createDropdownReducer = defaultOption => {
    return (state, action) => {
        if (state === undefined) {
            state = createDefaultDropdownState(defaultOption);
        }
        switch (action.type) {
            case DROPDOWN_BLUR:
                return Object.assign({}, state, { dropdownExpanded: false });
            case DROPDOWN_TOGGLE_CLICK:
                return Object.assign({}, state, { dropdownExpanded: true });
            case DROPDOWN_SELECT_OPTION:
                return Object.assign({}, state, {
                    dropdownOptionSelected: action.option,
                    dropdownExpanded: false,
                });
            default:
                return state;
        }
    };
};

export const dropdownExpandedSelector = (state, props) => state.dropdownExpanded;

export const getDropdownOptionSelected = (state, props) => state.dropdownOptionSelected;

export const createDropdownOptionsSelector = (
    options = [],
    formatter = opt => opt
) => rootSelector =>
    createSelector(
        ...globalizeSelectors([getDropdownOptionSelected], rootSelector),
        selected => {
            let selectedText = formatter(selected);
            let deduplicatedOptions = [...new Set(options)];
            let optionsText = deduplicatedOptions.map(opt => formatter(opt));
            return optionsText.map((text, id) => ({
                id,
                text,
                selected: text === selectedText,
                option: deduplicatedOptions[id],
            }));
        }
    );
