import {
    OPEN_MODAL,
    CLOSE_MODAL,
} from '../actions';

const defaultModalState = {
    modalOpen: false,
    modalData: {},
};

const modalReducer = (state, action) => {
    if(state === undefined){
        state = defaultModalState;
    }
    switch(action.type){
        case OPEN_MODAL:
            return Object.assign({}, state, {
                modalOpen: true, modalData: action.modalData || {}
            });
        case CLOSE_MODAL:
            return Object.assign({}, state, {modalOpen: false, modalData: {}});
        default:
            return state;
    }
};

const modalOpenSelector = state => state.modalOpen;
const modalDataSelector = state => state.modalData;

export { defaultModalState, modalOpenSelector, modalDataSelector };
export default modalReducer;
