import {
    SELECT_HEADER_OPTION,
    TOGGLE_HEADER_DROPDOWN,
    TOGGLE_HEADER_BLUR,
    TOGGLE_ROW_EXPAND,
    TOGGLE_ROW_BEGIN,
    TOGGLE_ROW_SUCCESS,
    TOGGLE_ROW_FAIL,
    TOGGLE_ROW_BLUR,
    NAVIGATE_ROW_PAGE,
    FETCH_ALERTS,
    FETCH_FINISHED,
} from './actions.types';

export const selectHeaderOption = (table, header, option) => {
    return {
        type: SELECT_HEADER_OPTION,
        table,
        header,
        option,
    };
};

export const toggleHeaderDropDown = (table, header) => {
    return {
        type: TOGGLE_HEADER_DROPDOWN,
        table,
        header,
    };
};

export const toggleHeaderBlur = (table, header) => {
    return {
        type: TOGGLE_HEADER_BLUR,
        table,
        header,
    };
};

export const toggleRowExpand = (table, id) => {
    return {
        type: TOGGLE_ROW_EXPAND,
        table,
        id,
    };
};

export const toggleRowBegin = (table, id) => {
    return {
        type: TOGGLE_ROW_BEGIN,
        table,
        id,
    };
};

export const toggleRowSuccess = (table, id) => {
    return {
        type: TOGGLE_ROW_SUCCESS,
        table,
        id,
    };
};

export const toggleRowFailure = (table, id, err) => {
    return {
        type: TOGGLE_ROW_FAIL,
        table,
        id,
        err,
    };
};

export const toggleRowBlur = (table, id) => {
    return {
        type: TOGGLE_ROW_BLUR,
        table,
        id,
    };
};

export const rowNavigate = (table, id, page) => {
    return {
        type: NAVIGATE_ROW_PAGE,
        table,
        id,
        page,
    };
};

export const fetchAlertsBegin = () => {
    return {
        type: FETCH_ALERTS.BEGIN,
    };
};

export const fetchAlertsSuccess = alerts => {
    return {
        type: FETCH_ALERTS.SUCCESS,
        json: alerts,
    };
};

export const fetchAlertsFailure = err => {
    return {
        type: FETCH_ALERTS.FAILURE,
        err,
    };
};
