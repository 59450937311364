import { createSelector } from 'reselect';
import { reservationsSelector, propertiesSelector } from './selectors.data';
import tz from 'timezone';

export const getReservationsFilters = state => state.ui.reservations.reservationsTable.filters;

export const getCurrentReservationsHeaders = state =>
    state.ui.reservations.reservationsTable.headers;

export const currentReservationsUISelector = state => ({
    isLoading: state.ui.reservations.reservationsTable.isLoading,
    hasError: state.ui.reservations.reservationsTable.hasError,
});

export const currentReservationsHeadersSelector = createSelector(
    getCurrentReservationsHeaders,
    headers => {
        let keys = Object.keys(headers);
        let headerData = keys.map((key, i) => {
            if (headers[key] !== null) {
                return {
                    id: i,
                    text: key,
                    dropDown: {
                        options: headers[key].options.map((opt, oi) => ({
                            id: oi,
                            text: opt,
                            selected: headers[key].selected === opt ? true : false,
                        })),
                        open: headers[key].open,
                    },
                };
            } else {
                return { id: i, text: key };
            }
        });
        return headerData;
    }
);

export const currentReservationsSelector = createSelector(
    reservationsSelector,
    propertiesSelector,
    getReservationsFilters,
    currentReservationsHeadersSelector,
    currentReservationsUISelector,
    (reservations, properties, filters, headers, ui) => {
        let filteredReservations = reservations.filter(reservation => {
            let reservationProperty = null;
            for (let property of properties) {
                if (property.key === reservation.propertyKey) {
                    reservationProperty = property;
                    break;
                }
            }
            let matchesCriteria = true;
            if (reservationProperty && filters.tags.length) {
                filters.tags.forEach(tag => {
                    if (!reservationProperty.tags.includes(tag)) matchesCriteria = false;
                });
            }
            return matchesCriteria;
        });
        let rows = filteredReservations.map((reservation, index) => {
            return {
                id: index,
                popKey: reservation.id,
                contents: [
                    {
                        id: 0,
                        text:
                            reservation.guest &&
                            (reservation.guest.firstName || reservation.guest.lastName)
                                ? `${reservation.guest.firstName || ''} ${reservation.guest
                                      .lastName || ''}`
                                : '(No name)',
                    },
                    { id: 1, text: reservation.expectedGuestCount || '0' },
                    {
                        id: 2,
                        text:
                            reservation.guest && reservation.guest.email
                                ? reservation.guest.email
                                : '(No contact)',
                    },
                    { id: 3, text: `${reservation.start} - ${reservation.end}` },
                    {
                        id: 4,
                        text: reservation.propertyName || '(Unknown Property)',
                    },
                    { id: 5, text: reservation.type || '' },
                    { id: 6, icon: 'fas fa-external-link-alt' },
                ],
            };
        });
        return {
            headers,
            rows,
            isLoading: ui.isLoading,
            hasError: ui.hasError,
            numReservations: filteredReservations.length,
            totalReservations: reservations.length,
        };
    }
);
