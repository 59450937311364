import {
    OPEN_FILTER_MENU,
    CLOSE_FILTER_MENU,
    UPDATE_FILTERS,
    CLEAR_FILTERS,
    REMOVE_FILTER,
} from './actions.types';

export const openFilterMenu = table => {
    return {
        type: OPEN_FILTER_MENU,
        table,
    };
};

export const closeFilterMenu = table => {
    return {
        type: CLOSE_FILTER_MENU,
        table,
    };
};

export const updateFilters = (table, filters) => {
    return {
        type: UPDATE_FILTERS,
        table,
        filters,
    };
};

export const clearFilters = table => {
    return {
        type: CLEAR_FILTERS,
        table,
    };
};

export const removeFilter = (table, filter, value) => {
    return {
        type: REMOVE_FILTER,
        table,
        filter,
        value,
    };
};
