import { combineReducers } from 'redux';
import eventsTable, { defaultEventsTableState } from './eventsTable';
import eventsPanel, { defaultEventsPanelState } from './eventsPanel';

const events = combineReducers({
    eventsTable, eventsPanel
});

export default events;

export const defaultEventsState = {
    eventsTable: defaultEventsTableState,
    eventsPanel: defaultEventsPanelState
};