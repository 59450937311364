import React from 'react';
import VisibleNav from './VisibleNav';
import VisibleHeader from './VisibleHeader';

import '../../scss/App.scss';
import '@noiseaware/elements/dist/styles.css';

const App = props => (
    <div className="app-container">
        <VisibleNav />
        <div className="app-item">
            <VisibleHeader />
            {props.children}
        </div>
    </div>
);

export default App;
