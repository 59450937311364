import { createSelector } from 'reselect';
import { getAccountNotificationsSettingsColumns } from './selectors.account';

export const accountNotificationsSettingsColumnsSelector = createSelector(
    getAccountNotificationsSettingsColumns,
    (columns) => {
        let keys = Object.keys(columns);
        let colData = keys.map((key, i) => {
            if(columns[key].type === 'text' || columns[key].type === 'tags'){
                return {id: 'label', type: 'text', text: key};
            } else if(columns[key].type === 'toggle'){
                return {id: `toggle-${i}`, label: key, type: 'toggle', checked: false};
            } 
        });
        return colData;
    }
);