import { withRoot } from '../constants';
import { getAsyncState } from '../reducers/async';

const mountSelector = state => state.user;

export const getUserAsyncState = withRoot(mountSelector, getAsyncState);

export const getUserData = state => ({
    userKey: state.user.key,
    role: state.user.role,
    firstName: state.user.firstName,
    lastName: state.user.lastName,
    email: state.user.email,
    phone: state.user.phone,
    receiveAlertsApp: state.user.rcv_alerts_app,
    receiveAlertsInterval: state.user.rcv_alerts_interval,
    receiveAlertsNoise: state.user.rcv_alerts_noise,
    receiveAlertsDisconnect: state.user.rcv_alerts_disconnect,
    receiveAlertsActivation: state.user.rcv_alerts_activation,
    receiveAlertsGlobal: state.user.rcv_alerts_global,
    alertDevices: state.user.alertDevices,
    alertPhones: state.user.alertPhones,
    alertEmails: state.user.alertEmails,
    receiveEmails: state.user.rcv_alerts_email,
    receiveSMS: state.user.rcv_alerts_sms,
    isLoading: state.user.isLoading,
    hasError: state.user.hasError,
    orgName: state.user.organization.name,
    orgId: state.user.organization.key,
});

export const getUserDriftAttributes = state => ({
    'na.email': state.user.email,
    'na.firstName': state.user.firstName,
    'na.lastName': state.user.lastName,
    'na.orgName': state.user.organization.name,
});

export const getUserFirstName = state => state.user.firstName;

export const getUserId = state => state.user.key;

export const getUserName = state => ({
    firstName: state.user.firstName,
    lastName: state.user.lastName,
});

export const getUserOrgData = state => state.user.organization;

export const getUserOrgKey = state => state.user.organization.key;

export const getUserOrgName = state => state.user.organization.name;

export const getUserPermissions = state => state.user.permissions;

export const getUserRole = state => state.user.role;

export const getUserTokenFromStore = state => state.user.userToken;

export const getRefreshIsLoading = state => state.user.isLoadingRefresh;

export const getUserDefaultEmail = state => state.user.email;

export const getUserDefaultPhone = state => state.user.phone;

export const getRawUserData = state => ({
    key: state.user.key,
    role: state.user.role,
    firstName: state.user.firstName,
    lastName: state.user.lastName,
    email: state.user.email,
    phone: state.user.phone,
    rcv_alerts_app: state.user.rcv_alerts_app,
    rcv_alerts_interval: state.user.rcv_alerts_interval,
    rcv_alerts_noise: state.user.rcv_alerts_noise,
    rcv_alerts_disconnect: state.user.rcv_alerts_disconnect,
    rcv_alerts_activation: state.user.rcv_alerts_activation,
    rcv_alerts_global: state.user.rcv_alerts_global,
    alertDevices: state.user.alertDevices,
    alertPhones: state.user.alertPhones,
    alertEmails: state.user.alertEmails,
    rcv_alerts_email: state.user.rcv_alerts_email,
    rcv_alerts_sms: state.user.rcv_alerts_sms,
});
