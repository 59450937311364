import {
    FETCH_ALERTS,
    FETCH_PROPERTIES,
    FETCH_SENSORS,
    CACHE_SENSOR_PHOTO,
    FETCH_PROPERTY_DATA,
    FETCH_SENSOR_DECIBEL_REPORT,
    FETCH_SENSOR_MAC_COUNT_REPORT,
    FETCH_RESERVATIONS,
    FETCH_UPDATED_RESERVATION_DATA_SUCCESS,
    FETCH_MESSAGES_DATA_SUCCESS,
    FETCH_ORGANIZATION_USERS,
    FETCH_ORGANIZATION_USER_PERMISSION_DATA,
    FETCH_EVENTS,
    FETCH_MORE_EVENTS,
    FETCH_INTEGRATIONS_LIST_DATA,
    FETCH_INTEGRATIONS_DATA,
    FETCH_PROPERTY_ICAL_LINKS_DATA_SUCCESS,
    FETCH_ADMIN_DATA_SUCCESS,
} from '../actions';
import tz from 'timezone';
import { addOrUpdateObjectInArray, updateObjectInArray } from './utils';

export const defaultData = {
    properties: [],
    alerts: [],
    sensors: [],
    sensorDecibelReports: {},
    sensorMacCountReports: {},
    sensorPhotosCache: [],
    sensorsData: [],
    reservations: [],
    orgUsers: [],
    messages: [],
    events: [],
    integrations: [],
    iCalLinks: {},
    propertiesAreLoading: false,
    hasAdminData: false,
};

const data = (state, action) => {
    if (state === undefined) {
        state = defaultData;
    }
    switch (action.type) {
        case FETCH_ALERTS.SUCCESS:
            return Object.assign({}, state, { alerts: action.json });
        case FETCH_PROPERTIES.BEGIN:
            return Object.assign({}, state, { propertiesAreLoading: true });
        case FETCH_PROPERTIES.SUCCESS:
            return Object.assign({}, state, {
                propertiesAreLoading: false,
                properties: action.json,
            });
        case FETCH_SENSORS.SUCCESS:
            return Object.assign({}, state, {
                sensors: action.json,
                sensorPhotosCache: action.json.map(sensor => ({
                    sensorKey: sensor.key,
                    data: null,
                })),
            });
        case CACHE_SENSOR_PHOTO:
            return Object.assign({}, state, {
                sensorPhotosCache: updateObjectInArray(state.sensorPhotosCache, {
                    find: sensor => sensor.sensorKey === action.key,
                    update: sensor => ({ ...sensor, data: action.data }),
                }),
            });
        case FETCH_PROPERTY_DATA.SUCCESS:
            return Object.assign({}, state, {
                sensorsData: addOrUpdateObjectInArray(state.sensorsData, {
                    find: sensorsData => sensorsData.propertyKey === action.key,
                    update: sensorsData => ({ propertyKey: action.key, ...action.json }),
                }),
            });
        case FETCH_SENSOR_DECIBEL_REPORT.SUCCESS:
            return Object.assign({}, state, {
                sensorDecibelReports: {
                    ...state.sensorDecibelReports,
                    [action.sensorKey]: { propertyKey: action.propertyKey, data: action.json },
                },
            });
        // case FETCH_SENSOR_MAC_COUNT_REPORT.SUCCESS: {
        //     let countBySensor = (action.json.data || [])
        //         .sort((d1, d2) => tz(d1.timeStamp) - tz(d2.timeStamp))
        //         .reduce((acc, curr) => {
        //             // remove counts in buckets from total
        //             let count = curr
        //                 ? curr.total - curr.range70 - curr.range80 - curr.range90 - curr.range100
        //                 : 0;
        //             // organize within according to activityZoneId
        //             if (acc[`${curr.activityZoneId}`]) {
        //                 return {
        //                     ...acc,
        //                     [`${curr.activityZoneId}`]: [
        //                         ...acc[`${curr.activityZoneId}`],
        //                         { count, time: tz(curr.timeStamp) },
        //                     ],
        //                 };
        //             } else {
        //                 return {
        //                     ...acc,
        //                     [`${curr.activityZoneId}`]: [{ count, time: tz(curr.timeStamp) }],
        //                 };
        //             }
        //         }, {});
        //     return Object.assign({}, state, {
        //         sensorMacCountReports: {
        //             ...state.sensorMacCountReports,
        //             [action.propertyKey]: countBySensor,
        //         },
        //     });
        // } // MAC_FEAT
        case FETCH_RESERVATIONS.SUCCESS:
            return Object.assign({}, state, { reservations: action.json });
        case FETCH_UPDATED_RESERVATION_DATA_SUCCESS:
            return Object.assign({}, state, {
                reservations: updateObjectInArray(state.reservations, {
                    find: reservation => reservation.id === action.key,
                    update: reservation => action.json,
                }),
            });
        case FETCH_MESSAGES_DATA_SUCCESS:
            return Object.assign({}, state, {
                messages: addOrUpdateObjectInArray(state.messages, {
                    find: message => message.reservationID === action.key,
                    update: message => ({ reservationID: action.key, messages: action.json }),
                }),
            });
        case FETCH_ORGANIZATION_USERS.SUCCESS:
            return Object.assign({}, state, { orgUsers: action.json });
        case FETCH_ORGANIZATION_USER_PERMISSION_DATA.SUCCESS:
            return Object.assign({}, state, {
                orgUsers: updateObjectInArray(state.orgUsers, {
                    find: orgUser => orgUser.key === action.userKey,
                    update: orgUser => ({ ...orgUser, permissions: action.json }),
                }),
            });
        case FETCH_EVENTS.SUCCESS:
            return Object.assign({}, state, {
                events: [...action.json],
            });
        case FETCH_MORE_EVENTS.SUCCESS:
            return Object.assign({}, state, {
                events: [...state.events, ...action.json],
            });
        case FETCH_INTEGRATIONS_LIST_DATA.SUCCESS:
            return Object.assign({}, state, { integrations: action.json });
        case FETCH_INTEGRATIONS_DATA.SUCCESS:
            return Object.assign({}, state, {
                integrations: state.integrations.map(integration => ({
                    ...integration,
                    isSetUp: action.json.some(json => json.name === integration.name),
                })),
            });
        case FETCH_PROPERTY_ICAL_LINKS_DATA_SUCCESS:
            return Object.assign({}, state, {
                iCalLinks: { ...state.iCalLinks, [action.key]: action.json },
            });
        case FETCH_ADMIN_DATA_SUCCESS:
            return Object.assign({}, state, { hasAdminData: true });
        default:
            return state;
    }
};

export default data;
