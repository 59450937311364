import tz from 'timezone';
import Airbnb from '../img/misc/Airbnb.svg';
import Vrbo from '../img/misc/VRBO.svg';

export const colors = [
    ['rgba(238, 53, 74, 1)', 'rgba(238, 53, 74, 0.2)'],
    ['rgba(13, 113, 186, 1)', 'rgba(13, 113, 186, 0.2)'],
    ['rgba(219, 226, 62, 1)', 'rgba(219, 226, 62, 0.2)'],
    ['rgba(123, 72, 199, 1)', 'rgba(123, 72, 199, 0.2)'],
    ['rgba(115, 211, 98, 1)', 'rgba(115, 211, 98, 0.2)'],
    ['rgba(158, 65, 79, 1)', 'rgba(158, 65, 79, 0.2)'],
    ['rgba(193, 158, 189, 1)', 'rgba(193, 158, 189, 0.2)'],
    ['rgba(82, 46, 102, 1)', 'rgba(82, 46, 102, 0.2)'],
    ['rgba(210, 122, 60, 1)', 'rgba(210, 122, 60, 0.2)'],
    ['rgba(129, 198, 187, 1)', 'rgba(129, 198, 187, 0.2)'],
    ['rgba(201, 191, 117, 1)', 'rgba(201, 191, 117, 0.2)'],
    ['rgba(61, 61, 62, 1)', 'rgba(61, 61, 62, 0.2)'],
    ['rgba(209, 84, 176, 1)', 'rgba(209, 84, 176, 0.2)'],
    ['rgba(77, 124, 64, 1)', 'rgba(77, 124, 64, 0.2)'],
    ['rgba(129, 100, 61, 1)', 'rgba(129, 100, 61, 0.2)'],
];

export const colorsHex = [
    ['#EE354A', '#EE354A00'],
    ['#0D71BA', '#0D71BA00'],
    ['#DBE23E', '#DBE23E00'],
    ['#7B48C7', '#7B48C700'],
    ['#9BD362', '#9BD36200'],
    ['#9E414F', '#9E414F00'],
    ['#C19EBD', '#C19EBD00'],
    ['#522E66', '#522E6600'],
    ['#D27A3C', '#D27A3C00'],
    ['#81C6BB', '#81C6BB00'],
    ['#C9BF75', '#C9BF7500'],
    ['#3D3D3E', '#3D3D3E00'],
    ['#D154B0', '#D154B000'],
    ['#4D7C40', '#4D7C4000'],
    ['#81643D', '#81643D00'],
];

export const thresholdColors = {
    '5': '#0D71BA',
    '10': '#247DAC',
    '15': '#3B8A9F',
    '20': '#529791',
    '25': '#69A383',
    '30': '#80B075',
    '35': '#96BC67',
    '40': '#ADC95A',
    '45': '#C4D54C',
    '50': '#DBE23E',
    '55': '#DDCF3F',
    '60': '#DFBC41',
    '65': '#E1A842',
    '70': '#E49543',
    '75': '#E58244',
    '80': '#E86F46',
    '85': '#EA5B47',
    '90': '#EC4849',
    '95': '#EE354A',
};

export const brandColors = {
    brandRed: '#ee354a',
    darkRed: '#b11e40',
    darkBlue: '#1f4d6d',
    lightGreen: '#f7f8dc',
    darkGrey: '#494444',
    medGrey: '#bfbcbc',
    lightGrey: '#f4f2f2',
    medLightGrey: '#E8E6E6',
    superLightGrey: '#F7F6F6',
    black: '#1c1616',
    green: '#dbe23e',
    blue: '#0d71ba',
};

export const USER_TOKEN_EXPIRES_AT = 'expiresAt';

export const REFRESH_TOKEN = 'refreshToken';

export const reservationIcons = {
    Airbnb: Airbnb,
    Vrbo: Vrbo,
};

export const reservationIconColors = {
    valid: brandColors.black,
    pending: brandColors.medGrey,
    invalid: brandColors.darkRed,
};

export const sleep = ms => {
    return new Promise((resolve, reject) => {
        setTimeout(() => resolve(), ms);
    });
};

export const debounce = (func, ms) => {
    let timeout;
    return function(...args) {
        let context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), ms);
    };
};

/**
 * @description Converts start and end timestamp strings to filters compatible with Portal API
 * @param {String} start The start time; given in a format parsable by tz
 * @param {String} end The end time; given in a format parsable by tz
 * @returns An array of filters with endpoints included
 */
export const startAndEndFilters = (start, end) => {
    return [
        {
            key: 'time_stamp',
            operator: '>=',
            value: tz(start, '%Y-%m-%dT%H:%M:%SZ'),
        },
        {
            key: 'time_stamp',
            operator: '<=',
            value: tz(end, '%Y-%m-%dT%H:%M:%SZ'),
        },
    ];
};

export const noop = function(...args) {
    return;
};

export const valueToOption = value => (value ? { value, label: value } : '');

export const camelCaseToNormalCase = string =>
    string.slice(0, 1).toUpperCase() + string.slice(1).replace(/([A-Z])/g, ' $1');

export const apiList = {
    get portal() {
        switch (process.env.PORTAL_ENV) {
            case 'production':
                return 'https://portal.apps.noiseaware.io';
            case 'staging':
                return 'https://portalstage.apps.noiseaware.io';
            case 'local':
                return 'http://localhost:8207';
            default:
                return 'https://portalstage.apps.noiseaware.io';
        }
    },
};

export const externalSites = {
    loginApp(redirect) {
        let queryStr = redirect ? `?redirect=${redirect}` : '';
        switch (process.env.LOGIN_ENV) {
            case 'production':
                return 'https://login.noiseaware.io' + queryStr;
            case 'staging':
                return 'https://loginstage.noiseaware.io' + queryStr;
            case 'development':
                return 'http://localhost:8081' + queryStr;
            default:
                return 'https://loginstage.noiseaware.io' + queryStr;
        }
    },
    get valkyrie() {
        switch (process.env.VALKYRIE_ENV) {
            case 'production':
                return 'https://valkyrie.noiseaware.io/?dashboard=true';
            case 'staging':
                return 'https://lokistage.noiseaware.io/?dashboard=true';
            case 'development':
                return 'http://localhost:8082/?dashboard=true';
            default:
                return 'https://lokistage.noiseaware.io/?dashboard=true';
        }
    },
};

export const authCookieDomain =
    process.env.LOGIN_ENV === 'production' || process.env.LOGIN_ENV === 'staging'
        ? 'noiseaware.io'
        : 'localhost';

export const googleAnalyticsProperties = {
    /**
     * From analytics.google.com
     * Under the 'Properties & Apps' section
     */
    'noiseaware.io (new site)': 'UA-77912240-4',
    Dashboard: 'UA-77912240-10',
};

export function _arrayBufferToBase64(buffer) {
    let bytes = new Uint8Array(buffer);
    let binary = bytes.reduce((acc, byte) => (acc += String.fromCharCode(byte)), '');
    return btoa(binary);
}

export function readJPEGAndGetOrientation(file) {
    /**
     * Gets the orientation from a JPEG file using the EXIF orientation tag
     * Reads the file as an ArrayBuffer using a FileReader object.
     * Once the file fires 'loadend' event, we scan through the byte array using DataView:
     *  1. JPEG files are identified by the first two bytes being 0xFF 0xD8; otherwise, we reject
     *  2. The EXIF data starts after 0xFF 0xE1
     *  3. Initialize endianness as false (big endian)
     *  4. Eight bytes after the start of the EXIF data is the endianness: 0x4949 = Intel = little endian; 0x4D4D = Motorola = big endian
     *  5. The length of the EXIF data is given by the two bytes following the start of the EXIF data
     *  6. The orientation tag value is located 6 bytes after 0x01 0x12
     *
     * @param {(Blob|File)} file: the JPEG file as a Blob or File
     * @return {Promise} Promise that resolves with the ImgData object
     *
     */
    const promise = new Promise((resolve, reject) => {
        let fileReader = new FileReader();
        fileReader.onloadend = () => {
            const base64img = `data:${file.type};base64,${_arrayBufferToBase64(fileReader.result)}`;
            const scanner = new DataView(fileReader.result);
            let value = 1; // Non-rotated is the default
            if (fileReader.result.length < 2 || scanner.getUint16(0) != 0xffd8) {
                reject({ base64img, value });
            }
            let maxBytes = scanner.byteLength;
            let endianness = false; // big endian is the default
            for (let i = 2; i < maxBytes - 2; ) {
                let uint16 = scanner.getUint16(i, endianness);
                i += 2;
                if (uint16 === 0xffe1) {
                    // Start of EXIF
                    endianness = scanner.getUint16(i + 8) === 0x4949;
                    let EXIFLength = scanner.getUint16(i, endianness);
                    maxBytes = EXIFLength - i;
                    i += 2;
                } else if (uint16 === 0x0112) {
                    // Orientation tag
                    value = scanner.getUint16(i + 6, endianness);
                    break;
                }
            }
            resolve({ base64img, value });
        };
        fileReader.readAsArrayBuffer(file);
    });
    return promise;
}

export function correctJPEGOrientation({ base64img, value }) {
    /**
     * Transform the raw image using the Canvas API according to the data recovered by EXIF analysis
     * Canvas origin is in upper left of element; +x is to right, +y is down (i.e. left-handed coordinate system)
     * Strategy:
     *  1. Set canvas width and height to target width and height of image (changing the canvas after drawing resets it)
     *  2. Rotate drawing origin according to EXIF value
     *  3. Translate drawing origin so that image is drawn on original quadrant
     *  4. Draw image on canvas.
     *  5. Convert canvas to data URL for rendering in an image element.
     *
     * Intuitive explanation:
     *  Think of the canvas and image as being on two separate pieces of paper:
     *  The canvas has a fixed coordinate system drawn in upper left corner (+x is right, +y is down).
     *  The image is drawn on a piece of paper with own fixed coordinate system drawn in upper left corner (+x is right, +y is down).
     *  The initial state has the two coordinate systems aligned and the image paper is on top.
     *  Hold the image paper at its origin (positive rotations are clockwise) and apply the transformation relative to the canvas coordinate system
     *
     * @param {ImgData} imgData - the ImgData object
     *  @param {string} imgData.base64img - the JPEG image encoded in a base-64 string
     *  @param {number} imgData.value - the JPEG EXIF orientation tag value
     *
     * @returns {Promise} Promise that resolves to the data URL transform of the canvas
     */
    const rotationsMap = {
        1: 0,
        3: Math.PI,
        6: Math.PI / 2,
        8: (3 * Math.PI) / 2,
    };

    // use these invisible elements to load the image and
    // rotate the image using the canvas
    const img = document.createElement('img');
    const canvas = document.createElement('canvas');
    img.style.display = 'none';
    canvas.style.display = 'none';

    let promise = new Promise((resolve, reject) => {
        img.addEventListener('load', () => {
            let ctx = canvas.getContext('2d');
            let rotation = rotationsMap[value];
            canvas.width = [1, 3].includes(value) ? img.width : img.height;
            canvas.height = [1, 3].includes(value) ? img.height : img.width;
            ctx.rotate(rotation);
            switch (value) {
                case 6: // pi/2
                    ctx.translate(0, -img.height);
                    break;
                case 3: // pi
                    ctx.translate(-img.width, img.height);
                    break;
                case 8: // 3pi/2
                    ctx.translate(-img.width, 0);
                    break;
                default:
                    // 0
                    break;
            }
            ctx.drawImage(img, 0, 0);
            const dataURL = canvas.toDataURL('image/jpeg', 0.65);
            resolve(dataURL);
        });
        img.addEventListener('error', () => {
            reject('error transforming image');
        });
        img.src = base64img;
    });
    return promise;
}

/**
 * @description Attaches a module's `selector` to the redux store via a `rootSelector`
 * @param {function} rootSelector selector function from root state to relevant mount point
 * @param {function} selector modular selector to be mapped to global selector
 */
export const withRoot = (rootSelector, selector) => (state, props) =>
    selector(rootSelector(state), props);

/**
 * @description Converts modular selectors to selectors that can access the actual redux store
 * @param {object} selectors An object with keys to the output selector names, values are the modular selectors
 * @param {string} mountSelectors representation of object property path from root redux store
 * @returns {object} keys are the same as those for selectors object, values are the globalized selectors
 */
export const globalizeSelectors = (selectors, mountSelector) => {
    if (selectors instanceof Array) {
        return selectors.map(selector => withRoot(mountSelector, selector));
    }
    return Object.keys(selectors).reduce((acc, key) => {
        acc[key] = withRoot(mountSelector, selectors[key]);
        return acc;
    }, {});
};
