import { createSelector } from 'reselect';
import { accountNotificationsSettingsColumnsSelector } from './accountNotificationsSettingsColumnsSelector';
import {
    getAccountNotificationFilters,
    getAccountUpdatedPropertiesNotificationSettings,
    getAccountGlobalReceivePreference,
    accountNotificationsSettingsHeadersSelector,
} from './selectors.account';

export const accountNotificationsSettingsSelector = createSelector(
    accountNotificationsSettingsHeadersSelector,
    getAccountNotificationFilters,
    getAccountUpdatedPropertiesNotificationSettings,
    accountNotificationsSettingsColumnsSelector,
    getAccountGlobalReceivePreference,
    (headers, filters, propertiesNotificationSettings, columns, globalReceivePreference) => {
        let filteredProperties = Object.values(propertiesNotificationSettings).filter(property => {
            let matchesCriteria = true;
            if (filters.city && filters.city !== property.city) return false;
            if (filters.propertyType && filters.propertyType !== property.type) return false;
            filters.tags.forEach(tag => {
                if (!property.tags.includes(tag)) matchesCriteria = false;
            });
            return matchesCriteria;
        });
        let rows = filteredProperties.map(property => {
            return {
                id: property.key,
                contents: [
                    {
                        id: `${property.key}-0`,
                        label: property.key,
                        type: 'text',
                        text: property.nickname,
                    },
                    {
                        id: `${property.key}-1`,
                        label: property.key,
                        type: property.tags.length ? 'tags' : 'text',
                        [property.tags.length ? 'tags' : 'text']: property.tags.length
                            ? property.tags
                            : 'None',
                    },
                    {
                        id: `${property.key}-2`,
                        label: property.key,
                        type: 'text',
                        text: property.city,
                    },
                    {
                        id: `${property.key}-3`,
                        label: property.key,
                        type: 'text',
                        text: property.timezone ? property.timezone.replace(/_/g, ' ') : '',
                    },
                    {
                        id: `${property.key}-4`,
                        label: property.key,
                        type: 'toggle',
                        disabled: false,
                        checked: !!property.notificationNoise,
                        column: 'Noise Alerts',
                    },
                    {
                        id: `${property.key}-5`,
                        label: property.key,
                        type: 'toggle',
                        disabled: false,
                        checked: !!property.notificationDisconnect,
                        column: 'Disconnect Alerts',
                    },
                ],
            };
        });
        let cols = columns.map((column, i) => {
            if (column.type === 'toggle') {
                column = {
                    ...column,
                    disabled: !globalReceivePreference,
                };
                let checked = column.checked;
                if (rows.length) {
                    let numChecked = rows.reduce((acc, curr) => {
                        let num = curr.contents[i].checked ? 1 : 0;
                        return acc + num;
                    }, 0);
                    if (numChecked === 0) {
                        checked = false;
                    } else if (numChecked < rows.length) {
                        checked = 'indeterminate';
                    } else if (numChecked === rows.length) {
                        checked = true;
                    }
                    return { ...column, checked };
                } else {
                    return column;
                }
            } else {
                return column;
            }
        });
        return {
            headers,
            rows,
            cols,
        };
    }
);
