import React from 'react';

import PageLink from './PageLink';

import LogoWhite from '../../img/brandedAssets/NoiseAware_Logo_White.svg';
import DashboardIcon from '../../img/sideNav/NoiseAware_Icon_Dashboard.svg';
import PropertiesIcon from '../../img/sideNav/NoiseAware_Icon_Properties.svg';
import SensorsIcon from '../../img/sideNav/NoiseAware_Icon_Sensors.svg';
import EventsIcon from '../../img/sideNav/NoiseAware_Icon_Events.svg';
import AddPropertyIcon from '../../img/sideNav/NoiseAware_Icon_AddProperty.svg';
import { externalSites } from '../constants';

const Nav = ({ nav, onToggleClick, userRole }) => (
    <nav className={nav === 'open' ? 'nav-container' : 'nav-container nav-closed'}>
        <div className="nav-brand">
            <LogoWhite className="nav-brand-img" />
            <div className="nav-brand-text">NoiseAware</div>
        </div>
        <div className="nav-toggle" onClick={onToggleClick}>
            <span className={nav === 'open' ? 'toggle-icon' : 'toggle-icon nav-icon-closed'}>
                <i className="fas fa-chevron-left"></i>
            </span>
        </div>
        <hr className="nav-rule" />
        <div className="nav-item">
            <PageLink page="dashboard" title={'Dashboard'}>
                <div className="nav-icon">
                    <DashboardIcon />
                </div>
                Dashboard
            </PageLink>
        </div>
        <div className="nav-item">
            <PageLink page="properties" title={'Properties'}>
                <div className="nav-icon">
                    <PropertiesIcon />
                </div>
                Properties
            </PageLink>
        </div>
        <div className="nav-item">
            <PageLink page="sensors" title={'Sensors'}>
                <div className="nav-icon">
                    <SensorsIcon />
                </div>
                Sensors
            </PageLink>
        </div>
        <div className="nav-item">
            <PageLink page="events" title={'Events'}>
                <div className="nav-icon">
                    <EventsIcon />
                </div>
                Events
            </PageLink>
        </div>
        <hr className="nav-rule" />
        <div className="nav-item">
            <PageLink page="add_property" title={'Add Property'}>
                <div className="nav-icon">
                    <AddPropertyIcon />
                </div>
                Add Property
            </PageLink>
        </div>
        <hr className="nav-rule" />
        <div className="nav-item">
            <a
                className="nav-link"
                href="https://help.noiseaware.io/"
                rel="noopener noreferrer"
                target="_blank">
                <i className="far fa-question-circle nav-icon"></i>
                Help
            </a>
        </div>
        {userRole !== 'External' && (
            <div className="nav-item">
                <a href={externalSites.valkyrie} className="nav-link">
                    <div className="nav-icon">
                        <EventsIcon />
                    </div>
                    Events<sup className="beta">BETA</sup>
                </a>
            </div>
        )}
        <div className="nav-footer">
            {process.env.NODE_ENV === 'production' ? process.env.VERSION : 'beta'}
        </div>
    </nav>
);

export default Nav;
