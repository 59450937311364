import { createSelector } from 'reselect';
import { withRoot } from '../constants';
import { propertiesAreLoadingSelector } from './selectors.data';
import { getUserAsyncState } from './selectors.user';
import { propertiesAppliedFiltersFormatter, getFilters, getFilterOpen } from '../reducers/filter';
import { createTableHeadersDropdownSelector } from '../reducers/tableHeaderDropdown';

const mountSelector = state => state.ui.account;

export const getAccountModalOpen = state => state.ui.account.modalOpen;

export const getAccountModalData = state => state.ui.account.modalData;

export const getAccountNotificationFilters = withRoot(mountSelector, getFilters);

export const getAccountFiltersVisibility = withRoot(mountSelector, getFilterOpen);

export const getAccountNotificationsSettingsColumns = state => state.ui.account.cols;

export const getAccountNotificationsSettingsHeaders = state => state.ui.account.headers;

export const getAccountAlertsPreferences = state => state.ui.account.preferences;

export const getAccountGlobalReceivePreference = state =>
    state.ui.account.preferences.globalReceive;

export const getAccountNotificationEmails = state => state.ui.account.notificationEmails;

export const getAccountDeletedEmails = state => state.ui.account.deletedEmails;

export const getAccountNotificationPhones = state => state.ui.account.notificationPhones;

export const getAccountDeletedPhones = state => state.ui.account.deletedPhones;

export const getAccountIsLoading = state => state.ui.account.isLoading;

export const getAccountHasError = state => state.ui.account.hasError;

export const getAccountHasPropertiesData = state => state.ui.account.hasPropertiesData;

export const getAccountSavedPropertiesNotificationSettings = state =>
    state.ui.account.savedPropertiesNotificationSettings;

export const getAccountUpdatedPropertiesNotificationSettings = state =>
    state.ui.account.updatedPropertiesNotificationSettings;

export const propertiesNotificationSettingsAreLoadingSelector = state =>
    state.ui.account.propertiesNotificationSettingsAreLoading;

export const accountPreferencesCardLoadingSelector = createSelector(
    // Preferences card depends on data from the /v1/users endpoint
    // as well as the /user/notification endpoints
    // hence, we need to wait for both
    getAccountIsLoading,
    getUserAsyncState,
    (accountLoading, userAsyncState) => {
        return [accountLoading, userAsyncState.isLoading].some(isLoading => isLoading === true);
    }
);

export const accountPreferencesCardHasErrorSelector = createSelector(
    // Preferences card depends on data from the /v1/users endpoint
    // as well as the /user/notification endpoints
    // hence, we need to wait for both
    getAccountHasError,
    getUserAsyncState,
    (accountHasError, userAsyncState) => {
        return [accountHasError, userAsyncState.hasError].some(hasError => !hasError);
    }
);

export const accountNotificationCardIsLoadingSelector = createSelector(
    // Notification card depends on data from the /v1/properties endpoint
    // as well as the /user/notification endpoints
    // hence, we need to wait for both
    propertiesNotificationSettingsAreLoadingSelector,
    propertiesAreLoadingSelector,
    (accountIsLoading, propertiesAreLoading) => {
        return [accountIsLoading, propertiesAreLoading].some(isLoading => isLoading === true);
    }
);

export const getAccountGlobalReceivePreferences = createSelector(
    getAccountAlertsPreferences,
    preferences => {
        return [
            preferences.globalReceiveEmail,
            preferences.globalReceivePush,
            preferences.globalReceiveSMS,
        ];
    }
);

export const accountNotificationsSettingsHeadersSelector = createTableHeadersDropdownSelector(
    getAccountNotificationsSettingsHeaders
);

export const getAccountFiltersApplied = createSelector(
    getAccountNotificationFilters,
    propertiesAppliedFiltersFormatter
);
