import {
    TOGGLE_ALERT_PREFERENCE,
    TOGGLE_NOTIFICATION_SUBSCRIPTION,
    TOGGLE_NOTIFICATIONS_SUBSCRIPTIONS,
    UPDATE_USER,
} from '../actions';
import { createAnalyticsSaga, sendEventToGoogleAnalytics } from './sagas.analytics';

function accountAnalyticsHandler(name) {
    return function*() {
        yield* sendEventToGoogleAnalytics({ eventName: name, eventLabel: 'account' });
    };
}

export const accountAnalyticsSaga = createAnalyticsSaga({
    [TOGGLE_ALERT_PREFERENCE]: accountAnalyticsHandler('toggle_alert_preference'),
    [TOGGLE_NOTIFICATION_SUBSCRIPTION]: accountAnalyticsHandler('toggle_notification'),
    [TOGGLE_NOTIFICATIONS_SUBSCRIPTIONS]: accountAnalyticsHandler('toggle_all_notifications'),
    [UPDATE_USER.BEGIN]: accountAnalyticsHandler('update_user'),
});
