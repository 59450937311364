import {
    FETCH_ORGANIZATION_USER_PERMISSION_DATA,
    FETCH_PROPERTY_PERMISSIONS,
} from './actions.types';
import { usersSighV1Api } from '../remote2';
import { asyncActionCreator } from './actions.async';

export const fetchUserPermissions = userKey =>
    asyncActionCreator({
        // for fetching permissions for other users in org
        type: FETCH_ORGANIZATION_USER_PERMISSION_DATA,
        createThunk: userKey => dispatch => {
            return usersSighV1Api.usersSighV1GetPropertiesListForPermittedUser(userKey);
        },
        successPayload: { userKey },
    })(userKey);

export const fetchPropertyPermissions = userKey =>
    asyncActionCreator({
        type: FETCH_PROPERTY_PERMISSIONS,
        createThunk: userKey => dispatch => {
            return usersSighV1Api.usersSighV1GetPropertiesListForPermittedUser(userKey);
        },
    })(userKey);
