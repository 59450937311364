import {
    TOGGLE_USER_MENU,
    TOGGLE_SEARCH_FOCUS,
    TOGGLE_SEARCH_BLUR,
    SEARCH_FOR_PROPERTY,
    SELECT_PROPERTY_FROM_SEARCH,
} from './actions.types';

import { propertiesSighV1Api } from '../remote2';
import { asyncActionCreator } from './actions.async';

export const toggleUserMenu = () => {
    return {
        type: TOGGLE_USER_MENU,
    };
};

export const searchFocus = () => {
    return {
        type: TOGGLE_SEARCH_FOCUS,
    };
};

export const searchBlur = () => {
    return {
        type: TOGGLE_SEARCH_BLUR,
    };
};

export const searchProperties = search =>
    asyncActionCreator({
        type: SEARCH_FOR_PROPERTY,
        createThunk: search => dispatch =>
            propertiesSighV1Api.propertiesSighV1GetAllProperties({ search }),
    })(search);

export const selectProperty = propertyKey => {
    return {
        type: SELECT_PROPERTY_FROM_SEARCH,
        table: 'properties',
        propertyKey,
    };
};
