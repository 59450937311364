import { DROPDOWN_TOGGLE_CLICK, DROPDOWN_BLUR, DROPDOWN_SELECT_OPTION } from './actions.types';

export const dropdownToggleClick = table => {
    return {
        type: DROPDOWN_TOGGLE_CLICK,
        table,
    };
};

export const dropdownBlur = table => {
    return {
        type: DROPDOWN_BLUR,
        table,
    };
};

export const dropdownSelectOption = (table, option) => {
    return {
        type: DROPDOWN_SELECT_OPTION,
        table,
        option,
    };
};
