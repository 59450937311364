import {
    UPDATE_DAY_PICKER_FROM,
    UPDATE_DAY_PICKER_TO,
} from './actions.types';

export const updateDayFrom = (day, table) => {
    return {
        type: UPDATE_DAY_PICKER_FROM,
        table,
        day
    };
};

export const updateDayTo = (day, table) => {
    return {
        type: UPDATE_DAY_PICKER_TO,
        table,
        day
    };
};