import {
    FETCH_MESSAGES_DATA_SUCCESS,
    FETCH_RESERVATIONS,
    DELETE_RESERVATION,
    FETCH_PROPERTY_ICAL_LINKS_DATA_SUCCESS,
} from './actions.types';
import { asyncActionCreator } from './actions.async';
import { reservationSighV1Api } from '../remote2';
import {
    fetchMessagesBegin,
    fetchMessagesSuccess,
    fetchMessagesFailure,
    fetchPropertyICalLinksBegin,
    fetchPropertyICalLinksSuccess,
    fetchPropertyICalLinksFailure,
} from './actions.defaultPanel';

export const fetchReservations = ({ time = '', offset = 0, limit = -1, opts = {} }) =>
    asyncActionCreator({
        type: FETCH_RESERVATIONS,
        createThunk: (...args) => dispatch => {
            return reservationSighV1Api.reservationsSighV1GetReservationsForPermittedUser(...args);
        },
    })(time, offset, limit, opts);

export const deleteReservation = reservationID =>
    asyncActionCreator({
        type: DELETE_RESERVATION,
        createThunk: reservationID => dispatch => {
            return reservationSighV1Api.reservationsSighV1DeleteReservation(reservationID);
        },
    })(reservationID);

export function fetchMessages(propertyKey, reservationID, table) {
    return dispatch => {
        dispatch(fetchMessagesBegin(table));
        return reservationSighV1Api
            .reservationsSighV1GetMessages(0, -1, {
                propertyKey: propertyKey,
                reservationId: reservationID,
            })
            .then(json => {
                dispatch(fetchMessagesDataSuccess(json, reservationID));
                dispatch(fetchMessagesSuccess(json, table));
            })
            .catch(err => {
                dispatch(fetchMessagesFailure(err, table));
            });
    };
}

export const fetchMessagesDataSuccess = (json, reservationID) => {
    return {
        type: FETCH_MESSAGES_DATA_SUCCESS,
        json,
        key: reservationID,
    };
};

export function fetchPropertyICalLinks(table, propertyKey) {
    return dispatch => {
        dispatch(fetchPropertyICalLinksBegin(table));
        return reservationSighV1Api
            .reservationsSighV1GetPropertyICallLinks(propertyKey)
            .then(json => {
                dispatch(fetchPropertyICalLinksSuccess(json, table));
                dispatch(fetchPropertyICalLinksDataSuccess(json, propertyKey));
            })
            .catch(err => {
                dispatch(fetchPropertyICalLinksFailure(err));
            });
    };
}

export const fetchPropertyICalLinksDataSuccess = (json, key) => {
    return {
        type: FETCH_PROPERTY_ICAL_LINKS_DATA_SUCCESS,
        json,
        key,
    };
};
