import currentAlerts, { defaultCurrentAlertsState } from './currentAlerts';
import resolvedAlerts, { defaultResolvedAlertsState } from './resolvedAlerts';
import { propertiesFilterReducer, defaultFilterState, defaultPropertiesFilters } from './filter';

export const defaultDashboardState = {
    filterOpen: defaultFilterState.filterOpen,
    filters: defaultPropertiesFilters,
    currentAlerts: defaultCurrentAlertsState,
    resolvedAlerts: defaultResolvedAlertsState,
};

const dashboard = (state, action) => {
    if (state === undefined) {
        state = defaultDashboardState;
    }
    if (action.table === 'dashboard') {
        return Object.assign(
            {},
            state,
            propertiesFilterReducer(
                { filterOpen: state.filterOpen, filters: state.filters },
                action
            )
        );
    } else {
        return Object.assign({}, state, {
            currentAlerts: currentAlerts(state.currentAlerts, action),
            resolvedAlerts: resolvedAlerts(state.resolvedAlerts, action),
        });
    }
};

export default dashboard;
