import {
    FETCH_USER,
    SET_USER_TOKEN,
    FETCH_PROPERTY_PERMISSIONS,
    REFRESH_USER_TOKEN,
} from '../actions';
import createAsyncStateReducer from './async';
import { composeReducers } from './utils';

const fetchUserReducer = createAsyncStateReducer(FETCH_USER);

export const defaultUserState = {
    // TODO: Delete marked fields to accommodate deprecating the `/v1/users API response
    // Notification settings now come from `/user/notification/` API
    // The notification settings will be handled in the `account` reducer
    alertEmails: [], // TODO: delete
    alertPhones: [], // TODO: delete
    email: '',
    firstName: '',
    lastName: '',
    key: '',
    phone: '',
    rcv_alerts_activation: false, // TODO: delete
    rcv_alerts_disconnect: false, // TODO: delete
    rcv_alerts_interval: 0, // TODO: delete
    rcv_alerts_noise: false, // TODO: delete
    role: 'None',
    organization: {
        checkin: '00:00:00',
        checkout: '00:00:00',
        city: '',
        installedProperties: 0,
        key: '',
        name: '',
        phone: '',
        postalCode: '',
        state: '',
        street1: '',
        street2: '',
        totalProperties: 0,
    },
    isLoading: true,
    isLoadingRefresh: true,
    hasError: null,
    userToken: null,
    permissions: [],
};

const user = (state, action) => {
    if (state === undefined) {
        state = defaultUserState;
    }
    switch (action.type) {
        case FETCH_USER.SUCCESS:
            return Object.assign({}, state, { ...action.json });
        case SET_USER_TOKEN:
            return Object.assign({}, state, { userToken: action.token });
        case REFRESH_USER_TOKEN.SUCCESS:
            return Object.assign({}, state, {
                isLoadingRefresh: false,
                userToken: action.json.userToken,
            });
        case FETCH_PROPERTY_PERMISSIONS.SUCCESS:
            return Object.assign({}, state, { permissions: action.json });
        default:
            return state;
    }
};

export default composeReducers([user, fetchUserReducer], defaultUserState);
