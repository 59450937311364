import { combineReducers } from 'redux';
import propertiesTable, { defaultPropertiesTableState } from './propertiesTable';
import propertiesPanel, { defaultPropertiesPanelState } from './propertiesPanel';

const properties = combineReducers({
    propertiesTable, propertiesPanel
});

export default properties;

export const defaultPropertiesState = {
    propertiesTable: defaultPropertiesTableState,
    propertiesPanel: defaultPropertiesPanelState
};