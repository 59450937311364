import { createSelector } from 'reselect';
import { getFilters, getFilterOpen } from '../reducers/filter';
import { withRoot } from '../constants';
import { createTableHeadersDropdownSelector } from '../reducers/tableHeaderDropdown';

const mountSelector = state => state.ui.events.eventsTable;

export const eventsTableTimeDropdownSelector = state =>
    state.ui.events.eventsTable.headers['Time'].selected;

export const eventsUIHasErrorSelector = state => state.ui.events.eventsTable.hasError;

export const eventsUILoadingSelector = state => state.ui.events.eventsTable.isLoading;

export const getEventsFilters = withRoot(mountSelector, getFilters);

export const getEventsFiltersVisibility = withRoot(mountSelector, getFilterOpen);

export const getEventsHeaders = state => state.ui.events.eventsTable.headers;

export const eventsHeadersSelector = createTableHeadersDropdownSelector(getEventsHeaders);

export const getEventsOffset = state => state.ui.events.eventsTable.offset;

export const getExpandedEventRow = state => state.ui.events.eventsTable.expandedRow;

export const getEventsFiltersApplied = createSelector(
    getEventsFilters,
    eventsFilters =>
        Object.entries(eventsFilters)
            .map(([key, value]) => ({ key, value }))
            .reduce((acc, { key, value }) => {
                if (key === 'tags') {
                    if (value.length > 0)
                        return [...value.map(tag => ({ key: 'tag', value: tag })), ...acc];
                    else return [...acc];
                } else if (value) return [{ key, value }, ...acc];
                else return [...acc];
            }, [])
);
