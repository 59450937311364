import { withRoot } from '../constants';
import { dateFormatString } from '../dates';
import { modalOpenSelector, modalDataSelector } from '../reducers/modal';
import { createSelector } from 'reselect';
import { reservationsSelector, propertySensorsDataSelector } from './selectors.data';
import tz from 'timezone';

const mountSelector = state => state.ui.reservations.reservationsDetail;

export const getDeleteReservationModalOpen = withRoot(mountSelector, modalOpenSelector);

export const getDeleteReservationModalData = withRoot(mountSelector, modalDataSelector);

export const reservationDetailUISelector = state => ({
    isLoading: state.ui.reservations.reservationsDetail.isLoading,
    hasError: state.ui.reservations.reservationsDetail.hasError,
});

export const isEditingReservationSelector = state =>
    state.ui.reservations.reservationsDetail.isEditingReservation;

export const reservationIdSelector = state => state.ui.reservations.reservationsDetail.id;

export const getReservationIdByPathParam = (state, props) => {
    if (props.match && props.match.params && props.match.params.reservationId) {
        return Number.parseInt(props.match.params.reservationId);
    }
    return 'u';
};

export const reservationDetailGraphUISelector = state => ({
    isLoading: state.ui.reservations.reservationsDetail['NRS Graph'].isLoading,
    hasError: state.ui.reservations.reservationsDetail['NRS Graph'].hasError,
});

export const getReservationDetailGraphEndpoints = state => ({
    start: state.ui.reservations.reservationsDetail['NRS Graph'].start,
    end: state.ui.reservations.reservationsDetail['NRS Graph'].end,
});

export const getReservationDetailGraphAutoRefresh = state =>
    state.ui.reservations.reservationsDetail['NRS Graph'].autoRefresh;

export const getReservationDetailGraphUI = state =>
    state.ui.reservations.reservationsDetail['NRS Graph'];

export const getReservationDetailMessagesUI = state =>
    state.ui.reservations.reservationsDetail['Messages'];

export const getReservationDetailGraphDeviceVisibility = state =>
    state.ui.reservations.reservationsDetail['NRS Graph'].deviceVisibility;

export const getReservationById = createSelector(
    reservationsSelector,
    getReservationIdByPathParam,
    (reservations, id) => {
        for (let reservation of reservations) {
            if (reservation.id === id) {
                return reservation;
            }
        }
        return {
            id: 0,
            propertyKey: '',
            propertyName: '',
            start: '',
            end: '',
            guest: {
                email: '',
                phone: '',
                role: 'Guest',
                firstName: '',
                lastName: '',
            },
            sourceResID: '',
            type: 'Vacant',
            current: true,
            timezone: '',
            expectedGuestCount: 0,
        };
    }
);

export const getReservationPropertyKeyById = createSelector(
    reservationsSelector,
    getReservationIdByPathParam,
    (reservations, id) => {
        for (let reservation of reservations) {
            if (reservation.id === id) {
                return reservation.propertyKey;
            }
        }
        return '';
    }
);

export const reservationDetailPollGraphDataSelector = createSelector(
    getReservationPropertyKeyById,
    getReservationById,
    getReservationDetailGraphUI,
    (propertyKey, reservation, graphUI) => {
        return {
            interval: reservation.current
                ? { number: 30, unit: 'minutes' }
                : { number: 7, unit: 'days' },
            table: 'reservations',
            autoRefresh: graphUI.autoRefresh,
            propertyKey,
            end: reservation.current ? tz(new Date(), dateFormatString.RFC3339) : reservation.end,
        };
    }
);

export const isActiveReservationSelector = createSelector(
    getReservationById,
    reservation => reservation.current
);

export const reservationMessagesSelector = createSelector(
    getReservationPropertyKeyById,
    getReservationIdByPathParam,
    isActiveReservationSelector,
    (propertyKey, reservationId, isActive) => {
        return {
            paused: !isActive,
            reservationId,
            table: 'reservations',
            propertyKey,
        };
    }
);

export const getReservationGuestName = createSelector(
    getReservationById,
    reservation => {
        if (reservation.guest.firstName || reservation.guest.lastName)
            return `${reservation.guest.firstName || ''} ${reservation.guest.lastName || ''}`;
        else return '(No Name)';
    }
);

export const reservationDetailTableSelector = createSelector(
    getReservationById,
    reservation => ({
        Property: reservation.propertyName,
        Today: tz(new Date(), '%m/%d/%Y'),
        Dates: `${tz(reservation.start, '%m/%d/%Y')} - ${tz(reservation.end, '%m/%d/%Y')}`,
        Guests: reservation.expectedGuestCount,
        Contact: reservation.guest.phone,
        Reservation: reservation.sourceResID,
        Status: reservation.type,
    })
);

export const reservationDetailGraphSelector = createSelector(
    propertySensorsDataSelector,
    getReservationById,
    getReservationDetailGraphEndpoints,
    getReservationDetailGraphDeviceVisibility,
    reservationDetailGraphUISelector,
    (sensorsData, reservation, ends, deviceVisibility, graphUI) => {
        const propertySensorsData = sensorsData.find(
            sensorsDataByProperty => sensorsDataByProperty.propertyKey === reservation.propertyKey
        ) || { timezone: 'string', propertyKey: 'string', data: [], zones: [] };
        return {
            data: propertySensorsData.data,
            sensorGraphsVisible: deviceVisibility,
            start: ends.start,
            end: ends.end,
            timezone: propertySensorsData.timezone,
            sensors: propertySensorsData.zones,
            isLoading: graphUI.isLoading,
            hasError: graphUI.hasError,
        };
    }
);

export const reservationDetailMessagesSelector = createSelector(
    getReservationIdByPathParam,
    getReservationGuestName,
    getReservationPropertyKeyById,
    (id, guestName, propertyKey) => {
        return {
            reservation: {
                id,
                guest: guestName,
            },
            propertyKey,
        };
    }
);
