import { defaultModalState } from './modal';
import {
    OPEN_CONFIRM_DELETE_MODAL,
    CLOSE_CONFIRM_DELETE_MODAL,
    TOGGLE_EDIT_RESERVATION,
    FETCH_PROPERTY_DETAIL,
    FETCH_MESSAGES,
    POP_ROW_CLICK,
    TOGGLE_SENSOR_GRAPH_VISIBILITY,
    TOGGLE_DATA_AUTO_REFRESH,
} from '../actions/actions.types';
import { dateFormatString } from '../dates';
import tz from 'timezone';

export const defaultReservationsDetailState = {
    modalOpen: defaultModalState.modalOpen,
    modalData: {
        assetType: 'reservation',
        assetName: '',
    },
    isEditingReservation: false,
    id: 'u',
    isLoading: false,
    hasError: null,
    isActiveReservation: true,
    'NRS Graph': {
        isLoading: false,
        hasError: null,
        autoRefresh: false,
        start: tz(new Date(), '-30 minutes', dateFormatString.RFC3339),
        end: tz(new Date(), dateFormatString.RFC3339),
        deviceVisibility: [],
    },
    Messages: {
        isLoading: false,
        hasError: null,
    },
};

const reservationsDetail = (state, action) => {
    if (state === undefined) {
        state = defaultReservationsDetailState;
    }
    if (action.table === 'reservations') {
        switch (action.type) {
            case POP_ROW_CLICK:
                return Object.assign({}, state, {
                    id: Number.parseInt(action.popKey),
                });
            case FETCH_PROPERTY_DETAIL.BEGIN:
                return Object.assign({}, state, {
                    'NRS Graph': { ...state['NRS Graph'], isLoading: true, hasError: null },
                });
            case FETCH_PROPERTY_DETAIL.SUCCESS:
                return Object.assign({}, state, {
                    'NRS Graph': {
                        ...state['NRS Graph'],
                        isLoading: false,
                        hasError: null,
                        deviceVisibility: action.json.zones.map(o => true),
                        start: tz(action.json.data[0].time, dateFormatString.RFC3339),
                        end: tz(
                            action.json.data[action.json.data.length - 1].time,
                            dateFormatString.RFC3339
                        ),
                    },
                });
            case FETCH_PROPERTY_DETAIL.FAILURE:
                return Object.assign({}, state, {
                    'NRS Graph': { ...state['NRS Graph'], isLoading: false, hasError: action.err },
                });
            case FETCH_MESSAGES.BEGIN:
                return Object.assign({}, state, {
                    Messages: {
                        ...state['Messages'],
                        isLoading: true,
                    },
                });
            case FETCH_MESSAGES.SUCCESS:
                return Object.assign({}, state, {
                    Messages: {
                        ...state['Messages'],
                        isLoading: false,
                        hasError: null,
                    },
                });
            case FETCH_MESSAGES.FAILURE:
                return Object.assign({}, state, {
                    Messages: {
                        ...state['Messages'],
                        isLoading: false,
                        hasError: action.err,
                    },
                });
            case OPEN_CONFIRM_DELETE_MODAL:
                return Object.assign({}, state, {
                    modalOpen: true,
                    modalData: action.modalData,
                });
            case CLOSE_CONFIRM_DELETE_MODAL:
                return Object.assign({}, state, {
                    modalOpen: false,
                    modalData: defaultReservationsDetailState.modalData,
                });
            case TOGGLE_DATA_AUTO_REFRESH:
                return Object.assign({}, state, {
                    'NRS Graph': {
                        ...state['NRS Graph'],
                        autoRefresh: !state['NRS Graph'].autoRefresh,
                    },
                });
            case TOGGLE_EDIT_RESERVATION:
                return Object.assign({}, state, {
                    isEditingReservation: !state.isEditingReservation,
                });
            case TOGGLE_SENSOR_GRAPH_VISIBILITY:
                return Object.assign({}, state, {
                    'NRS Graph': {
                        ...state['NRS Graph'],
                        deviceVisibility: state['NRS Graph'].deviceVisibility.map(
                            (visibility, index) =>
                                index === action.index ? !visibility : visibility
                        ),
                    },
                });
            default:
                return state;
        }
    }
    switch (action.type) {
        default:
            return state;
    }
};

export default reservationsDetail;
