import { TOGGLE_NAV } from '../actions';
export const defaultNavState = {nav: 'closed'};

const nav = (state = {nav: 'closed'}, action) => {
    switch(action.type){
        case TOGGLE_NAV:
            return Object.assign({}, state, {nav: state.nav === 'open' ? 'closed': 'open'});
        default:
            return state;
    }
};

export default nav;