import { createSelector } from 'reselect';
import { getFilters, getFilterOpen } from '../reducers/filter';
import { withRoot } from '../constants';
import { createTableHeadersDropdownSelector } from '../reducers/tableHeaderDropdown';
import { getAsyncState } from '../reducers/async';

const mountSelector = state => state.ui.sensors.sensorsTable;

export const getExpandedSensorRow = state => state.ui.sensors.sensorsTable.expandedRow;

export const getSensorsAsyncState = withRoot(mountSelector, getAsyncState);

export const getSensorsFilters = withRoot(mountSelector, getFilters);

export const getSensorsFiltersVisibility = withRoot(mountSelector, getFilterOpen);

export const getSensorsHeaders = state => state.ui.sensors.sensorsTable.headers;

export const sensorsHeadersSelector = createTableHeadersDropdownSelector(getSensorsHeaders);

export const sensorsTablePropertyDropdownSelector = state =>
    state.ui.sensors.sensorsTable.headers['Property'].selected;

export const sensorsUISelector = state => state.ui.sensors.sensorsTable;

export const getSensorsFiltersApplied = createSelector(
    getSensorsFilters,
    sensorsFilters =>
        Object.entries(sensorsFilters)
            .map(([key, value]) => ({ key, value }))
            .reduce((acc, { key, value }) => {
                if (key === 'tags') {
                    if (value.length > 0)
                        return [...value.map(tag => ({ key: 'tag', value: tag })), ...acc];
                    else return [...acc];
                } else if (value) return [{ key, value }, ...acc];
                else return [...acc];
            }, [])
);
