import {
    OPEN_FILTER_MENU,
    CLOSE_FILTER_MENU,
    UPDATE_FILTERS,
    CLEAR_FILTERS,
    REMOVE_FILTER,
} from '../actions';

export const defaultFilterState = {
    filterOpen: false,
    filters: {},
};

const filterReducer = (state, action) => {
    if (state === undefined) {
        state = defaultFilterState;
    }
    switch (action.type) {
        case OPEN_FILTER_MENU:
            return Object.assign({}, state, { filterOpen: true });
        case CLOSE_FILTER_MENU:
            return Object.assign({}, state, { filterOpen: false });
        case UPDATE_FILTERS:
            return Object.assign({}, state, { filters: action.filters });
        case CLEAR_FILTERS:
            return Object.assign({}, state, {
                filters: defaultFilterState.filters,
            });
        case REMOVE_FILTER:
            if (action.filter === 'tag') {
                return Object.assign({}, state, {
                    filters: {
                        ...state.filters,
                        tags: state.filters.tags.filter(tag => tag !== action.value),
                    },
                });
            }
            return Object.assign({}, state, {
                filters: {
                    ...state.filters,
                    [action.filter]: defaultFilterState.filters[action.filter],
                },
            });
        default:
            return state;
    }
};

export const createFilterReducer = (defaultFilterStateFilters = defaultFilterState.filters) => {
    return (state, action) => {
        if (state === undefined) {
            state = defaultFilterState;
            state.filters = defaultFilterStateFilters;
        }
        switch (action.type) {
            case OPEN_FILTER_MENU:
                return Object.assign({}, state, { filterOpen: true });
            case CLOSE_FILTER_MENU:
                return Object.assign({}, state, { filterOpen: false });
            case UPDATE_FILTERS:
                return Object.assign({}, state, { filters: action.filters });
            case CLEAR_FILTERS:
                return Object.assign({}, state, {
                    filters: defaultFilterStateFilters,
                });
            case REMOVE_FILTER:
                if (action.filter === 'tag') {
                    return Object.assign({}, state, {
                        filters: {
                            ...state.filters,
                            tags: state.filters.tags.filter(tag => tag !== action.value),
                        },
                    });
                }
                return Object.assign({}, state, {
                    filters: {
                        ...state.filters,
                        [action.filter]: defaultFilterStateFilters[action.filter],
                    },
                });
            default:
                return state;
        }
    };
};

export const defaultPropertiesFilters = {
    city: null,
    reservationStatus: null,
    propertyType: null,
    tags: [],
};

export const propertiesFilterReducer = createFilterReducer(defaultPropertiesFilters);

export const propertiesAppliedFiltersFormatter = propertiesFilters =>
    Object.entries(propertiesFilters)
        .map(([key, value]) => ({ key, value }))
        .reduce((acc, { key, value }) => {
            if (key === 'tags') {
                if (value.length > 0)
                    return [...value.map(tag => ({ key: 'tag', value: tag })), ...acc];
                else return [...acc];
            } else if (value) return [{ key, value }, ...acc];
            else return [...acc];
        }, []);

export const getFilters = state => state.filters;
export const getFilterOpen = state => state.filterOpen;

export default filterReducer;
