import React from 'react';

const NoMatch = () => (
    <div className={'root-error-boundary-container'}>
    <div className='root-error-boundary-body'>
        <h2 className='root-error-boundary-header'>{`404 - This page doesn't exist`}</h2>
        <div className='root-error-boundary-text'>
            <span className='root-error-boundary-text emph'>{`That's all we know`}</span>
            <br/>
            <a className='root-error-boundary-link' href="https://noiseaware.io"><b>Click here to go to noiseaware.io</b></a>
            <br/>
            {`If you'd like to talk to support, you can call our support line at (888) 787-8750 or send us an email at `}
            <a className='root-error-boundary-link' href="mailto:support@noiseaware.io"><b>support@noiseaware.io</b></a>
        </div>
    </div>
</div>
);

export default NoMatch;

