import { createSelector } from 'reselect';
import { withRoot } from '../constants';
import { propertiesAppliedFiltersFormatter, getFilters, getFilterOpen } from '../reducers/filter';
import { createTableHeadersDropdownSelector } from '../reducers/tableHeaderDropdown';
import { getAsyncState } from '../reducers/async';

const mountSelector = state => state.ui.properties.propertiesTable;

export const getExpandedPropertyRow = state => state.ui.properties.propertiesTable.expandedRow;

export const getExpandedPropertyRowPopKey = state =>
    state.ui.properties.propertiesTable.expandedRow.popKey;

export const getPropertiesAsyncState = withRoot(mountSelector, getAsyncState);

export const getPropertiesFilters = withRoot(mountSelector, getFilters);

export const getPropertiesFiltersVisibility = withRoot(mountSelector, getFilterOpen);

export const getPropertiesHeaders = state => state.ui.properties.propertiesTable.headers;

export const propertiesHeadersSelector = createTableHeadersDropdownSelector(getPropertiesHeaders);

export const propertiesTablePropertyDropdownSelector = state =>
    state.ui.properties.propertiesTable.headers['Property'].selected;

export const propertiesUIHasErrorSelector = state => state.ui.properties.propertiesTable.hasError;

export const propertiesUILoadingSelector = state => state.ui.properties.propertiesTable.isLoading;

export const propertiesUISelector = state => state.ui.properties.propertiesTable;

export const getPropertiesFiltersApplied = createSelector(
    getPropertiesFilters,
    propertiesAppliedFiltersFormatter
);
