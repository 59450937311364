import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { getUserData } from '../selectors';
import hasUserData from '../containers/hasUserData';

const AdminRoute = ({component: Component, user, ...props}) => {
    // Need to fetch user object before evaluating render conditionals
    return (
        <Route {...props}> 
        {
            user.isLoading? null : 
            user.role === 'Administrator' || user.role === 'God' ? <Component/> : <Redirect to={{pathname: '/dashboard'}}/>
        }
        </Route>

    );
};

const mapStateToProps = (state, ownProps) => {
    return (
    {
        user: getUserData(state),
        component: ownProps.component
    });
};

const mapDispatchToProps = (dispatch) => (
    {
        dispatch
    }
);

export default connect(mapStateToProps, mapDispatchToProps)(hasUserData(AdminRoute));