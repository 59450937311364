import { POP_ROW_CLICK, POP_ROW_BLUR, FETCH_ALERTS } from '../actions';
import tableHeaderDropdownReducer from './tableHeaderDropdown';
import createAsyncStateReducer from './async';
import { composeReducers } from './utils';

const fetchAlertsReducer = createAsyncStateReducer(FETCH_ALERTS);

export const defaultCurrentAlertsTableState = {
    headers: {
        Property: null,
        Start: { options: ['Local', 'Remote'], selected: 'Local', open: false },
        Duration: null,
        Severity: null,
        // 'Reservation': null
    },
    rows: [],
    isLoading: true,
    hasError: null,
    activeAlerts: 0,
    expandedRow: { popKey: 'u' },
    isExpanded: false,
    numProperties: 50,
};

const currentAlertsTable = (state, action) => {
    if (state === undefined) {
        state = defaultCurrentAlertsTableState;
    }
    if (action.type === FETCH_ALERTS.SUCCESS) {
        return Object.assign({}, state, {
            rows: action.json.reduce((acc, curr) => {
                return {
                    [curr.id]: {
                        isLoading: true,
                        hasError: false,
                        init: false,
                        expanded: false,
                    },
                    ...acc,
                };
            }, {}),
        });
    }
    if (action.table === 'currentAlerts') {
        switch (action.type) {
            case POP_ROW_CLICK:
                return Object.assign({}, state, { expandedRow: { popKey: action.popKey } });
            case POP_ROW_BLUR:
                return Object.assign({}, state, { expandedRow: { popKey: 0 } });
            default:
                return Object.assign(
                    {},
                    state,
                    tableHeaderDropdownReducer({ headers: state.headers }, action)
                );
        }
    } else {
        return state;
    }
};

export default composeReducers(
    [currentAlertsTable, fetchAlertsReducer],
    defaultCurrentAlertsTableState
);
