import {
    POP_ROW_CLICK,
    POP_ROW_BLUR,
} from '../actions';

export const defaultEventsPanelState = {
    expanded: false,
    detailsPage: 'Overview',
    isLoading: false,
    hasError: null,
    'Overview': {}
};

const eventsPanel = (state, action) => {
    if(state === undefined) state = defaultEventsPanelState;
    if(action.table === 'events'){
        switch(action.type){
            case POP_ROW_CLICK:
                return Object.assign({}, state, {expanded: true});
            case POP_ROW_BLUR:
                return Object.assign({}, state, {expanded: false, init: false, detailsPage: 'Overview'});
            default:
                return state;
        }
    }
    else {
        return state;
    }
};

export default eventsPanel;