import {
    NAVIGATE_DETAILS_PAGE,
    DELAY_SLIDE_OPEN_DETAIL_PANEL_COMPLETE,
    SLIDE_PANEL_END,
    HIDE_PANEL_CLICK,
    RESET_PANEL,
} from './actions.types';

export const navigateDetailsPage = (detailsPage, table) => {
    return {
        type: NAVIGATE_DETAILS_PAGE,
        table,
        detailsPage
    };
};

export const delaySlideOpenDetailPanelComplete = ({table, popKey}) => {
    return (
        {
            type: DELAY_SLIDE_OPEN_DETAIL_PANEL_COMPLETE,
            table,
            popKey
        }
    );
};

export const slideEnd = (table, {...args}) => {
    return {
        type: SLIDE_PANEL_END,
        table,
        ...args
    };
};

export function handleSlideEnd(e, table) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            if(e.target.classList.contains('sp-expanded')){
                resolve();
            }
        }).then(() => dispatch(slideEnd(table)));
    };
}

export function checkIfExpanded(e){
    return dispatch => {
        return new Promise((resolve, reject) => {
            if(e.target.classList.contains('sp-expanded')){
                resolve();
            }
        });
    };
}

export const hidePanelClick = (table) => {
    return {
        type: HIDE_PANEL_CLICK,
        table
    };
};

export const resetPanel = (table) => {
    return {
        type: RESET_PANEL,
        table
    };
};