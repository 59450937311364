import { combineReducers } from 'redux';
import data from './data';
import { defaultData } from './data';
import user from './user';
import { defaultUserState } from './user';
import ui, { defaultUIState } from './ui';

const noiseAwareApp = combineReducers({
    ui,
    data,
    user,
});

const rootReducer = (state = window.Cypress && window.initialState, action) => {
    if (action.type === 'LOGOUT_SUCCESS') {
        return Object.assign({}, state, {
            data: defaultData,
            ui: defaultUIState,
            user: defaultUserState,
        });
    } else {
        return noiseAwareApp(state, action);
    }
};

export default rootReducer;
