import { combineReducers } from 'redux';
import mobilePanel, { defaultMobilePanelState } from './mobilePanel';
import mobileNav, { defaultMobileNavState } from './mobileNav';
import mobileAdmin, { defaultMobileAdminState } from './mobileAdmin';

const mobile = combineReducers({
    mobilePanel,
    mobileNav,
    mobileAdmin,
});

export default mobile;

export const defaultMobileState = {
    mobileNav: defaultMobileNavState,
    mobilePanel: defaultMobilePanelState,
    mobileAdmin: defaultMobileAdminState,
};
