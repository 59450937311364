import { asyncActionCreator } from './actions.async';
import { FETCH_ORGANIZATION_USERS } from './actions.types';
import { organizationSighV1Api } from '../remote2';

export const fetchOrgUsers = orgKey =>
    asyncActionCreator({
        type: FETCH_ORGANIZATION_USERS,
        createThunk: orgKey => dispatch => {
            return organizationSighV1Api.organizationSighV1GetUsersForOrganization(orgKey);
        },
    })(orgKey);
