import React from 'react';
import { Route } from 'react-router-dom';
import VisibleApp from '../containers/VisibleApp';
import ZenDesk from './ZenDesk';
import { requiresAuthentication, desktopOnly } from '../containers/enhanceRoute';

const AppRoute = ({ children, ...props }) => (
    <Route {...props}>
        <VisibleApp>
            {children}
            <ZenDesk />
        </VisibleApp>
    </Route>
);

export default desktopOnly(requiresAuthentication(AppRoute));
