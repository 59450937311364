import modalReducer from './modal';

export const defaultAddPropertyState = {
    modalOpen: false,
    modalData: {
        nickname: null,
        street1: null,
        city: null,
        state: null,
        postalCode: null,
        key: null,
        hasError: null,
    },
};

const addProperty = (state, action) => {
    if (state === undefined) {
        state = defaultAddPropertyState;
    }
    if (action.table === 'addProperty') {
        return Object.assign(
            {},
            state,
            modalReducer({ modalOpen: state.modalOpen, modalData: state.modalData }, action)
        );
    } else {
        return state;
    }
};

export default addProperty;
