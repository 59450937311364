import { PROTECTED } from 'redux-jwt-protected-middleware';

export const createAsyncTypes = baseType => ({
    BEGIN: `${baseType}_BEGIN`,
    SUCCESS: `${baseType}_SUCCESS`,
    FAILURE: `${baseType}_FAILURE`,
});

/**
 * @description creates an action creator for dispatching a typical thunk with begin, success, and failure action dispatchers
 * @param {Object} asyncArgs
 *  @param {String} asyncArgs.type an object with BEGIN, SUCCESS, and FAILURE keys. `createThunk`: a function that returns a thunk
 *  @param {Function} asyncArgs.createThunk an function that returns a thunk
 *  @param {Object} asyncArgs.beginPayload (optional) additional payload for the begin action
 *  @param {Object} asyncArgs.successPayload (optional) additional payload for the success action
 *  @param {Object} asyncArgs.failurePayload (optional) additional payload for the failure action
 *  @param {Boolean} asyncArgs.isProtected (optional) used to mark the thunk as requiring authentication to complete an API request
 * @returns an action creator that dispatches the begin, success, and failure actions, in accordance with the thunk created via `createThunk`
 */
export const asyncActionCreator = ({
    type: asyncTypes,
    createThunk = () => dispatch => {},
    beginPayload = {},
    successPayload = {},
    failurePayload = {},
    isProtected = true,
}) => (...args) => {
    const thunk = createThunk(...args);
    if (isProtected) thunk[PROTECTED] = true;
    return dispatch => {
        dispatch({
            type: asyncTypes.BEGIN,
            ...beginPayload,
        });
        // wrapping with dispatch here allows for dispatching other thunks
        // from within this, making it extensible and reusable
        return dispatch(thunk)
            .then(json => {
                dispatch({ type: asyncTypes.SUCCESS, json, ...successPayload });
                return json;
            })
            .catch(err => {
                dispatch({ type: asyncTypes.FAILURE, err, ...failurePayload });
                return err;
            });
    };
    /** example usage:
     * const fetchSomeDataAndDoSomethingSync = asyncActionCreator(
     *    {
     *        type: FETCH_SOME_DATA,
     *        createThunk: item => dispatch => api.callData(item).then(json => {
     *            dispatch(someSyncAction({ someParam: item, someData: json }));
     *            return json;
     *        })
     *    }
     * );
     */
};
