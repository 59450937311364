import { panGraph, toggleGraphIntervalSelect } from './actions.defaultPanel';
import { macApi } from '../remote2';
import { FETCH_SENSOR_MAC_COUNT_REPORT } from './actions.types';
import { startAndEndFilters } from '../constants';
import tz from 'timezone';

export function fetchSensorMacCountReport(table, propertyKey, start, end) {
    return dispatch => {
        dispatch(fetchSensorMacCountReportBegin(table));
        const body = {
            filter: [
                { key: 'key', operator: 'in', value: propertyKey },
                ...startAndEndFilters(start, end),
            ],
            pagination: { perPage: 7000, pageNum: 1 },
        };
        return macApi
            .macList(body)
            .then(json => {
                dispatch(fetchSensorMacCountReportSuccess(table, json, propertyKey));
            })
            .catch(err => {
                dispatch(fetchSensorMacCountReportFailure(err, table));
            });
    };
}

export function fetchSensorMacCountReportAndPanGraph(table, propertyKey, start, end) {
    return dispatch => {
        dispatch(fetchSensorMacCountReportBegin(table));
        const body = {
            filter: [
                { key: 'key', operator: 'in', value: propertyKey },
                ...startAndEndFilters(start, end),
            ],
            pagination: { perPage: 7000, pageNum: 1 },
        };
        return macApi
            .macList(body)
            .then(json => {
                dispatch(fetchSensorMacCountReportSuccess(table, json, propertyKey));
                dispatch(panGraph(table, start, end));
            })
            .catch(err => {
                dispatch(fetchSensorMacCountReportFailure(err, table));
            });
    };
}

export function fetchSensorMacCountReportAndSelectInterval(table, propertyKey, start, end, option) {
    return dispatch => {
        dispatch(fetchSensorMacCountReportBegin(table));
        const body = {
            filter: [
                { key: 'key', operator: 'in', value: propertyKey },
                ...startAndEndFilters(start, end),
            ],
            pagination: { perPage: 7000, pageNum: 1 },
        };
        return macApi
            .macList(body)
            .then(json => {
                dispatch(fetchSensorMacCountReportSuccess(table, json, propertyKey));
                dispatch(toggleGraphIntervalSelect(table, start, end, option));
            })
            .catch(err => {
                dispatch(fetchSensorMacCountReportFailure(err, table));
            });
    };
}
export function refreshSensorMacCountReport(propertyKey, interval, table) {
    return dispatch => {
        let start = tz(new Date(), `-${interval.number} ${interval.unit}`);
        let end = tz(new Date());
        const body = {
            filter: [
                { key: 'key', operator: 'in', value: propertyKey },
                ...startAndEndFilters(start, end),
            ],
            pagination: { perPage: 7000, pageNum: 1 },
        };
        return macApi
            .macList(body)
            .then(json => {
                dispatch(panGraph(table, start, end));
                dispatch(fetchSensorMacCountReportSuccess(table, json, propertyKey));
            })
            .catch(err => {
                dispatch(fetchSensorMacCountReportFailure(err, table));
            });
    };
}

export const fetchSensorMacCountReportBegin = table => {
    return {
        type: FETCH_SENSOR_MAC_COUNT_REPORT.BEGIN,
        table,
    };
};

export const fetchSensorMacCountReportSuccess = (table, json, propertyKey) => {
    return {
        type: FETCH_SENSOR_MAC_COUNT_REPORT.SUCCESS,
        table,
        json,
        propertyKey,
    };
};

export const fetchSensorMacCountReportFailure = (err, table) => {
    return {
        type: FETCH_SENSOR_MAC_COUNT_REPORT.FAILURE,
        table,
        err,
    };
};
