import tz from 'timezone';
import queryString from 'query-string';
import { createAsyncStateSelector } from '../reducers/async';
import { getCurrentAlertsAsyncState } from './selectors.currentAlertsTable';
import { getResolvedAlertsAsyncState } from './selectors.resolvedAlerts';
import { getPropertiesAsyncState } from './selectors.propertiesTable';
import { getUserAsyncState } from './selectors.user';
import { getSensorsAsyncState } from './selectors.sensorsTable';

export const alertsSelector = state => state.data.alerts;

export const eventsSelector = state => state.data.events;

export const getAlertByMetaId = (state, props) =>
    state.data.alerts.find(alert => alert.id === props.meta.eventId);

export const getAlertByPropertyKey = (state, props) =>
    state.data.alerts.find(alert => alert.propertyKey === props.propertyKey);

// Note that we sort the messages in reverse chronological order because the display flips them with flex-direction: column-reverse
export const getMessagesByReservation = (state, props) => {
    for (let messagesByReservation of state.data.messages) {
        if (messagesByReservation.reservationID === props.id) {
            let sortedMessages = messagesByReservation.messages.sort((message1, message2) =>
                tz(message2.timeUTC) < tz(message1.timeUTC) ? -1 : 1
            );
            return { ...messagesByReservation, messages: sortedMessages };
        }
    }
    return { reservationID: 0, messages: [] };
};

export const getOrgUsersData = state => state.data.orgUsers;

export const getQueryParams = (state, props) => queryString.parse(props.location.search);

export const getPathParams = (state, props) => (props.match ? props.match.params : {});

export const getPropertiesTags = state => [
    ...new Set(state.data.properties.reduce((acc, curr) => [...curr.tags, ...acc], [])),
];
// use Set for removing duplicate tags

export const getProperty = (state, props) =>
    props.propertyKey
        ? state.data.properties.find(property => property.key === props.propertyKey)
        : { key: undefined };

export const getICalLinksByPropertyKey = (state, props) =>
    state.data.iCalLinks[props.propertyKey] || [];

export const getPropertyByPathParam = (state, props) =>
    props.match
        ? state.data.properties.find(property => property.key === props.match.params.propertyKey)
        : {};

export const getPropertyEvents = (state, props) =>
    props.propertyKey
        ? state.data.events.filter(event => event.propertyKey === props.propertyKey)
        : [];

export const getPropertySensors = (state, props) => {
    const sensors = state.data.sensors.filter(sensor => sensor.propertyKey === props.propertyKey);
    if (sensors.length) return sensors;
    else return [];
};

export const getPropertySensorsData = (state, props) => {
    for (let sensorsByProperty of state.data.sensorsData) {
        if (sensorsByProperty.propertyKey === props.propertyKey) return sensorsByProperty;
    }
    return {
        timezone: 'string',
        propertyKey: 'string',
        data: [],
        zones: [],
    };
};

export const propertySensorsDataSelector = state => state.data.sensorsData;

export const sensorDecibelReportSelector = state => state.data.sensorDecibelReports;

export const sensorMacCountReportSelector = state => state.data.sensorMacCountReports;

export const getCurrentReservation = (state, props) => {
    for (let reservation of state.data.reservations) {
        if (reservation.propertyKey === props.propertyKey && reservation.current) {
            return {
                status: reservation.type,
                guest: `${reservation.guest.firstName || ''} ${reservation.guest.lastName || ''}`,
                phone: reservation.guest.phone,
                start: reservation.start,
                end: reservation.end,
                id: reservation.id,
                guestCount: reservation.expectedGuestCount,
                type: reservation.type,
                email: reservation.guest.email,
            };
        }
    }
    return {
        start: '',
        end: '',
        guest: '',
        phone: '',
        status: 'No reservation data',
        id: 0,
        guestCount: 0,
        type: 'Vacant',
        email: '',
    };
};

export const getSensorEvents = (state, props) =>
    state.data.events.filter(
        event => event.zoneKey === props.sensorKey && ['Noise', 'Sensor'].includes(event.type)
    );

export const getSensorByKey = (state, props) =>
    state.data.sensors.find(sensor => sensor.key === props.sensorKey);

export const getSensorByPathParam = (state, props) =>
    props.match
        ? state.data.sensors.find(sensor => sensor.key === props.match.params.sensorKey)
        : {};

export const getSensorPhotoCachedDataByKey = (state, props) =>
    (
        state.data.sensorPhotosCache.find(sensor => sensor.sensorKey === props.sensorKey) || {
            data: '',
        }
    ).data;

export const getAppDataState = createAsyncStateSelector([
    getCurrentAlertsAsyncState,
    getResolvedAlertsAsyncState,
    getPropertiesAsyncState,
    getUserAsyncState,
    getSensorsAsyncState,
]);

export const propertiesAreLoadingSelector = state => state.data.propertiesAreLoading;

export const propertiesSelector = state => state.data.properties;

export const sensorsSelector = state => state.data.sensors;

export const sensorPhotosSelector = state => state.data.sensorPhotosCache;

export const integrationsSelector = state => state.data.integrations;

export const reservationsSelector = state => state.data.reservations;

export const iCalLinksSelector = state => state.data.iCalLinks;
