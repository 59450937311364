import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import hasAppData from './hasAppData';
import App from './App';
import { getAppDataState } from '../selectors';

const mapStateToProps = state => ({
    appData: getAppDataState(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    dispatch,
});

const VisibleApp = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(hasAppData(App))
);

export default VisibleApp;
