import { createTableHeadersDropdownSelector } from '../reducers/tableHeaderDropdown';
import { withRoot } from '../constants';
import { getAsyncState } from '../reducers/async';

const mountSelector = state => state.ui.dashboard.resolvedAlerts;

export const getResolvedAlertsAsyncState = withRoot(mountSelector, getAsyncState);

export const getResolvedAlertsHeaders = state => state.ui.dashboard.resolvedAlerts.headers;

export const resolvedAlertsHeadersSelector = createTableHeadersDropdownSelector(
    getResolvedAlertsHeaders
);

export const getResolvedAlertsRow = (state, props) =>
    state.ui.dashboard.resolvedAlerts.rows[props.id];

export const resolvedAlertsUISelector = state => state.ui.dashboard.resolvedAlerts;
