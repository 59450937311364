import { combineReducers } from 'redux';
import currentAlertsPanel from './currentAlertsPanel';
import { defaultCurrentAlertsPanelState } from './currentAlertsPanel';
import currentAlertsTable, { defaultCurrentAlertsTableState } from './currentAlertsTable';

const currentAlerts = combineReducers({
    currentAlertsPanel,
    currentAlertsTable
});

export default currentAlerts;

export const defaultCurrentAlertsState = { 
    currentAlertsPanel: defaultCurrentAlertsPanelState,
    currentAlertsTable: defaultCurrentAlertsTableState
};