import {
    TOGGLE_ROW_EXPAND,
    TOGGLE_ROW_BEGIN,
    TOGGLE_ROW_SUCCESS,
    TOGGLE_ROW_FAIL,
    TOGGLE_ROW_BLUR,
    FETCH_ALERTS,
} from '../actions';
import tableHeaderDropdownReducer from './tableHeaderDropdown';
import createAsyncStateReducer from './async';
import { composeReducers } from './utils';

const fetchAlertsReducer = createAsyncStateReducer(FETCH_ALERTS);

export const defaultResolvedAlertsState = {
    headers: {
        Property: null,
        'Start (Local)': null,
        End: { options: ['Local', 'Remote'], selected: 'Local', open: false },
        Duration: null,
    },
    rows: [],
    isLoading: true,
    hasError: null,
    numResolved: 0,
};

const resolvedAlerts = (state, action) => {
    if (state === undefined) {
        state = defaultResolvedAlertsState;
    }
    if (action.type === FETCH_ALERTS.SUCCESS) {
        return Object.assign({}, state, {
            rows: action.json.reduce((acc, curr) => {
                return {
                    [curr.id]: {
                        isLoading: true,
                        hasError: false,
                        init: false,
                        expanded: false,
                    },
                    ...acc,
                };
            }, {}),
        });
    }
    if (action.table === 'resolvedAlerts') {
        switch (action.type) {
            case TOGGLE_ROW_EXPAND:
                return Object.assign({}, state, {
                    rows: [
                        ...state.rows.slice(0, action.id),
                        { ...state.rows[action.id], expanded: !state.rows[action.id].expanded },
                        ...state.rows.slice(action.id + 1),
                    ],
                });
            case TOGGLE_ROW_BEGIN:
                return Object.assign({}, state, {
                    rows: [
                        ...state.rows.slice(0, action.id),
                        { ...state.rows[action.id], expanded: true, isLoading: true },
                        ...state.rows.slice(action.id + 1),
                    ],
                });
            case TOGGLE_ROW_SUCCESS:
                return Object.assign({}, state, {
                    rows: [
                        ...state.rows.slice(0, action.id),
                        { ...state.rows[action.id], init: true, isLoading: false },
                        ...state.rows.slice(action.id + 1),
                    ],
                });
            case TOGGLE_ROW_FAIL:
                return Object.assign({}, state, {
                    rows: [
                        ...state.rows.slice(0, action.id),
                        { ...state.rows[action.id], hasError: action.err, isLoading: false },
                        ...state.rows.slice(action.id + 1),
                    ],
                });
            case TOGGLE_ROW_BLUR:
                return Object.assign({}, state, {
                    rows: [
                        ...state.rows.slice(0, action.id),
                        { ...state.rows[action.id], expanded: false },
                        ...state.rows.slice(action.id + 1),
                    ],
                });
            default:
                return Object.assign(
                    {},
                    state,
                    tableHeaderDropdownReducer({ headers: state.headers }, action)
                );
        }
    } else {
        return state;
    }
};

export default composeReducers([resolvedAlerts, fetchAlertsReducer], defaultResolvedAlertsState);
