import * as selectors from './selectors.defaultPanel';
import { globalizeSelectors } from '../constants';

const rootSelector = state => state.ui.dashboard.currentAlerts.currentAlertsPanel;
const globalSelectors = globalizeSelectors(selectors, rootSelector);
export const {
    defaultPanelSettingsModalOpenSelector: getCurrentAlertsDetailSettingsModalOpen,
    defaultPanelSettingsModalDataSelector: getCurrentAlertsDetailSettingsModalData,
    defaultPanelMessagesIsEditingReservationSelector: getCurrentAlertsDetailMessagesIsEditingReservation,
    defaultPanelDataSensorGraphsVisibleSelector: getCurrentAlertsDetailDataSensorGraphsVisible,
    defaultPanelDataIntervalSelector: getCurrentAlertsDetailDataInterval,
    defaultPanelDataIntervalExpandedSelector: getCurrentAlertsDetailDataIntervalExpanded,
    defaultPanelDataUISelector: getCurrentAlertsDetailDataUI,
    defaultPanelDataEventIDSelector: getCurrentAlertsDetailDataEventID,
    defaultPanelGraphEndpointsSelector: getCurrentAlertsDetailGraphEndpoints,
    defaultPanelNRSSettingsListSelector: getCurrentAlertsDetailNRSSettingsList,
    defaultPanelMessagesErrorSelector: getCurrentAlertsDetailMessagesError,
    defaultPanelMessagesModalOpenSelector: getCurrentAlertsDetailMessagesModalOpen,
    defaultPanelMessagesModalDataSelector: getCurrentAlertsDetailMessagesModalData,
    defaultPanelMessagesLoadingSelector: getCurrentAlertsDetailMessagesLoading,
    defaultPanelEventRangeSelector: getCurrentAlertsDetailEventRange,
    defaultPanelIsAddingNoteSelector: currentAlertsDetailEventsIsAddingNoteSelector,
    defaultPanelIsInitSelector: getCurrentAlertsDetailPanelInit,
    defaultPanelUISelector: getCurrentAlertsDetailPanelUI,
    defaultPanelKeySelector: getCurrentAlertsDetailPanelKey,
} = globalSelectors;
export default rootSelector;
