import {
    RESET_INTEGRATION_SETUP,
    TOGGLE_ALL_INTEGRATION_PROPERTIES,
    TOGGLE_INTEGRATION_PROPERTY,
    CREATE_INTEGRATION,
} from './actions.types';
import { integrationSighV1Api } from '../remote2';

export const resetIntegrationSetup = () => {
    return {
        type: RESET_INTEGRATION_SETUP,
    };
};

export const toggleAllIntegrationProperties = (col, state) => {
    return {
        type: TOGGLE_ALL_INTEGRATION_PROPERTIES,
        col,
        state,
    };
};

export const toggleIntegrationProperty = (key, col) => {
    return {
        type: TOGGLE_INTEGRATION_PROPERTY,
        key,
        col,
    };
};

export const beginCreateIntegration = () => {
    return {
        type: CREATE_INTEGRATION.BEGIN,
    };
};

export const createIntegrationSuccess = () => {
    return {
        type: CREATE_INTEGRATION.SUCCESS,
    };
};

export const createIntegrationFailure = err => {
    return {
        type: CREATE_INTEGRATION.FAILURE,
        err,
    };
};

export function createIntegration(integration, values) {
    return dispatch => {
        dispatch(beginCreateIntegration());
        const body = {
            name: integration.name,
            keys: values.apiKey,
            authenticationType: integration.authenticationType,
            numKeys: Number(integration.numKeys),
            receiveAlerts: true,
            enabled: true,
            rcv_alerts_interval: 0,
            rcv_alerts_noise: true,
            rcv_alerts_disconnect: true,
            reservationsSubscribed: values.sync,
        };
        // return api.integrationsPost(body)
        return integrationSighV1Api
            .integrationSighV1CreateIntegrationsForPermittedUser(body)
            .then(() => dispatch(createIntegrationSuccess()))
            .catch(err => {
                dispatch(createIntegrationFailure(err));
                throw new Error(`Failed to create integration with ${integration.name}`);
            });
    };
}
