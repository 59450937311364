import {
    SELECT_PROPERTY_FROM_SEARCH,
    VIEW_NRS_GRAPH,
    VIEW_NRS_GRAPH_EVENT,
    DELAY_SLIDE_OPEN_DETAIL_PANEL_COMPLETE,
    POP_ROW_CLICK,
    POP_ROW_BLUR,
    FETCH_PROPERTIES,
    DELETE_PROPERTY,
} from '../actions';
import { defaultFilterState, propertiesFilterReducer, defaultPropertiesFilters } from './filter';
import tableHeaderDropdownReducer from './tableHeaderDropdown';
import createAsyncStateReducer from './async';
import { composeReducers } from './utils';

const fetchPropertiesReducer = createAsyncStateReducer(FETCH_PROPERTIES);

const deletePropertyReducer = createAsyncStateReducer(DELETE_PROPERTY, {
    actionFilters: [action => action.table === 'properties'],
});

export const defaultPropertiesTableState = {
    headers: {
        Property: { options: ['Ascending', 'Descending'], selected: 'Ascending', open: false },
        'Property Tags': null,
        City: null,
        Timezone: null,
        // Reservation: null,
    },
    rows: [],
    isLoading: true,
    hasError: null,
    expandedRow: { popKey: 'u' },
    filterOpen: defaultFilterState.filterOpen,
    filters: defaultPropertiesFilters,
};

const propertiesTable = (state, action) => {
    switch (action.type) {
        case FETCH_PROPERTIES.SUCCESS:
            return Object.assign({}, state, {
                rows: action.json.map((property, index) => {
                    return {
                        id: index,
                        isLoading: true,
                        hasError: false,
                        init: false,
                    };
                }),
            });
    }
    if (action.table === 'properties') {
        switch (action.type) {
            case SELECT_PROPERTY_FROM_SEARCH:
                return Object.assign({}, state, { expandedRow: { popKey: action.propertyKey } });
            case VIEW_NRS_GRAPH:
                return Object.assign({}, state, {
                    expandedRow: { popKey: action.propertyKey },
                });
            case VIEW_NRS_GRAPH_EVENT:
                return Object.assign({}, state, {
                    expandedRow: { popKey: action.propertyKey },
                });
            case DELAY_SLIDE_OPEN_DETAIL_PANEL_COMPLETE:
            case POP_ROW_CLICK:
                return Object.assign({}, state, {
                    expandedRow: { popKey: action.popKey },
                });
            case POP_ROW_BLUR:
                return Object.assign({}, state, { expandedRow: { popKey: 'u' } });
            case DELETE_PROPERTY.BEGIN:
                return Object.assign({}, state, { expandedRow: { popKey: 'u' } });
            default:
                return Object.assign(
                    {},
                    state,
                    propertiesFilterReducer(
                        { filterOpen: state.filterOpen, filters: state.filters },
                        action
                    ),
                    tableHeaderDropdownReducer({ headers: state.headers }, action)
                );
        }
    } else {
        return state;
    }
};

export default composeReducers(
    [propertiesTable, fetchPropertiesReducer, deletePropertyReducer],
    defaultPropertiesTableState
);
