import { modalOpenSelector, modalDataSelector } from '../reducers/modal';
import { withRoot, reservationIcons, reservationIconColors, noop } from '../constants';
import { createSelector } from 'reselect';
import { propertiesSelector, iCalLinksSelector } from './selectors.data';

const mountSelector = state => state.ui.reservations.iCalLinks;

export const getICalLinksModalOpen = withRoot(mountSelector, modalOpenSelector);

export const getICalLinksModalData = withRoot(mountSelector, modalDataSelector);

export const getICalLinksModalPropertyName = createSelector(
    getICalLinksModalData,
    propertiesSelector,
    (modalData, properties) => {
        return (
            properties.find(property => property.key === modalData.propertyKey) || {
                nickname: '(No Name)',
            }
        ).nickname;
    }
);

export const getICalLinksByModalPropertyKey = createSelector(
    getICalLinksModalData,
    iCalLinksSelector,
    (modalData, iCalLinks) => iCalLinks[modalData.propertyKey] || []
);

export const getICalLinksTableHeaders = state => state.ui.reservations.iCalLinks.headers;

export const iCalLinksTableHeadersSelector = createSelector(
    getICalLinksTableHeaders,
    headers => {
        let keys = Object.keys(headers);
        let headerData = keys.map((key, i) => {
            if (headers[key] !== null) {
                return {
                    id: i,
                    text: key,
                    dropDown: {
                        options: headers[key].options.map((opt, oi) => ({
                            id: oi,
                            text: opt,
                            selected: headers[key].selected === opt ? true : false,
                        })),
                        open: headers[key].open,
                    },
                };
            } else {
                return { id: i, text: key };
            }
        });
        return headerData;
    }
);

export const iCalLinksTableSelector = createSelector(
    propertiesSelector,
    iCalLinksSelector,
    iCalLinksTableHeadersSelector,
    (properties, iCalLinks, headers) => {
        let rows = properties.map(property => {
            let iCalLinksForProperty = iCalLinks[property.key] || [];
            let iCalLinkIcons = iCalLinksForProperty.map(link => ({
                fn: reservationIcons[link.platform] || noop,
                props: {
                    stroke: link.lastUpdated
                        ? reservationIconColors.valid
                        : reservationIconColors.pending,
                },
            }));
            return {
                popKey: property.key,
                contents: [
                    { id: 0, text: property.nickname },
                    {
                        id: 1,
                        render: tableProps =>
                            iCalLinkIcons.map(({ fn, props }) => fn({ ...tableProps, ...props })),
                    },
                    { id: 2, icon: 'fas fa-external-link-alt' },
                ],
            };
        });
        return {
            headers,
            rows,
        };
    }
);
