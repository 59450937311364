import {
    NAVIGATE_MOBILE_PAGE,
} from './actions.types';

export const navigateMobilePage = (table, page) => {
    return {
        type: NAVIGATE_MOBILE_PAGE,
        page,
        table
    };
};