import { FETCH_EVENTS, FETCH_MORE_EVENTS } from './actions.types';

export const fetchEventsBegin = () => {
    return {
        table: 'events',
        type: FETCH_EVENTS.BEGIN,
    };
};

export const fetchEventsSuccess = events => {
    return {
        type: FETCH_EVENTS.SUCCESS,
        table: 'events',
        events,
    };
};

export const fetchEventsFailure = err => {
    return {
        type: FETCH_EVENTS.FAILURE,
        table: 'events',
        err,
    };
};

export const fetchMoreEventsBegin = () => {
    return {
        table: 'events',
        type: FETCH_MORE_EVENTS.BEGIN,
    };
};

export const fetchMoreEventsSuccess = (events, limit) => {
    return {
        type: FETCH_MORE_EVENTS.SUCCESS,
        table: 'events',
        events,
        limit,
    };
};
