export const defaultMobileNavState = {
    navBar: 'default',
};

const mobileNav = (state, action) => {
    if (state === undefined) {
        state = defaultMobileNavState;
    }
    if (action.table === 'currentAlerts' || action.table === 'properties') {
        switch (action.type) {
            case 'POP_ROW_CLICK':
                return Object.assign({}, state, { navBar: 'detail' });
            case 'POP_ROW_BLUR':
                return Object.assign({}, state, { navBar: 'default' });
            default:
                return state;
        }
    } else {
        return state;
    }
};

export default mobileNav;
