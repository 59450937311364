import React from 'react';
import { NavLink } from 'react-router-dom';

const PageLink = ({ page, title, children, navClassName, navActiveClassName }) => (
    <NavLink
        to={`/${page}`}
        activeClassName={navActiveClassName ? navActiveClassName : 'nav-active'}
        className={navClassName ? navClassName : 'nav-link'}
        title={title ? title : page}>
        {children}
    </NavLink>
);

export default PageLink;
