import React from 'react';

const MobileHeader = ( {nav, searchFocused, onSearchFocus, onSearchBlur, onToggleClick}) => (
    <header className={searchFocused ? 'header-container' : 'header-container header-closed'}>
        <div className={searchFocused ? 'header-background' : 'header-background header-hidden'}></div>
        <div className='header-main'>
            <div className='header-content'>
                <div className='header-hamburger' onClick={onToggleClick}>
                    {nav === 'open'? <i className='fas fa-times'></i> : <i className="fas fa-bars"></i>}
                </div>
                <div className='header-title'>
                    Dashboard
                </div>
                <div className='header-search' onClick={onSearchFocus}>
                    {searchFocused? <i className='fas fa-times'></i> : <i className="fas fa-search"></i>}
                </div>
            </div>
        </div>
        {searchFocused && 
            <div className='header-search-bar'>
                <input type='text' name='search' placeholder='Search' className='search-input'/>
            </div>
        }
    </header>
);

export default MobileHeader;