import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import protectedMiddleware from './middleware';
import rootSaga from './sagas';
import rootReducer from './reducers';
import Root from './containers/Root';
import { getStateWith } from 'reselect-tools';
import { apiList } from './constants';

const history = createBrowserHistory();
const sagaMiddleWare = createSagaMiddleware();

const middleware = [protectedMiddleware, routerMiddleware(history), thunk, sagaMiddleWare];

let store = createStore(
    connectRouter(history)(rootReducer),
    composeWithDevTools({
        trace: true,
        traceLimit: 25,
    })(applyMiddleware(...middleware))
);
sagaMiddleWare.run(rootSaga);

getStateWith(() => store.getState());

ReactDOM.render(<Root store={store} history={history} />, document.getElementById('root'));

export default store;

if (process.env.NODE_ENV !== 'production') {
    console.log(`dashboard version: beta
Portal API:     ${apiList.portal}`);
}

if (window.Cypress) {
    window.store = store;
}
