import modalReducer, { defaultModalState } from './modal';
import {
    FETCH_INTEGRATIONS_LIST_DATA,
    FETCH_INTEGRATIONS_DATA,
    RESET_INTEGRATION_SETUP,
    FETCH_INTEGRATION_PROPERTIES_SUCCESS,
    FETCH_INTEGRATION_NEW_PROPERTIES,
    TOGGLE_ALL_INTEGRATION_PROPERTIES,
    TOGGLE_INTEGRATION_PROPERTY,
    CREATE_INTEGRATION,
    GO_BACK_CARD,
    GO_NEXT_CARD,
} from '../actions';

export const defaultAdminIntegrationState = {
    integrationIsLoading: true,
    integrationHasError: null,
    propertiesAreLoading: true,
    currentCard: 'API Key',
    prevCard: null,
    nextCard: 'Import Properties',
    'API Key': {
        complete: false,
        completedText: '',
        isLoading: false,
        hasError: null,
        number: 1,
        next: 'Import Properties',
        prev: null,
    },
    'Import Properties': {
        complete: false,
        isLoading: false,
        number: 2,
        next: null,
        prev: 'API Key',
    },
    breadcrumbs: 2,
    properties: [],
    headers: {
        'Listing Name': null,
        'Add to NoiseAware': null,
    },
    cols: {
        'Listing Name': { type: 'text' },
        'Add to NoiseAware': { type: 'toggle', checked: false },
    },
    modalOpen: defaultModalState.modalOpen,
    modalData: defaultModalState.modalData,
};

const adminIntegration = (state, action) => {
    if (state === undefined) {
        state = defaultAdminIntegrationState;
    }
    switch (action.type) {
        case FETCH_INTEGRATIONS_LIST_DATA.BEGIN:
            return Object.assign({}, state, {
                integrationIsLoading: true,
            });
        case FETCH_INTEGRATIONS_DATA.SUCCESS:
            return Object.assign({}, state, {
                integrationIsLoading: false,
                integrationHasError: null,
            });
        case FETCH_INTEGRATIONS_DATA.FAILURE:
            return Object.assign({}, state, {
                integrationIsLoading: false,
                integrationsHasError: action.err,
            });
        case RESET_INTEGRATION_SETUP:
            return Object.assign({}, state, defaultAdminIntegrationState);
        case FETCH_INTEGRATION_PROPERTIES_SUCCESS:
            return Object.assign({}, state, {
                properties: action.json.map((property, id) => ({
                    ...property,
                    checked: false,
                    key: `${id}`,
                })),
            });
        case FETCH_INTEGRATION_NEW_PROPERTIES.BEGIN:
            return Object.assign({}, state, {
                propertiesAreLoading: true,
            });
        case FETCH_INTEGRATION_NEW_PROPERTIES.SUCCESS:
            return Object.assign({}, state, {
                propertiesAreLoading: false,
                integrationHasError: null,
                properties: action.json.map((property, id) => ({
                    ...property,
                    checked: false,
                    key: `${id}`,
                })),
            });
        case TOGGLE_ALL_INTEGRATION_PROPERTIES:
            return Object.assign({}, state, {
                properties: state.properties.map(property => ({
                    ...property,
                    checked: action.state,
                })),
                cols: {
                    ...state.cols,
                    'Add to NoiseAware': {
                        ...state.cols['Add to NoiseAware'],
                        checked: action.state,
                    },
                },
            });
        case TOGGLE_INTEGRATION_PROPERTY:
            return Object.assign({}, state, {
                properties: state.properties.map(property =>
                    property.key === action.key
                        ? { ...property, checked: !property.checked }
                        : property
                ),
            });
        case CREATE_INTEGRATION.BEGIN:
            return Object.assign({}, state, {
                'API Key': { ...state['API Key'], isLoading: true },
            });
        case CREATE_INTEGRATION.FAILURE:
            return Object.assign({}, state, {
                'API Key': { ...state['API Key'], isLoading: false, hasError: action.err },
            });
    }
    if (action.table === 'adminIntegration') {
        switch (action.type) {
            case GO_BACK_CARD:
                return Object.assign({}, state, {
                    [state.prevCard]: {
                        ...state[state.prevCard],
                        complete: false,
                        completedText: null,
                    },
                    currentCard: state.prevCard,
                    prevCard: state[state.prevCard].prev,
                    nextCard: state.currentCard,
                });
            case GO_NEXT_CARD:
                return Object.assign({}, state, {
                    [state.currentCard]: {
                        ...state[state.currentCard],
                        isLoading: false,
                        complete: true,
                        completedText: action.text,
                    },
                    prevCard: state.currentCard,
                    currentCard: state.nextCard,
                    nextCard: state[state.nextCard].next,
                });
            default:
                return Object.assign(
                    {},
                    state,
                    modalReducer({ modalOpen: state.modalOpen, modalData: state.modalData }, action)
                );
        }
    } else return state;
};

export default adminIntegration;
