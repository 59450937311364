import React from 'react';

export default class RootErrorBoundary extends React.Component {
    constructor(props){
        super(props);
        this.state = {error: null, errorInfo: null}; // We want local props in case Redux store goes down
    }
    componentDidCatch(error, errorInfo){
        this.setState({
            error, errorInfo
        });
    }
    render() {
        if(this.state.errorInfo){
            return (
                <div className={this.state.errorInfo ? 'root-error-boundary-container' : 'root-error-boundary-container root-error-boundary-hidden'}>
                    <div className='root-error-boundary-body'>
                        <h2 className='root-error-boundary-header'>Something went wrong.</h2>
                        <div className='root-error-boundary-text'>
                            <span className='root-error-boundary-text emph'>That&apos;s all we know.</span>
                            <br/>
                            {`If you'd like to talk to support, you can call our support line at (888) 787-8750 or send us an email at `}
                            <a className='root-error-boundary-link' href="mailto:support@noiseaware.io"><b>support@noiseaware.io</b></a>
                        </div>
                    </div>
                </div>
            );
        }
        return this.props.children;
    }

}