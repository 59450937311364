import { createStructuredSelector, createSelector } from 'reselect';

/**
 * @description A higher-order reducer for creating a reducer to handle async states
 * @param {Object} asyncActionType: An object with keys BEGIN, SUCCESS, FAILURE, that correspond to the async action types
 * @param {Object} opts Options
 *  @param {Array[Function]} opts.actionFilters: Functions to filter the action types which trigger the reducer
 *  @param {String} opts.statePrefix: prefixes the default 'isLoading', 'hasSuccess', and 'hasError' state keys
 */
const createAsyncStateReducer = (
    asyncActionType,
    opts = { actionFilters: [], statePrefix: '' }
) => {
    let isLoading = 'isLoading',
        hasSuccess = 'hasSuccess',
        hasError = 'hasError';
    const prefix = (string, pre) => pre + string.charAt(0).toUpperCase() + string.slice(1);
    if (opts.statePrefix) {
        isLoading = prefix(isLoading, opts.statePrefix);
        hasSuccess = prefix(hasSuccess, opts.statePrefix);
        hasError = prefix(hasError, opts.statePrefix);
    }
    const defaultState = {
        [isLoading]: false,
        [hasSuccess]: false,
        [hasError]: null,
    };
    return (state = defaultState, action) => {
        if (opts.actionFilters && opts.actionFilters.every(filter => filter(action))) {
            switch (action.type) {
                case asyncActionType.BEGIN:
                    return Object.assign({}, state, {
                        [isLoading]: true,
                        [hasSuccess]: false,
                        [hasError]: null,
                    });
                case asyncActionType.SUCCESS:
                    return Object.assign({}, state, {
                        [isLoading]: false,
                        [hasSuccess]: true,
                        [hasError]: null,
                    });
                case asyncActionType.FAILURE:
                    return Object.assign({}, state, {
                        [isLoading]: false,
                        [hasSuccess]: false,
                        [hasError]: action.err,
                    });
                default:
                    return state;
            }
        } else {
            return state;
        }
    };
};

export default createAsyncStateReducer;
export const getAsyncState = state => ({
    isLoading: state.isLoading,
    hasError: state.hasError,
    hasSuccess: state.hasSuccess,
});

/**
 * @description A selector creator that computes the aggregate loading state for a set of async resources
 * @param {Array} asyncSelectors an array of selector functions that all return an object like `{ isLoading, hasSuccess, hasError }`
 * States have the following precedence:
 *  if any are uninitialized, then the aggregate state is uninitialized
 *  if any has error, then the aggregate state has an error
 *  if any are loading, then the aggregate state is loading
 *  if all are successful, then the aggregate state is successful
 * This is similar in spirit to how `Promise.all` works
 * @returns the object { isLoading, hasSuccess, hasError } with the computed state
 */
export function createAsyncStateSelector(asyncSelectors = []) {
    const getAsync = createStructuredSelector(asyncSelectors);
    return createSelector(
        getAsync,
        asyncState => {
            const asyncStateValues = Object.values(asyncState);
            if (
                asyncStateValues.some(
                    state => !state.isLoading && !state.hasSuccess && !state.hasError
                )
            )
                return { isLoading: false, hasSuccess: false, hasError: null };
            if (asyncStateValues.some(state => state.hasError))
                return { isLoading: false, hasSuccess: false, hasError: true };
            if (asyncStateValues.some(state => state.isLoading))
                return { isLoading: true, hasSuccess: false, hasError: null };
            if (asyncStateValues.every(state => state.hasSuccess))
                return { isLoading: false, hasSuccess: true, hasError: false };
        }
    );
}
