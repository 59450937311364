import { createTableHeadersDropdownSelector } from '../reducers/tableHeaderDropdown';

export const getAdminIntegrationCurrentCard = state => state.ui.admin.adminIntegration.currentCard;

export const adminIntegrationCardsSelector = state => {
    return {
        'API Key': state.ui.admin.adminIntegration['API Key'],
        'Import Properties': state.ui.admin.adminIntegration['Import Properties'],
    };
};

export const getAdminIntegrationImportSettingsHeaders = state =>
    state.ui.admin.adminIntegration.headers;

export const adminIntegrationImportSettingsHeadersSelector = createTableHeadersDropdownSelector(
    getAdminIntegrationImportSettingsHeaders
);

export const getAdminIntegrationImportSettingsColumns = state =>
    state.ui.admin.adminIntegration.cols;

export const getAdminIntegrationProperties = state => state.ui.admin.adminIntegration.properties;

export const getAdminIntegrationActive = state => state.ui.admin.adminIntegration.integration;

export const getAdminIntegrationModalOpen = state => state.ui.admin.adminIntegration.modalOpen;

export const getAdminIntegrationModalData = state => state.ui.admin.adminIntegration.modalData;

export const getAdminIntegrationIsLoading = state =>
    state.ui.admin.adminIntegration.integrationIsLoading;

export const getAdminIntegrationHasError = state =>
    state.ui.admin.adminIntegration.integrationHasError;

export const getAdminIntegrationPropertiesAreLoading = state =>
    state.ui.admin.adminIntegration.propertiesAreLoading;
