import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import tz from 'timezone';
import { authCookieDomain, REFRESH_TOKEN, USER_TOKEN_EXPIRES_AT } from './constants';

/**
 * @description Sets appropriate authentication cookies after successful authentication refresh
 * @param {Object} TokenPackage `{userToken, expiresAt, refreshToken}`
 *  @param {String} TokenPackage.userToken JWT user token base-64 encoded
 *  @param {String} TokenPackage.expiresAt ISO 8601 datetime string (ex. "2001-08-01T22:31:45Z")
 *  @param {String} TokenPackage.refreshToken JWT refresh token base-64 encoded
 */
export const setRefreshCookies = ({ expiresAt, refreshToken }) => {
    Cookies.set(USER_TOKEN_EXPIRES_AT, expiresAt, { domain: authCookieDomain });
    Cookies.set(REFRESH_TOKEN, refreshToken, { domain: authCookieDomain });
};

/**
 * @description Utility method to get the expiration of the refreshToken JWT from Cookies
 * NB: The token expiration is given in seconds, whereas Date.now() is in milliseconds
 * @param {String} unit Either 'ms' or 'sec'; the unit to return the expiration time in
 */
export const getRefreshExpiry = (unit = 'ms') => {
    const refreshToken = getRefreshToken();
    if (!refreshToken) return undefined;
    const decodedRefreshToken = jwtDecode(refreshToken);
    const refreshExpiry = decodedRefreshToken.exp;
    return unit === 'sec' ? refreshExpiry : 1000 * refreshExpiry;
};

export const userTokenExpired =
    Cookies.get(USER_TOKEN_EXPIRES_AT) && tz(Cookies.get(USER_TOKEN_EXPIRES_AT)) < Date.now();

export const userTokenValid = userToken =>
    !!userToken && userToken !== 'undefined' && !userTokenExpired;

export const getRefreshToken = () => Cookies.get(REFRESH_TOKEN);

export const refreshTokenExpired = () => getRefreshExpiry() && getRefreshExpiry() < Date.now();

export const refreshTokenValid = !!getRefreshToken() && !refreshTokenExpired();
