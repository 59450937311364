import {
    OPEN_MODAL,
    CLOSE_MODAL,
} from './actions.types';

export const openModal = (table, modalData, ...args) => {
    return {
        table,
        type: OPEN_MODAL,
        modalData,
        ...args
    };
};

export const closeModal = (table, ...args) => {
    return {
        table,
        type: CLOSE_MODAL,
        ...args
    };
};