import {
    all,
    put,
    select,
    take,
    cancel,
    call,
    fork,
    race,
    flush,
    actionChannel,
} from 'redux-saga/effects';
import { getSensorPhotoCachedDataByKey } from '../selectors';
import { getSensorByKey } from '../selectors';
import {
    resetPanel,
    fetchSensorPhotoBegin,
    fetchSensorPhotoSuccess,
    fetchSensorPhotoFailure,
    cacheSensorPhoto,
    fetchEventsSuccess,
    fetchSensorEventsBegin,
    fetchSensorEventsSuccess,
    readJPEGBegin,
    readJPEGSuccess,
    loadImgSrcBegin,
    loadImgSrcSuccess,
    fetchSensorEventsFailure,
} from '../actions';
import {
    POP_ROW_BLUR,
    HIDE_PANEL_CLICK,
    PROPERTY_DETAIL_ICON_CLICK,
    FETCH_SENSOR_PHOTO,
    READ_JPEG,
    LOAD_IMG_SRC,
    FETCH_SENSOR_EVENTS,
    FETCH_SENSORS,
    SLIDE_PANEL_END,
} from '../actions';
import { sleep, readJPEGAndGetOrientation, correctJPEGOrientation } from '../constants';
import { eventV1Api, sensorPhotoGet } from '../remote2';

const actionsThatCloseSensorDetailPanel = [
    POP_ROW_BLUR,
    HIDE_PANEL_CLICK,
    '@@router/LOCATION_CHANGE',
    PROPERTY_DETAIL_ICON_CLICK,
];

function* loadSensorPhoto(sensor) {
    if (sensor.image) {
        yield put(fetchSensorPhotoBegin());
        try {
            const file = yield call(sensorPhotoGet, sensor.image);
            yield put(fetchSensorPhotoSuccess());
            yield put(readJPEGBegin());
            const { base64img, value } = yield call(readJPEGAndGetOrientation, file);
            yield put(readJPEGSuccess());
            yield put(loadImgSrcBegin());
            const dataURL = yield call(correctJPEGOrientation, { base64img, value });
            yield put(loadImgSrcSuccess());
            yield put(cacheSensorPhoto(dataURL, sensor.key));
        } catch (err) {
            yield put(fetchSensorPhotoFailure(err));
        }
    }
}

function* fetchSensorEvents(sensorKey) {
    try {
        yield put(fetchSensorEventsBegin());
        // const events = yield call(
        //     { context: eventV1Api, fn: eventV1Api.eventV1GetEvents },
        //     '2018-06-28',
        //     { zoneKey: sensorKey, limit: 5 }
        // );
        // yield put(fetchEventsSuccess(events));
        yield put(fetchSensorEventsSuccess());
    } catch (err) {
        yield put(fetchSensorEventsFailure());
        console.error(err);
    }
}

function* watchLoadSensorPhoto({ sensorKey }) {
    function* photoActions() {
        yield FETCH_SENSOR_PHOTO.BEGIN;
        yield FETCH_SENSOR_PHOTO.SUCCESS;
        yield READ_JPEG.BEGIN;
        yield READ_JPEG.SUCCESS;
        yield LOAD_IMG_SRC.BEGIN;
        yield LOAD_IMG_SRC.SUCCESS;
    }
    let sensor = yield select(getSensorByKey, { sensorKey });
    if (sensor && sensor.image) {
        const cachedSensorPhotoDataURL = yield select(getSensorPhotoCachedDataByKey, { sensorKey });
        if (!cachedSensorPhotoDataURL) {
            const loadSensorPhotoSaga = yield fork(loadSensorPhoto, sensor);
            for (let nextAction of photoActions()) {
                let { photoAction, cancelAction } = yield race({
                    imageAction: take(nextAction),
                    cancelAction: take(actionsThatCloseSensorDetailPanel),
                });
                if (cancelAction) {
                    yield cancel(loadSensorPhotoSaga);
                    break;
                }
            }
        }
    }
}

function* watchFetchSensorEvents({ sensorKey }) {
    function* eventActions() {
        yield FETCH_SENSOR_EVENTS.BEGIN;
        yield FETCH_SENSOR_EVENTS.SUCCESS;
    }
    const fetchSensorEventsSaga = yield fork(fetchSensorEvents, sensorKey);
    for (let nextAction of eventActions()) {
        let { eventAction, cancelAction } = yield race({
            eventAction: take(nextAction),
            cancelAction: take(actionsThatCloseSensorDetailPanel),
        });
        if (cancelAction) {
            yield cancel(fetchSensorEventsSaga);
            break;
        }
    }
}

export function* fetchSensorDetailPanelAssets({ sensorKey }) {
    const cancelActionChannel = yield actionChannel(actionsThatCloseSensorDetailPanel);
    yield all([
        call(watchFetchSensorEvents, { sensorKey }),
        call(watchLoadSensorPhoto, { sensorKey }),
    ]);
    const cancelActions = yield flush(cancelActionChannel);
    if (cancelActions.length) {
        yield put(resetPanel('sensors'));
    }
}

export default function* watchFetchSensorDetailAssets() {
    // set up a buffer for SLIDE_PANEL_END actions until we have the sensor data
    const slidePanelEndChannel = yield actionChannel(SLIDE_PANEL_END);
    yield take(FETCH_SENSORS.SUCCESS);
    const slidePanelEndActions = yield flush(slidePanelEndChannel);
    if (slidePanelEndActions.length) {
        const sensorPanelAction = slidePanelEndActions.find(action => action.table === 'sensors');
        if (sensorPanelAction) {
            yield fork(fetchSensorDetailPanelAssets, { sensorKey: sensorPanelAction.sensorKey });
        }
    }
    // normal saga flow after we have sensor data
    let lastTask;
    while (true) {
        let action = yield take(SLIDE_PANEL_END);
        if (action.table === 'sensors') {
            if (lastTask) yield cancel(lastTask);
            lastTask = yield fork(fetchSensorDetailPanelAssets, { sensorKey: action.sensorKey });
        }
    }
}
