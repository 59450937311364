import { all } from 'redux-saga/effects';
import watchFetchSensorDetailAssets from './sagas.sensorsPanel';
import watchFetchPropertyDetailGraphAssets from './sagas.propertiesPanel';
import routerUpdate from './sagas.router';
import { userPageviewAnalyticsSaga } from './sagas.analytics';
import watchTokenExpiration from './sagas.auth';
import watchOpenCurrentAlertsDetailPanel, {
    currentAlertsPanelAnalyticsSaga,
} from './sagas.currentAlertsPanel';
import { accountAnalyticsSaga } from './sagas.account';
import { dashboardAnalyticsSaga } from './sagas.dashboard';

export default function* rootSaga() {
    yield all([
        watchFetchPropertyDetailGraphAssets(),
        watchFetchSensorDetailAssets(),
        watchOpenCurrentAlertsDetailPanel(),
        watchTokenExpiration(),
        ...(process.env.NODE_ENV === 'production'
            ? [
                  userPageviewAnalyticsSaga(),
                  currentAlertsPanelAnalyticsSaga(),
                  dashboardAnalyticsSaga(),
                  accountAnalyticsSaga(),
              ]
            : []),
        routerUpdate(),
    ]);
}
