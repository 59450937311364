import { combineReducers } from 'redux';
import sensorsTable, { defaultSensorsTableState } from './sensorsTable';
import sensorsPanel, { defaultSensorsPanelState } from './sensorsPanel';

const sensors = combineReducers({
    sensorsTable, sensorsPanel
});

export default sensors;

export const defaultSensorsState = {
    sensorsTable: defaultSensorsTableState,
    sensorsPanel: defaultSensorsPanelState
};