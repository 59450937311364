import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toggleNav } from '../actions';
import Nav from '../components/Nav';
import MobileNav from '../components-mobile/MobileNav';
import { getNav } from '../selectors/getNav';
import { getMobileNavBar } from '../selectors/getMobileNavBar';
import { getUserName, getUserOrgName, getUserRole } from '../selectors';
import withResponsive from './withResponsive';

const mapDispatchToProps = dispatch => {
    return {
        onToggleClick: () => {
            dispatch(toggleNav());
        },
    };
};

const mapStateToProps = state => ({
    ...getNav(state),
    userRole: getUserRole(state),
    mobileNav: getMobileNavBar(state),
    userName: getUserName(state),
    organizationName: getUserOrgName(state),
});

const VisibleNav = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withResponsive({ DesktopComponent: Nav, MobileComponent: MobileNav }))
);

export default VisibleNav;
