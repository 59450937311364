import { FETCH_RESERVATIONS, DELETE_RESERVATION } from './actions.types';

export const fetchReservationsSuccess = json => {
    return {
        type: FETCH_RESERVATIONS.SUCCESS,
        json,
    };
};

export const fetchReservationsFailure = err => {
    return {
        type: FETCH_RESERVATIONS.FAILURE,
        err,
    };
};

export const deleteReservationSuccess = () => {
    return {
        type: DELETE_RESERVATION.SUCCESS,
    };
};

export const deleteReservationFailure = err => {
    return {
        type: DELETE_RESERVATION.FAILURE,
        err,
    };
};
