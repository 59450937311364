import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { setUserToken } from '../actions';

const ImpersonateRoute = (...props) => {
    // Expects URL of type `${match.path}/?${userToken}`
    return (
        <Route {...props}>
            {({ match }) => {
                match != null && props.setUserToken(window.location.search.slice(1));
                return <Redirect to="/" />;
            }}
        </Route>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        setUserToken: userToken => dispatch(setUserToken(userToken)),
    };
};

export default connect(
    null,
    mapDispatchToProps
)(ImpersonateRoute);
