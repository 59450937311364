import modalReducer, { defaultModalState } from './modal';

export const defaultICalLinksState = {
    headers: {
        Property: null,
        'iCal Links': null,
        '': null,
    },
    modalOpen: defaultModalState.modalOpen,
    modalData: defaultModalState.modalData,
};

const iCalLinks = (state, action) => {
    if (state === undefined) {
        state = defaultICalLinksState;
    }
    if (action.table === 'iCalLinks') {
        switch (action.type) {
            default:
                return Object.assign(
                    {},
                    state,
                    modalReducer({ modalOpen: state.modalOpen, modalData: state.modalData }, action)
                );
        }
    } else {
        return state;
    }
};

export default iCalLinks;
