import React from 'react';
import AppleAppStore from '../../img/NoiseAware_AppleAppStore.png';
import GooglePlayStore from '../../img/NoiseAware_GooglePlayStore.png';
import MobileAppDashboard from '../../img/NoiseAware_MobileAppDashboard.png';

const MobilePlaceholder = () => (
    <div className='mobile-page'>
        <div className='mobile-page-placeholder-header'>
        </div>
        <div className='cards'>
            <div className='card'>
                <h2 className='mobile-page-placeholder-emp'>NoiseAware has an app!</h2>
                <p className='mobile-page-placeholder-text'>
                    To access your dashboard from a mobile device, please download our mobile application.
                </p>
                <div className='mobile-app-store-button-container'>
                    <div className='mobile-app-store-button'>
                        <a href='https://itunes.apple.com/us/app/noiseaware/id1436213179?ls=1&mt=8' rel="noopener noreferrer" target='_blank'>
                            <img src={AppleAppStore} className='mobile-app-store-button-img'/>
                        </a>
                    </div>
                    <div className='mobile-app-store-button'>
                        <a href='https://play.google.com/store/apps/details?id=io.noiseaware.mobile&hl=en' rel="noopener noreferrer" target='_blank'>
                            <img src={GooglePlayStore} className='mobile-app-store-button-img'/>
                        </a>
                    </div>
                </div>
                <img src={MobileAppDashboard} className='mobile-page-placeholder-img'/>
            </div>
        </div>
    </div>
);

export default MobilePlaceholder;