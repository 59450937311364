import adminIntegration, { defaultAdminIntegrationState } from './adminIntegration';
import { propertiesFilterReducer, defaultPropertiesFilters, defaultFilterState } from './filter';
import modalReducer, { defaultModalState } from './modal';
import {
    TOGGLE_ADD_USER,
    TOGGLE_EDIT_USER,
    FETCH_PROPERTIES,
    FETCH_ORGANIZATION_USER_PERMISSION_DATA,
    TOGGLE_PERMISSION_SETTING,
    TOGGLE_ALL_PERMISSION_SETTINGS,
} from '../actions';

export const defaultAdminState = {
    Users: {
        headers: {
            Email: null,
            Name: null,
            Phone: null,
            Role: null,
            Permissions: null,
        },
    },
    Payments: {
        headers: {
            Amount: null,
            Description: null,
            Date: {
                options: ['Ascending', 'Descending', 'Other'],
                selected: 'Ascending',
                open: false,
            },
        },
    },
    'Property Permissions': {
        headers: {
            Property: null,
            'Property Tags': null,
            City: null,
            Timezone: null,
            'Seen by user': null,
        },
        cols: {
            Property: { type: 'text' },
            'Property Tags': { type: 'tags' },
            City: { type: 'text' },
            Timezone: { type: 'text' },
            'Seen by user': { type: 'toggle', checked: false },
        },
        properties: [],
    },
    modalOpen: defaultModalState.modalOpen,
    modalData: defaultModalState.modalData,
    filterOpen: defaultFilterState.filterOpen,
    filters: defaultPropertiesFilters,
    'Edit User': {
        isLoading: true,
        hasError: false,
    },
    adminIntegration: defaultAdminIntegrationState,
};

const admin = (state, action) => {
    if (state === undefined) {
        state = defaultAdminState;
    }
    if (action.table === 'admin') {
        return Object.assign(
            {},
            state,
            propertiesFilterReducer(
                { filterOpen: state.filterOpen, filters: state.filters },
                action
            ),
            modalReducer({ modalOpen: state.modalOpen, modalData: state.modalData }, action)
        );
    }
    switch (action.type) {
        case TOGGLE_ADD_USER:
            return Object.assign({}, state, {
                'Property Permissions': {
                    ...state['Property Permissions'],
                    properties: state['Property Permissions'].properties.map(property => {
                        return {
                            ...property,
                            checked: false,
                            rcv_alerts_noise: false,
                            rcv_alerts_disconnect: false,
                        };
                    }),
                },
            });
        case TOGGLE_EDIT_USER:
            return Object.assign({}, state, {
                'Property Permissions': {
                    ...state['Property Permissions'],
                    properties: state['Property Permissions'].properties.map(property => {
                        return {
                            ...property,
                            checked: false,
                            rcv_alerts_noise: false,
                            rcv_alerts_disconnect: false,
                        };
                    }),
                },
            });
        case FETCH_PROPERTIES.SUCCESS:
            return Object.assign({}, state, {
                'Property Permissions': {
                    ...state['Property Permissions'],
                    properties: action.json.map(property => ({
                        ...property,
                        checked: false,
                    })),
                },
            });
        case FETCH_ORGANIZATION_USER_PERMISSION_DATA.SUCCESS:
            return Object.assign({}, state, {
                'Property Permissions': {
                    ...state['Property Permissions'],
                    properties: state['Property Permissions'].properties.reduce((acc, property) => {
                        const userProperty = action.json.find(
                            userProperty => userProperty.key === property.key
                        );
                        if (userProperty) return [...acc, { ...userProperty, checked: true }];
                        else return [...acc, { ...property, checked: false }];
                    }, []),
                },
                'Edit User': { isLoading: false, hasError: false },
            });
        case FETCH_ORGANIZATION_USER_PERMISSION_DATA.FAILURE:
            return Object.assign({}, state, {
                'Edit User': { isLoading: false, hasError: action.err },
            });
        case TOGGLE_PERMISSION_SETTING:
            return Object.assign({}, state, {
                'Property Permissions': {
                    ...state['Property Permissions'],
                    properties: state['Property Permissions'].properties.map(property =>
                        property.key === action.key
                            ? {
                                  ...property,
                                  checked: !property.checked,
                                  rcv_alerts_noise: !property.rcv_alerts_noise,
                                  rcv_alerts_disconnect: !property.rcv_alerts_disconnect,
                              }
                            : property
                    ),
                },
            });
        case TOGGLE_ALL_PERMISSION_SETTINGS:
            return Object.assign({}, state, {
                'Property Permissions': {
                    ...state['Property Permissions'],
                    properties: state['Property Permissions'].properties.map(property => {
                        let matchesCriteria = true;
                        if (state.filters.city && state.filters.city !== property.city)
                            matchesCriteria &= false;
                        if (
                            state.filters.reservationStatus &&
                            state.filters.reservationStatus !== property.reservationStatus
                        )
                            matchesCriteria &= false;
                        if (
                            state.filters.propertyType &&
                            state.filters.propertyType !== property.type
                        )
                            matchesCriteria &= false;
                        state.filters.tags.forEach(tag => {
                            if (!property.tags.includes(tag)) matchesCriteria &= false;
                        });
                        if (matchesCriteria)
                            return {
                                ...property,
                                checked: action.state,
                                rcv_alerts_noise: action.state,
                                rcv_alerts_disconnect: action.state,
                            };
                        else return property;
                    }),
                },
            });
        default:
            return Object.assign({}, state, {
                adminIntegration: adminIntegration(state.adminIntegration, action),
            });
    }
};

export default admin;
