import {
    TOGGLE_SEARCH_FOCUS,
    TOGGLE_SEARCH_BLUR,
    TOGGLE_USER_MENU,
    SEARCH_FOR_PROPERTY,
} from '../actions';
import createAsyncStateReducer from './async';
import { composeReducers } from './utils';

const searchingStateReducer = createAsyncStateReducer(SEARCH_FOR_PROPERTY);

export const defaultHeaderState = {
    searchFocused: false,
    userMenuExpanded: false,
};

const header = (state, action) => {
    if (state === undefined) {
        state = defaultHeaderState;
    }
    switch (action.type) {
        case TOGGLE_SEARCH_FOCUS:
            return Object.assign({}, state, { searchFocused: !state.searchFocused });
        case TOGGLE_SEARCH_BLUR:
            return Object.assign({}, state, { searchFocused: false });
        case TOGGLE_USER_MENU:
            return Object.assign({}, state, { userMenuExpanded: !state.userMenuExpanded });
        default:
            return state;
    }
};

export default composeReducers([header, searchingStateReducer], defaultHeaderState);
