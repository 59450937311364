import {
    FETCH_ORGANIZATION_USERS,
    TOGGLE_EDIT_USER,
    TOGGLE_PERMISSION_SETTING,
    TOGGLE_ALL_PERMISSION_SETTINGS,
    TOGGLE_INTEGRATION_SUCCESS,
    TOGGLE_INTEGRATION_FAILURE,
    FETCH_INTEGRATION_PROPERTIES_SUCCESS,
    FETCH_INTEGRATION_NEW_PROPERTIES,
    SETUP_INTEGRATION,
} from './actions.types';
import { integrationSighV1Api, usersSighV1Api } from '../remote2';
import { push } from 'connected-react-router';

export const fetchOrgUsersSuccess = json => {
    return {
        type: FETCH_ORGANIZATION_USERS.SUCCESS,
        json,
    };
};

export const fetchOrgUsersFailure = err => {
    return {
        type: FETCH_ORGANIZATION_USERS.FAILURE,
        err,
    };
};

export function toggleAddUser() {
    return dispatch => {
        dispatch(push('/admin/add_user'));
    };
}

export const toggleEditUserSuccess = ({ table, popKey }) => {
    return {
        type: TOGGLE_EDIT_USER,
        table,
        popKey,
    };
};

export function toggleEditUser({ table, popKey }) {
    return dispatch => {
        dispatch(push(`/admin/edit_user/${popKey}`));
        dispatch(toggleEditUserSuccess({ table, popKey }));
    };
}

export const togglePermissionSetting = (key, col) => {
    return {
        type: TOGGLE_PERMISSION_SETTING,
        key,
        col,
    };
};

export const toggleAllPermissionSettings = (col, state) => {
    return {
        type: TOGGLE_ALL_PERMISSION_SETTINGS,
        col,
        state,
    };
};

export const toggleIntegrationSuccess = integration => {
    return {
        type: TOGGLE_INTEGRATION_SUCCESS,
        integration,
    };
};

export const toggleIntegrationFailure = (integration, err) => {
    return {
        type: TOGGLE_INTEGRATION_FAILURE,
        integration,
        err,
    };
};

export const fetchIntegrationPropertiesSuccess = (integration, json) => {
    return {
        type: FETCH_INTEGRATION_PROPERTIES_SUCCESS,
        integration,
        json,
    };
};

export const fetchIntegrationNewPropertiesBegin = () => {
    return {
        type: FETCH_INTEGRATION_NEW_PROPERTIES.BEGIN,
    };
};

export const fetchIntegrationNewPropertiesSuccess = (integration, json) => {
    return {
        type: FETCH_INTEGRATION_NEW_PROPERTIES.SUCCESS,
        integration,
        json,
    };
};

export function navigateToIntegration(integration) {
    return dispatch => {
        dispatch(push(`/admin/setup_integration/${integration.name}`));
        dispatch(setupIntegration(integration));
    };
}

export function navigateToUpdateIntegration(integration) {
    return dispatch => {
        dispatch(push(`/admin/update_integration/${integration.name}`));
    };
}

export const setupIntegration = integration => {
    return {
        type: SETUP_INTEGRATION,
        integration,
    };
};

export function deleteUser(userKey) {
    return dispatch => {
        // return api.usersUserKeyDelete(userKey);
        return usersSighV1Api.usersSighV1DeleteUser(userKey);
    };
}

export function updateIntegration(body) {
    return dispatch => {
        // return api.integrationsPut(body);
        return integrationSighV1Api.integrationSighV1UpdateIntegration(body);
    };
}

export function toggleIntegration(integration, body) {
    return dispatch => {
        // return api.integrationsPut(body)
        return integrationSighV1Api
            .integrationSighV1UpdateIntegration(body)
            .then(json => {
                dispatch(toggleIntegrationSuccess(integration));
            })
            .catch(err => toggleIntegrationFailure(integration, err));
    };
}
