import React from 'react';
import { fetchUser } from '../actions';

const hasUserData = WrappedComponent => {
    class ComponentWithData extends React.Component {
        constructor(props) {
            super(props);
        }
        componentDidMount() {
            this.props.dispatch(fetchUser());
        }
        render() {
            return <WrappedComponent {...this.props} />;
        }
    }
    ComponentWithData.displayName = `hasUserData(${WrappedComponent.displayName})`;
    return ComponentWithData;
};

export default hasUserData;
