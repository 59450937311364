import { registerSelectors } from 'reselect-tools';
import * as reservationsTableSelectors from './selectors.reservationsTable';
import * as reservationsDetailSelectors from './selectors.reservationsDetail';
import * as iCalLinksSelectors from './selectors.iCalLinks';
import * as userSelectors from './selectors.user';
import * as dataSelectors from './selectors.data';
import * as accountSelectors from './selectors.account';
import * as adminSelectors from './selectors.admin';
import * as adminIntegrationSelectors from './selectors.adminIntegration';
import * as currentAlertsTableSelectors from './selectors.currentAlertsTable';
import * as currentAlertsPanelSelectors from './selectors.currentAlertsPanel';
import * as resolvedAlertsSelectors from './selectors.resolvedAlerts';
import * as propertiesTableSelectors from './selectors.propertiesTable';
import * as propertiesPanelSelectors from './selectors.propertiesPanel';
import * as sensorsTableSelectors from './selectors.sensorsTable';
import * as sensorsPanelSelectors from './selectors.sensorsPanel';
import * as eventsTableSelectors from './selectors.eventsTable';
import * as defaultPanelSelectors from './selectors.defaultPanel';
import * as addPropertySelectors from './selectors.addProperty';
import * as computedAccountSelectors from './computed.account';

registerSelectors(
    Object.assign(
        {},
        // userSelectors,
        // dataSelectors,
        accountSelectors,
        // adminSelectors,
        // currentAlertsTableSelectors,
        // currentAlertsPanelSelectors,
        // resolvedAlertsSelectors,
        // propertiesTableSelectors,
        // propertiesPanelSelectors,
        // sensorsTableSelectors,
        // sensorsPanelSelectors,
        // eventsTableSelectors,
        // storeSelectors,
        // publicStoreSelectors,
        computedAccountSelectors
    )
);

export * from './selectors.dashboard';
export * from './selectors.header';
export * from './selectors.reservationsTable';
export * from './selectors.reservationsDetail';
export * from './selectors.user';
export * from './selectors.data';
export * from './selectors.account';
export * from './selectors.admin';
export * from './selectors.adminIntegration';
export * from './selectors.currentAlertsTable';
export * from './selectors.currentAlertsPanel';
export * from './selectors.resolvedAlerts';
export * from './selectors.propertiesTable';
export * from './selectors.propertiesPanel';
export * from './selectors.sensorsTable';
export * from './selectors.sensorsPanel';
export * from './selectors.eventsTable';
export * from './selectors.defaultPanel';
export * from './selectors.addProperty';
export * from './selectors.iCalLinks';
