import { POP_ROW_CLICK, POP_ROW_BLUR, FETCH_EVENTS, FETCH_MORE_EVENTS } from '../actions';
import { defaultFilterState, createFilterReducer } from './filter';
import tableHeaderDropdownReducer from './tableHeaderDropdown';
import createAsyncStateReducer from './async';
import { composeReducers } from './utils';

const fetchEventsReducer = createAsyncStateReducer(FETCH_EVENTS, {
    actionFilters: [action => action.table === 'events'],
});

const defaultEventsFiltersState = {
    city: null,
    // eventType: null,
    propertyType: null,
    tags: [],
};

export const defaultEventsTableState = {
    headers: {
        Event: null,
        'Event Type': null,
        Time: { options: ['Local', 'Remote'], selected: 'Local', open: false },
        Property: null,
        'Property Tags': null,
    },
    rows: [],
    isLoading: true,
    isLoadingMore: false,
    hasError: null,
    expandedRow: { popKey: 'u' },
    filterOpen: defaultFilterState.filterOpen,
    filters: defaultEventsFiltersState,
    offset: 0,
};

const eventsTable = (state, action) => {
    if (state === undefined) {
        state = defaultEventsTableState;
    }
    if (action.table === 'events') {
        switch (action.type) {
            case POP_ROW_CLICK:
                return Object.assign({}, state, {
                    expandedRow: { popKey: action.popKey },
                });
            case POP_ROW_BLUR:
                return Object.assign({}, state, { expandedRow: { popKey: 'u' } });
            case FETCH_EVENTS.SUCCESS:
                return Object.assign({}, state, { offset: (action.json || []).length });
            case FETCH_MORE_EVENTS.BEGIN:
                return Object.assign({}, state, { isLoadingMore: true });
            case FETCH_MORE_EVENTS.SUCCESS:
                return Object.assign({}, state, {
                    isLoadingMore: false,
                    hasError: null,
                    offset: (action.json || []).length + state.offset,
                });
            default:
                return Object.assign(
                    {},
                    state,
                    createFilterReducer(defaultEventsFiltersState)(
                        { filterOpen: state.filterOpen, filters: state.filters },
                        action
                    ),
                    tableHeaderDropdownReducer({ headers: state.headers }, action)
                );
        }
    } else {
        return state;
    }
};

export default composeReducers([eventsTable, fetchEventsReducer], defaultEventsTableState);
