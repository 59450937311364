import {
    ApiClient as PortalClient,
    DeviceApi,
    EventsApi,
    MacApi,
    PropertiesApi,
    AZSighV1Api,
    EventV1Api,
    IntegrationSighV1Api,
    MiscSighV1Api,
    OrganizationSighV1Api,
    PropertiesSighV1Api,
    ReservationsSighV1Api,
    UsersSighV1Api,
    UserNotificationSettingsApi,
    AuthenticationApi,
    UserApi,
} from '@noiseaware/portal-client';
import { apiList } from './constants';

export function sensorPhotoGet(url) {
    let p = new Promise((resolve, reject) => {
        let xhr = new XMLHttpRequest();
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.setRequestHeader('Cache-Control', 'no-cache');
        xhr.addEventListener('load', () => {
            if (xhr.status === 200) {
                resolve(xhr.response);
            } else {
                reject(xhr.statusText);
            }
        });
        // eslint-disable-next-line no-unused-vars
        xhr.addEventListener('error', event => {
            reject('Error fetching image');
        });
        xhr.send();
    });
    return p;
}

PortalClient.prototype.setBasePath = function(basePath = apiList.portal) {
    this.basePath = basePath;
};
PortalClient.prototype.setApiKey = function(apiKey) {
    this.authentications['jwt'].apiKey = apiKey;
};
PortalClient.prototype.clearApiKey = function() {
    this.authentications['jwt'].apiKey = undefined;
};

const defaultPortalClient = PortalClient.instance;
defaultPortalClient.setBasePath(apiList.portal);

export { defaultPortalClient };

export const macApi = new MacApi(defaultPortalClient);
export const deviceApi = new DeviceApi(defaultPortalClient);
export const propertiesApi = new PropertiesApi(defaultPortalClient);
export const eventsApi = new EventsApi(defaultPortalClient);
export const userApi = new UserApi(defaultPortalClient);
export const userNotificationSettingsApi = new UserNotificationSettingsApi(defaultPortalClient);

export const azSighV1Api = new AZSighV1Api(defaultPortalClient);
export const eventV1Api = new EventV1Api(defaultPortalClient);
export const integrationSighV1Api = new IntegrationSighV1Api(defaultPortalClient);
export const miscSighV1Api = new MiscSighV1Api(defaultPortalClient);
export const organizationSighV1Api = new OrganizationSighV1Api(defaultPortalClient);
export const propertiesSighV1Api = new PropertiesSighV1Api(defaultPortalClient);
export const reservationSighV1Api = new ReservationsSighV1Api(defaultPortalClient);
export const usersSighV1Api = new UsersSighV1Api(defaultPortalClient);

export const authenticationApi = new AuthenticationApi(defaultPortalClient);
