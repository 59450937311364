import { usersSighV1Api, userNotificationSettingsApi, authenticationApi } from '../remote2';
import {
    TOGGLE_NOTIFICATION_SUBSCRIPTION,
    TOGGLE_NOTIFICATIONS_SUBSCRIPTIONS,
    TOGGLE_GLOBAL_ALERTS,
    FETCH_PROPERTY_PERMISSIONS,
    TOGGLE_ALERT_PREFERENCE,
    FETCH_ACCOUNT_PREFERENCES,
    FETCH_PROPERTIES_NOTIFICATION_SETTINGS,
    FETCH_ALL_ACCOUNT_NOTIFICATION_SETTINGS,
    REMOVE_NOTIFICATION_EMAIL,
    REMOVE_NOTIFICATION_PHONE,
} from './actions.types';
import { openModal } from './actions.modal';

export const toggleNotificationSetting = (key, alertType, toggleStatus) => {
    let nativeAlertType;
    if (alertType === 'Noise Alerts') nativeAlertType = 'notificationNoise';
    else if (alertType === 'Disconnect Alerts') nativeAlertType = 'notificationDisconnect';
    return {
        type: TOGGLE_NOTIFICATION_SUBSCRIPTION,
        key,
        alertType: nativeAlertType,
        status: toggleStatus,
    };
};

export const toggleNotificationsSettings = (col, properties, nextState) => {
    return {
        type: TOGGLE_NOTIFICATIONS_SUBSCRIPTIONS,
        col,
        properties,
        nextState,
    };
};

export const toggleAlertPreference = alertType => {
    return {
        type: TOGGLE_ALERT_PREFERENCE,
        alertType,
    };
};

export const toggleGlobalAlerts = () => {
    return {
        type: TOGGLE_GLOBAL_ALERTS,
    };
};

export const fetchPropertyPermissionsSuccess = json => {
    return {
        type: FETCH_PROPERTY_PERMISSIONS.SUCCESS,
        json,
    };
};

export const fetchPropertyPermissionsFailure = err => {
    return {
        type: FETCH_PROPERTY_PERMISSIONS.FAILURE,
    };
};

export function sendResetPasswordEmail(email) {
    return dispatch => {
        return authenticationApi
            .authenticationForgotPasswordWithHttpInfo(email, { override: false })
            .then(() => dispatch(openModal('account', email)))
            .catch(() => dispatch(openModal('account', 'error')));
    };
}

export const removeNotificationEmail = email => {
    return {
        type: REMOVE_NOTIFICATION_EMAIL,
        email,
    };
};

export function deleteAccountPreferenceEmail({ userId, email }) {
    return dispatch => {
        return userNotificationSettingsApi.userNotificationSettingsDeleteUserEmailWithHttpInfo(
            email,
            userId
        );
    };
}

export const removeNotificationPhone = phone => {
    return {
        type: REMOVE_NOTIFICATION_PHONE,
        phone,
    };
};

export function deleteAccountPreferencePhone({ userId, phone }) {
    return dispatch => {
        return userNotificationSettingsApi.userNotificationSettingsDeleteUserPhoneWithHttpInfo(
            userId,
            phone
        );
    };
}

export function updateAccountPreferences(userId, preferences) {
    return dispatch => {
        return userNotificationSettingsApi.userNotificationSettingsUpdateNotificationsSettings(
            userId,
            preferences
        );
    };
}

export function updatePropertyNotificationSettings(userId, propertyNotificationSettings) {
    return dispatch => {
        return userNotificationSettingsApi.userNotificationSettingsUpdatePropertyNotificationsSettings(
            userId,
            {
                propertyID: propertyNotificationSettings.propertyID,
                notificationDisconnect: propertyNotificationSettings.notificationDisconnect,
                notificationNoise: propertyNotificationSettings.notificationNoise,
                organizationID: propertyNotificationSettings.organizationID,
            }
        );
    };
}

export const fetchAccountPreferencesBegin = () => {
    return {
        type: FETCH_ACCOUNT_PREFERENCES.BEGIN,
    };
};

export const fetchAccountPreferencesSuccess = json => {
    return {
        type: FETCH_ACCOUNT_PREFERENCES.SUCCESS,
        json,
    };
};

export const fetchAccountPreferencesFailure = err => {
    return {
        type: FETCH_ACCOUNT_PREFERENCES.FAILURE,
        err,
    };
};

export function fetchAccountPreferences(userId) {
    return dispatch => {
        dispatch(fetchAccountPreferencesBegin());
        userNotificationSettingsApi
            .userNotificationSettingsGetNotificationsSettings(userId)
            .then(json => {
                dispatch(fetchAccountPreferencesSuccess(json));
            })
            .catch(err => dispatch(fetchAccountPreferencesFailure(err)));
    };
}

export const fetchPropertiesNotificationSettingsBegin = () => {
    return {
        type: FETCH_PROPERTIES_NOTIFICATION_SETTINGS.BEGIN,
    };
};

export const fetchPropertiesNotificationSettingsSuccess = json => {
    return {
        type: FETCH_PROPERTIES_NOTIFICATION_SETTINGS.SUCCESS,
        json,
    };
};

export const fetchPropertiesNotificationSettingsFailure = err => {
    return {
        type: FETCH_PROPERTIES_NOTIFICATION_SETTINGS.FAILURE,
        err,
    };
};

export function fetchPropertiesNotificationSettings(userId) {
    return dispatch => {
        dispatch(fetchPropertiesNotificationSettingsBegin());
        userNotificationSettingsApi
            .userNotificationSettingsGetPropertyNotificationsSettings(userId)
            .then(json => {
                dispatch(fetchPropertiesNotificationSettingsSuccess(json));
            })
            .catch(err => dispatch(fetchPropertiesNotificationSettingsFailure(err)));
    };
}

export const fetchAllAccountNotificationSettingsBegin = () => {
    return {
        type: FETCH_ALL_ACCOUNT_NOTIFICATION_SETTINGS.BEGIN,
    };
};

export const fetchAllAccountNotificationSettingsSuccess = json => {
    return {
        type: FETCH_ALL_ACCOUNT_NOTIFICATION_SETTINGS.SUCCESS,
        json,
    };
};

export const fetchAllAccountNotificationSettingsFailure = err => {
    return {
        type: FETCH_ALL_ACCOUNT_NOTIFICATION_SETTINGS.FAILURE,
        err,
    };
};

export function fetchAllAccountNotificationSettings(userId) {
    return dispatch => {
        dispatch(fetchAllAccountNotificationSettingsBegin());
        userNotificationSettingsApi
            .userNotificationSettingsGetNotificationsAll(userId)
            .then(json => {
                dispatch(fetchAllAccountNotificationSettingsSuccess(json));
            })
            .catch(err => dispatch(fetchAllAccountNotificationSettingsFailure(err)));
    };
}
