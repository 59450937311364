import { middleware as protectedMiddleware } from 'redux-jwt-protected-middleware';
import { setRefreshCookies, getRefreshToken } from './auth';
import { authenticationApi, defaultPortalClient } from './remote2';
import { getUserTokenFromStore } from './selectors';
import { setUserToken, logout } from './actions';

const config = {
    currentAccessToken: store => getUserTokenFromStore(store.getState()),
    currentRefreshToken: () => getRefreshToken(),
    handleRefreshAccessToken: (refreshToken, store) => {
        return new Promise((resolve, reject) => {
            return authenticationApi
                .authenticationRefreshWithHttpInfo({ refreshToken })
                .then(({ data, response }) => {
                    return data.data;
                })
                .then(data => {
                    setRefreshCookies(data);
                    defaultPortalClient.setApiKey(data.userToken);
                    store.dispatch(setUserToken(data.userToken));
                    resolve(data.userToken);
                })
                .catch(err => {
                    reject(err);
                    store.dispatch(logout());
                });
        });
    },
    handleAuthenticationError: (error, store) => {
        console.log('there was an error handling the authentication middleware');
    },
};

export default protectedMiddleware(config);
