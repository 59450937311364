import { take, call, all } from 'redux-saga/effects';
import { googleAnalyticsProperties } from '../constants';
import { FETCH_USER } from '../actions';

/**
 * @description Utility to create an analytics saga
 * @param {Object} handlers Keys should be action types,
 *                          values should be generator function which takes the action object and yields a saga
 * @param {...Function} filters functions to filter actions passed in handlers
 * @returns a generator (saga) configured for taking an action specified in `handlers`
 */
export function createAnalyticsSaga(handlers, ...filters) {
    const analyticsActions = Object.keys(handlers);
    return function* analyticsSaga() {
        while (true) {
            let action = yield take(analyticsActions);
            if (filters && !filters.every(filter => filter(action))) {
                continue;
            }
            yield* handlers[action.type](action);
        }
    };
    // Example usage:
    // const someSaga = createAnalyticsSaga({
    //   [ACTION_EXAMPLE]: function*(action){
    //      let someState = yield select(someSelector);
    //      let payload = {eventName: 'event_ex', eventLabel: action.table, state: someState}}
    //      yield* sendEventToGoogleAnalytics(payload)
    //   }
    //})
}

export function* sendEventToGoogleAnalytics(payload) {
    const { eventName, eventLabel, ...eventPayload } = payload;
    yield call(window.gtag, 'event', eventName, {
        send_to: googleAnalyticsProperties['noiseaware.io (new site)'],
        event_label: eventLabel,
        ...eventPayload,
    });
}

export function* sendUpdatedPathToGoogleAnalytics() {
    while (true) {
        let updatedPageAction = yield take('@@router/LOCATION_CHANGE');
        yield call(window.gtag, 'config', googleAnalyticsProperties['Dashboard'], {
            page_path: updatedPageAction.payload.location.pathname,
        });
    }
}

export function* configUserID() {
    let userAction = yield take(FETCH_USER.SUCCESS);
    yield call(window.gtag, 'config', googleAnalyticsProperties['Dashboard'], {
        user_id: userAction.json.key,
    });
}

export function* userPageviewAnalyticsSaga() {
    yield call(window.gtag, 'config', googleAnalyticsProperties['Dashboard']);
    yield* configUserID();
    yield* sendUpdatedPathToGoogleAnalytics();
}
