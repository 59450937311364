import { SELECT_HEADER_OPTION, TOGGLE_HEADER_DROPDOWN, TOGGLE_HEADER_BLUR } from '../actions';
import { createSelector } from 'reselect';
const defaultTableHeaderDropdownState = {
    headers: {},
};

const tableHeaderDropdownReducer = (state, action) => {
    if (state === undefined) {
        state = defaultTableHeaderDropdownState;
    }
    switch (action.type) {
        case SELECT_HEADER_OPTION:
            return Object.assign({}, state, {
                headers: {
                    ...state.headers,
                    [action.header]: {
                        ...state.headers[action.header],
                        selected: action.option.text,
                    },
                },
            });
        case TOGGLE_HEADER_DROPDOWN:
            return Object.assign({}, state, {
                headers: {
                    ...state.headers,
                    [action.header]: {
                        ...state.headers[action.header],
                        open: !state.headers[action.header].open,
                    },
                },
            });
        case TOGGLE_HEADER_BLUR:
            return Object.assign({}, state, {
                headers: {
                    ...state.headers,
                    [action.header]: { ...state.headers[action.header], open: false },
                },
            });
        default:
            return state;
    }
};

const tableHeadersDropdownSelector = (state, props) => state.headers;

const createTableHeadersDropdownSelector = headersSelector =>
    createSelector(
        headersSelector,
        headers => {
            let keys = Object.keys(headers);
            let headerData = keys.map((key, i) => {
                if (headers[key] !== null) {
                    return {
                        id: i,
                        text: key,
                        dropDown: {
                            options: headers[key].options.map((opt, oi) => ({
                                id: oi,
                                text: opt,
                                selected: headers[key].selected === opt ? true : false,
                            })),
                            open: headers[key].open,
                        },
                    };
                } else {
                    return { id: i, text: key };
                }
            });
            return headerData;
        }
    );

export {
    defaultTableHeaderDropdownState,
    tableHeadersDropdownSelector,
    createTableHeadersDropdownSelector,
};
export default tableHeaderDropdownReducer;
