import { POP_ROW_CLICK, UPDATE_FILTERS, TOGGLE_SEARCH_FOCUS } from '../actions';
import { createAnalyticsSaga, sendEventToGoogleAnalytics } from './sagas.analytics';

export const dashboardAnalyticsSaga = createAnalyticsSaga({
    [POP_ROW_CLICK]: function*(action) {
        if (action.table === 'dashboard')
            yield* sendEventToGoogleAnalytics({
                eventName: 'view_alert_detail',
                eventLabel: action.table,
            });
    },
    [UPDATE_FILTERS]: function*(action) {
        if (action.table === 'dashboard')
            yield* sendEventToGoogleAnalytics({
                eventName: 'filter_alerts',
                eventLabel: action.table,
            });
    },
    [TOGGLE_SEARCH_FOCUS]: function*(action) {
        yield* sendEventToGoogleAnalytics({ eventName: 'search_focus', eventLabel: 'dashboard' });
    },
});
