import { createSelector } from 'reselect';
import { getFilters, getFilterOpen, propertiesAppliedFiltersFormatter } from '../reducers/filter';
import { withRoot } from '../constants';

const mountSelector = state => state.ui.dashboard;

export const getDashboardFilters = withRoot(mountSelector, getFilters);

export const getDashboardFiltersVisibility = withRoot(mountSelector, getFilterOpen);

export const getDashboardFiltersApplied = createSelector(
    getDashboardFilters,
    propertiesAppliedFiltersFormatter
);
