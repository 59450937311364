import reservationsTable, { defaultReservationsTableState } from './reservationsTable';
import reservationsDetail, { defaultReservationsDetailState } from './reservationsDetail';
import { combineReducers } from 'redux';
import iCalLinks, { defaultICalLinksState } from './iCalLinks';

const reservations = combineReducers({
    reservationsTable,
    reservationsDetail,
    iCalLinks,
});

export const defaultReservationsState = {
    reservationsTable: defaultReservationsTableState,
    reservationsDetail: defaultReservationsDetailState,
    iCalLinks: defaultICalLinksState,
};

export default reservations;
