import {
    TOGGLE_ADD_NOTE,
    PAN_GRAPH,
    TOGGLE_DATA_AUTO_REFRESH,
    REFRESH_PROPERTY_DETAIL_SUCCESS,
    TOGGLE_DATA_INTERVAL_CLICK,
    TOGGLE_DATA_INTERVAL_SELECT,
    TOGGLE_DATA_INTERVAL_BLUR,
    TOGGLE_SENSOR_GRAPH_VISIBILITY,
    TOGGLE_EDIT_RESERVATION,
    CLOSE_EDIT_RESERVATION,
    FETCH_UPDATED_RESERVATION,
    SENSOR_DETAIL_ICON_CLICK,
    FETCH_MESSAGES,
    OPEN_CONFIRM_DELETE_MODAL,
    CLOSE_CONFIRM_DELETE_MODAL,
    FETCH_PROPERTY_ICAL_LINKS,
} from './actions.types';
import { propertiesSighV1Api } from '../remote2';
import { fetchProperties } from './actions.data';

export const toggleAddNote = table => {
    return {
        table,
        type: TOGGLE_ADD_NOTE,
    };
};

export const panGraph = (table, start, end) => {
    return {
        table,
        type: PAN_GRAPH,
        start,
        end,
    };
};

export const toggleGraphAutoRefresh = table => {
    return {
        table,
        type: TOGGLE_DATA_AUTO_REFRESH,
    };
};

export const refreshPropertyDetailSuccess = (table, json) => {
    return {
        type: REFRESH_PROPERTY_DETAIL_SUCCESS,
        table,
        json,
    };
};

export const toggleGraphIntervalClick = table => {
    return {
        table,
        type: TOGGLE_DATA_INTERVAL_CLICK,
    };
};

export const toggleGraphIntervalSelect = (table, start, end, option) => {
    return {
        table,
        type: TOGGLE_DATA_INTERVAL_SELECT,
        start,
        end,
        option,
    };
};

export const toggleGraphIntervalBlur = table => {
    return {
        table,
        type: TOGGLE_DATA_INTERVAL_BLUR,
    };
};

export const toggleSensorGraphVisibility = (table, index) => {
    return {
        table,
        type: TOGGLE_SENSOR_GRAPH_VISIBILITY,
        index,
    };
};

export const toggleEditReservation = table => {
    return {
        table,
        type: TOGGLE_EDIT_RESERVATION,
    };
};

export const closeEditReservation = table => {
    return {
        table,
        type: CLOSE_EDIT_RESERVATION,
    };
};

export const fetchUpdatedReservationBegin = table => {
    return {
        type: FETCH_UPDATED_RESERVATION.BEGIN,
        table,
    };
};

export const fetchUpdatedReservationSuccess = table => {
    return {
        type: FETCH_UPDATED_RESERVATION.SUCCESS,
        table,
    };
};

export const fetchUpdatedReservationFailure = (err, table) => {
    return {
        type: FETCH_UPDATED_RESERVATION.FAILURE,
        table,
        err,
    };
};

export const sensorDetailIconClick = sensorKey => {
    return {
        type: SENSOR_DETAIL_ICON_CLICK,
        sensorKey,
    };
};

export const fetchMessagesBegin = table => {
    return {
        table,
        type: FETCH_MESSAGES.BEGIN,
    };
};

export const fetchMessagesSuccess = (json, table) => {
    return {
        table,
        type: FETCH_MESSAGES.SUCCESS,
        json,
    };
};

export const fetchMessagesFailure = (err, table) => {
    return {
        table,
        type: FETCH_MESSAGES.FAILURE,
        err,
    };
};

export const openDeleteModal = (table, detailsPage, modalData) => {
    return {
        table,
        detailsPage,
        type: OPEN_CONFIRM_DELETE_MODAL,
        modalData,
    };
};

export const closeDeleteModal = (table, detailsPage) => {
    return {
        table,
        detailsPage,
        type: CLOSE_CONFIRM_DELETE_MODAL,
    };
};

export const fetchPropertyICalLinksBegin = table => {
    return {
        type: FETCH_PROPERTY_ICAL_LINKS.BEGIN,
        table,
    };
};

export const fetchPropertyICalLinksSuccess = (json, table) => {
    return {
        type: FETCH_PROPERTY_ICAL_LINKS.SUCCESS,
        table,
        json,
    };
};

export const fetchPropertyICalLinksFailure = (err, table) => {
    return {
        type: FETCH_PROPERTY_ICAL_LINKS.FAILURE,
        table,
        err,
    };
};
