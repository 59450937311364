import { take, put, call, all, fork, cancel } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
    POP_ROW_CLICK,
    POP_ROW_BLUR,
    HIDE_PANEL_CLICK,
    SENSOR_DETAIL_ICON_CLICK,
    PROPERTY_DETAIL_ICON_CLICK,
    SELECT_PROPERTY_FROM_SEARCH,
} from '../actions';

const actionsThatUpdateRouter = [
    POP_ROW_CLICK,
    POP_ROW_BLUR,
    HIDE_PANEL_CLICK,
    SENSOR_DETAIL_ICON_CLICK,
    PROPERTY_DETAIL_ICON_CLICK,
    SELECT_PROPERTY_FROM_SEARCH,
];

export default function* routerUpdate() {
    let routerUpdateTask;
    while (true) {
        let routerUpdateAction = yield take(actionsThatUpdateRouter);
        if (routerUpdateTask) yield cancel(routerUpdateTask);
        switch (routerUpdateAction.type) {
            case POP_ROW_CLICK:
                if (routerUpdateAction.table === 'properties') {
                    routerUpdateTask = yield call(
                        routerUpdateSaga,
                        `/properties/${routerUpdateAction.popKey}`
                    );
                } else if (routerUpdateAction.table === 'sensors') {
                    routerUpdateTask = yield call(
                        routerUpdateSaga,
                        `/sensors/${routerUpdateAction.popKey}`
                    );
                } else if (routerUpdateAction.table === 'reservations') {
                    routerUpdateTask = yield call(
                        routerUpdateSaga,
                        `/reservations/reservation-${routerUpdateAction.popKey}`
                    );
                }
                break;
            case HIDE_PANEL_CLICK:
            case POP_ROW_BLUR:
                if (routerUpdateAction.table === 'properties') {
                    routerUpdateTask = yield call(routerUpdateSaga, '/properties');
                } else if (routerUpdateAction.table === 'sensors') {
                    routerUpdateTask = yield call(routerUpdateSaga, '/sensors');
                }
                break;
            case SENSOR_DETAIL_ICON_CLICK:
                routerUpdateTask = yield call(
                    routerUpdateSaga,
                    `/sensors/${routerUpdateAction.sensorKey}`
                );
                break;
            case PROPERTY_DETAIL_ICON_CLICK:
                routerUpdateTask = yield call(
                    routerUpdateSaga,
                    `/properties/${routerUpdateAction.propertyKey}`
                );
                break;
            case SELECT_PROPERTY_FROM_SEARCH:
                routerUpdateTask = yield call(
                    routerUpdateSaga,
                    `/properties/${routerUpdateAction.propertyKey}`
                );
        }
    }
}

function* routerUpdateSaga(route) {
    yield put(push(route));
}
