import { combineReducers } from 'redux';
import header, { defaultHeaderState } from './header';
import nav, { defaultNavState } from './nav';
import dashboard, { defaultDashboardState } from './dashboard';
import properties, { defaultPropertiesState } from './properties';
import sensors, { defaultSensorsState } from './sensors';
import account, { defaultAccountState } from './account';
import admin, { defaultAdminState } from './admin';
import addProperty, { defaultAddPropertyState } from './addProperty';
import mobile, { defaultMobileState } from './mobile';
import events, { defaultEventsState } from './events';
import reservations, { defaultReservationsState } from './reservations';

const ui = combineReducers({
    header,
    nav,
    dashboard,
    properties,
    sensors,
    events,
    account,
    admin,
    addProperty,
    reservations,
    mobile,
});

export default ui;

export const defaultUIState = {
    header: defaultHeaderState,
    nav: defaultNavState,
    dashboard: defaultDashboardState,
    properties: defaultPropertiesState,
    sensors: defaultSensorsState,
    events: defaultEventsState,
    account: defaultAccountState,
    admin: defaultAdminState,
    addProperty: defaultAddPropertyState,
    reservations: defaultReservationsState,
    mobile: defaultMobileState,
};
