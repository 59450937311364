import React, { Fragment } from 'react';

export default class ZenDesk extends React.Component {
    constructor(props) {
        super(props);
        this.insertScript = this.insertScript.bind(this);
    }
    componentDidMount() {
        if (typeof window !== 'undefined' && !document.getElementById('ze-snippet')) {
            this.insertScript();
        }
    }
    insertScript() {
        const script = document.createElement('script');
        script.src =
            'https://static.zdassets.com/ekr/snippet.js?key=2e0250a7-d049-47fc-aed0-2936eb7f233e';
        script.id = 'ze-snippet';
        document.body.appendChild(script);
    }
    render() {
        return <Fragment />;
    }
}
