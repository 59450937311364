import { asyncActionCreator } from './actions.async';
import { FETCH_SENSORS } from './actions.types';
import { azSighV1Api } from '../remote2';
import { logoutIfUnauthorized } from './actions.user';

export const fetchSensors = asyncActionCreator({
    type: FETCH_SENSORS,
    createThunk: () => dispatch => {
        return azSighV1Api
            .aZSighV1GetAZsListForPermittedUserWithHttpInfo({ offset: 0, limit: -1, search: '' })
            .then(({ data, response }) => {
                dispatch(logoutIfUnauthorized(response));
                return data;
            });
    },
});
