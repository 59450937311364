import React from 'react';
import { Route } from 'react-router-dom';

const RedirectExternal = ({ to, from, ...props }) => (
    <Route
        path={from}
        {...props}
        render={() => {
            window.location.href = to;
            return '';
        }}
    />
);

export default RedirectExternal;
