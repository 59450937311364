import React from 'react';
import { Link } from 'react-router-dom';
import SearchSelect from './SearchSelect';
import UserIcon from '../../img/topBar/NoiseAware_Icon_User.svg';
import onClickOutside from 'react-onclickoutside';

const Header = ({
    user,
    userName,
    organizationName,
    userMenuExpanded,
    onSearchProperties,
    onToggleUserMenu,
    onLogout,
}) => (
    <header>
        <div className="header-main">
            <div className="header-search-bar">
                <SearchSelect onSearch={onSearchProperties} />
            </div>
            <div className="header-user">
                <HeaderUserInfoWithBlur
                    disableOnClickOutside={!userMenuExpanded}
                    handleClickOutside={onToggleUserMenu}
                    {...{
                        userName,
                        organizationName,
                        onToggleUserMenu,
                        user,
                        onLogout,
                        userMenuExpanded,
                    }}
                />
            </div>
        </div>
    </header>
);

export default Header;

const HeaderUserInfo = ({
    onToggleUserMenu,
    userName,
    organizationName,
    user,
    onLogout,
    userMenuExpanded,
}) => (
    <div>
        <div onClick={onToggleUserMenu}>
            <div className="header-user-info">
                <span className="header-text" id="user-name">
                    {userName}
                </span>
                <br />
                <span className="header-text" id="org-name">
                    {organizationName}
                </span>
            </div>
            <UserIcon className="header-user-icon" />
        </div>
        <UserMenu user={user} onLogout={onLogout} expanded={userMenuExpanded} />
    </div>
);

const HeaderUserInfoWithBlur = onClickOutside(HeaderUserInfo, {
    handleOutsideClick: inst => inst.props.handleClickOutside,
});

const UserMenu = ({ user, expanded, onLogout }) => {
    return (
        <div>
            <ul className={expanded ? 'header-user-menu' : 'hidden'}>
                <li>
                    <Link className="header-link" to="/account">
                        Account Settings
                    </Link>
                </li>
                {!user.isLoading && (user.role === 'Administrator' || user.role === 'God') && (
                    <li>
                        <Link className="header-link" to="/admin">
                            Admin Tools
                        </Link>
                    </li>
                )}
                <li className="header-link" onClick={onLogout}>
                    Logout <i className="fas fa-sign-out-alt"></i>
                </li>
            </ul>
        </div>
    );
};
