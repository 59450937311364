import {
    POP_ROW_CLICK,
    POP_ROW_BLUR,
    FETCH_PROPERTIES,
    FETCH_PROPERTY_DETAIL,
} from './actions.types';

export const popRowClick = ({ table, popKey }) => {
    return {
        type: POP_ROW_CLICK,
        table,
        popKey,
    };
};

export const popRowBlur = table => {
    return {
        type: POP_ROW_BLUR,
        table,
    };
};

export const fetchPropertiesBegin = () => {
    return {
        type: FETCH_PROPERTIES.BEGIN,
    };
};

export const fetchPropertyDetailBegin = table => {
    return {
        type: FETCH_PROPERTY_DETAIL.BEGIN,
        table,
    };
};

export const fetchPropertyDetailSuccess = (table, json) => {
    return {
        type: FETCH_PROPERTY_DETAIL.SUCCESS,
        table,
        json,
    };
};

export const fetchPropertyDetailFailure = (err, table) => {
    return {
        type: FETCH_PROPERTY_DETAIL.FAILURE,
        table,
        err,
    };
};
