import { FETCH_ADMIN_DATA_SUCCESS } from './actions.types';
import { fetchAlerts } from './actions.data.eventV1Api';
import { fetchProperties } from './actions.data.propertiesSighV1Api';
import { fetchSensors } from './actions.data.azSighV1Api';
import { fetchUser } from './actions.user';

export function fetchAppData() {
    return dispatch => {
        return Promise.all([
            dispatch(fetchAlerts()),
            dispatch(fetchProperties()),
            dispatch(fetchSensors()),
            dispatch(fetchUser()),
        ]);
    };
}

export const fetchAdminDataSuccess = () => {
    return {
        type: FETCH_ADMIN_DATA_SUCCESS,
    };
};

export * from './actions.data.propertiesSighV1Api';
export * from './actions.data.usersSighV1Api';
export * from './actions.data.azSighV1Api';
export * from './actions.data.reservationSighV1Api';
export * from './actions.data.organizationSighV1Api';
export * from './actions.data.integrationSighV1Api';
export * from './actions.data.miscSighV1Api';
export * from './actions.data.eventV1Api';
export * from './actions.data.authentication';

//start MAC_FEAT
export * from './actions.data.macApi';
// end MAC_FEAT
