import { put, delay } from 'redux-saga/effects';
import { logout } from '../actions';
import { refreshTokenExpired } from '../auth';

export default function* watchTokenExpiration() {
    while (true) {
        if (refreshTokenExpired()) {
            yield put(logout());
        }
        yield delay(1000);
    }
}
