import { propertiesFilterReducer, defaultPropertiesFilters, defaultFilterState } from './filter';
import modalReducer, { defaultModalState } from './modal';
import {
    FETCH_PROPERTIES_NOTIFICATION_SETTINGS,
    FETCH_PROPERTIES,
    FETCH_ACCOUNT_PREFERENCES,
    FETCH_ALL_ACCOUNT_NOTIFICATION_SETTINGS,
    TOGGLE_ALERT_PREFERENCE,
    TOGGLE_GLOBAL_ALERTS,
    TOGGLE_NOTIFICATION_SUBSCRIPTION,
    TOGGLE_NOTIFICATIONS_SUBSCRIPTIONS,
    OPEN_ACCOUNT_MODAL,
    CLOSE_ACCOUNT_MODAL,
    REMOVE_NOTIFICATION_EMAIL,
    REMOVE_NOTIFICATION_PHONE,
} from '../actions';

export const defaultAccountState = {
    headers: {
        Property: null,
        'Property Tags': null,
        City: null,
        Timezone: null,
        'Noise Alerts': null,
        'Disconnect Alerts': null,
    },
    cols: {
        Property: { type: 'text' },
        'Property Tags': { type: 'tags' },
        City: { type: 'text' },
        Timezone: { type: 'text' },
        'Noise Alerts': { type: 'toggle', checked: false, disabled: false },
        'Disconnect Alerts': { type: 'toggle', checked: false, disabled: false },
    },
    modalOpen: defaultModalState.modalOpen,
    modalData: defaultModalState.modalData,
    filterOpen: defaultFilterState.filterOpen,
    filters: defaultPropertiesFilters,
    hasPropertiesData: false,
    hasPropertiesNotificationSettings: false,
    savedPropertiesNotificationSettings: {},
    updatedPropertiesNotificationSettings: {},
    propertiesNotificationSettingsAreLoading: true,
    preferences: {
        globalReceive: false,
        globalReceiveEmail: false,
        globalReceiveSMS: false,
        globalReceivePush: false,
        reminder: false,
        addDiscoNotifications: false,
        addNoiseNotifications: false,
    },
    preferencesIsLoading: true,
    preferencesHasError: null,
    notificationEmails: [],
    notificationPhones: [],
    deletedEmails: [],
    deletedPhones: [],
    isLoading: true,
    hasError: false,
};

const account = (state, action) => {
    if (state === undefined) {
        state = defaultAccountState;
    }
    if (action.table === 'account') {
        return Object.assign(
            {},
            state,
            propertiesFilterReducer(
                { filterOpen: state.filterOpen, filters: state.filters },
                action
            ),
            modalReducer({ modalOpen: state.modalOpen, modalData: state.modalData }, action)
        );
    }
    switch (action.type) {
        case FETCH_ALL_ACCOUNT_NOTIFICATION_SETTINGS.BEGIN:
            return Object.assign({}, state, {
                isLoading: true,
                hasError: false,
                preferencesIsLoading: true,
                preferencesHasError: false,
            });
        case FETCH_ALL_ACCOUNT_NOTIFICATION_SETTINGS.SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                hasError: false,
                preferences: action.json.data ? action.json.data.settings : state.preferences,
                preferencesIsLoading: false,
                preferenceHasError: false,
                notificationEmails:
                    action.json.data && action.json.data.emails
                        ? action.json.data.emails
                        : state.notificationEmails,
                notificationPhones:
                    action.json.data && action.json.data.phones
                        ? action.json.data.phones
                        : state.notificationPhones,
            });
        case FETCH_ALL_ACCOUNT_NOTIFICATION_SETTINGS.FAILURE:
            return Object.assign({}, state, {
                isLoading: false,
                hasError: action.err,
                preferencesIsLoading: false,
                preferencesHasError: true,
            });
        case FETCH_ACCOUNT_PREFERENCES.BEGIN:
            return Object.assign({}, state, {
                preferencesIsLoading: true,
            });
        case FETCH_ACCOUNT_PREFERENCES.SUCCESS:
            return Object.assign({}, state, {
                preferencesIsLoading: false,
                preferences: action.json.data ? action.json.data : state.preferences,
            });
        case FETCH_ACCOUNT_PREFERENCES.FAILURE:
            return Object.assign({}, state, {
                preferencesIsLoading: false,
                preferencesHasError: action.err,
            });
        case TOGGLE_GLOBAL_ALERTS: {
            return Object.assign({}, state, {
                preferences: {
                    ...state.preferences,
                    globalReceive: !state.preferences.globalReceive,
                },
            });
        }
        case TOGGLE_ALERT_PREFERENCE:
            return Object.assign({}, state, {
                preferences: {
                    ...state.preferences,
                    [action.alertType]: !state.preferences[action.alertType],
                },
            });
        case REMOVE_NOTIFICATION_EMAIL:
            return Object.assign({}, state, {
                notificationEmails: state.notificationEmails.filter(
                    email => email !== action.email
                ),
                deletedEmails: [...state.deletedEmails, action.email],
            });
        case REMOVE_NOTIFICATION_PHONE:
            return Object.assign({}, state, {
                notificationPhones: state.notificationPhones.filter(
                    phone => phone !== action.phone
                ),
                deletedPhones: [...state.deletedPhones, action.phone],
            });
        case FETCH_PROPERTIES.SUCCESS: {
            if (state.hasPropertiesNotificationSettings) {
                // we already have the notification settings
                if (action.json.length) {
                    // the action has properties data
                    let propertiesNotificationSettings = action.json.reduce((acc, property) => {
                        let notificationSettingsDefault = {
                            notificationDisconnect: false,
                            notificationNoise: false,
                            propertyID: property.id,
                        };
                        // set up a default because the notification settings are a subset of the full properties response
                        let notificationSettings =
                            state.savedPropertiesNotificationSettings[property.id] ||
                            notificationSettingsDefault;
                        return {
                            ...acc,
                            [property.id]: {
                                id: property.id,
                                key: property.key,
                                nickname: property.nickname,
                                tags: property.tags,
                                city: property.city,
                                timezone: property.timezone,
                                type: property.type,
                                reservationStatus: property.reservationStatus,
                                ...notificationSettings,
                            },
                        };
                    }, {});
                    return Object.assign({}, state, {
                        hasPropertiesData: true,
                        savedPropertiesNotificationSettings: propertiesNotificationSettings,
                        updatedPropertiesNotificationSettings: propertiesNotificationSettings,
                    });
                } else {
                    // we have the notifications settings, but the properties response didn't return any data
                    return Object.assign({}, state, {
                        hasPropertiesData: false,
                    });
                }
            } else {
                // we don't have the notification settings yet
                if (action.json.length) {
                    // the properties response returned data
                    let propertiesNotificationSettings = action.json.reduce(
                        (acc, property) => ({
                            ...acc,
                            [property.id]: {
                                id: property.id,
                                key: property.key,
                                nickname: property.nickname,
                                tags: property.tags,
                                city: property.city,
                                timezone: property.timezone,
                                type: property.type,
                                reservationStatus: property.reservationStatus,
                                propertyID: property.id, //key used for joining with notification settings response
                                notificationDisconnect: false, // default
                                notificationNoise: false, // default
                            },
                        }),
                        {}
                    );
                    return Object.assign({}, state, {
                        hasPropertiesData: true,
                        savedPropertiesNotificationSettings: propertiesNotificationSettings,
                        updatedPropertiesNotificationSettings: propertiesNotificationSettings,
                    });
                }
                // we don't have any notification settings data
                // nor any data from the properties response
                return state;
            }
        }
        case FETCH_PROPERTIES_NOTIFICATION_SETTINGS.SUCCESS: {
            if (state.hasPropertiesData) {
                if (action.json && action.json.data && action.json.data.properties) {
                    let propertiesNotificationSettings = Object.values(
                        state.savedPropertiesNotificationSettings
                    ).reduce((acc, property) => {
                        let notificationSettingsDefault = {
                            notificationDisconnect: false,
                            notificationNoise: false,
                            propertyID: property.id,
                        };
                        let notificationSettings =
                            action.json.data.properties.find(
                                notificationSettings =>
                                    notificationSettings.propertyID === property.id
                            ) || notificationSettingsDefault;
                        return {
                            ...acc,
                            [property.id]: {
                                ...property,
                                ...notificationSettings,
                            },
                        };
                    }, {});
                    return Object.assign({}, state, {
                        propertiesNotificationSettingsAreLoading: false,
                        hasPropertiesNotificationSettings: true,
                        savedPropertiesNotificationSettings: propertiesNotificationSettings,
                        updatedPropertiesNotificationSettings: propertiesNotificationSettings,
                    });
                } else {
                    // no json data was returned
                    return Object.assign({}, state, {
                        propertiesNotificationSettingsAreLoading: false,
                        hasPropertiesNotificationSettings: false,
                    });
                }
            } else {
                // we do not have the requisite property data already
                if (action.json && action.json.data && action.json.data.properties) {
                    let propertiesNotificationSettings = action.json.data.properties.reduce(
                        (acc, property) => {
                            return { ...acc, [property.propertyID]: property };
                        }
                    );
                    return Object.assign({}, state, {
                        hasPropertiesNotificationSettings: true,
                        propertiesNotificationSettingsAreLoading: false,
                        savedPropertiesNotificationSettings: propertiesNotificationSettings,
                        updatedPropertiesNotificationSettings: propertiesNotificationSettings,
                    });
                } else {
                    // no json data was returned
                    return Object.assign({}, state, {
                        propertiesNotificationSettingsAreLoading: false,
                        hasPropertiesNotificationSettings: false,
                    });
                }
            }
        }
        case TOGGLE_NOTIFICATION_SUBSCRIPTION: {
            let updatedProperty = state.updatedPropertiesNotificationSettings[action.key];
            return Object.assign({}, state, {
                updatedPropertiesNotificationSettings: {
                    ...state.updatedPropertiesNotificationSettings,
                    [action.key]: {
                        ...updatedProperty,
                        [action.alertType]: !updatedProperty[action.alertType],
                    },
                },
            });
        }
        case TOGGLE_NOTIFICATIONS_SUBSCRIPTIONS: {
            let alertType;
            if (action.col === 'Noise Alerts') alertType = 'notificationNoise';
            if (action.col === 'Disconnect Alerts') alertType = 'notificationDisconnect';
            let updatedProperties = action.properties.reduce((acc, propertyId) => {
                let updatedProperty = state.updatedPropertiesNotificationSettings[propertyId];
                return {
                    ...acc,
                    [propertyId]: { ...updatedProperty, [alertType]: action.nextState },
                };
            }, {});
            return Object.assign({}, state, {
                updatedPropertiesNotificationSettings: {
                    ...state.updatedPropertiesNotificationSettings,
                    ...updatedProperties,
                },
                cols: {
                    ...state.cols,
                    [action.col]: { ...state.cols[action.col], checked: action.nextState },
                },
            });
        }
        case OPEN_ACCOUNT_MODAL:
            return Object.assign({}, state, {
                modalOpen: true,
                modalData: action.modalData,
            });
        case CLOSE_ACCOUNT_MODAL:
            return Object.assign({}, state, { modalOpen: false, modalData: null });
        default:
            return state;
    }
};

export default account;
