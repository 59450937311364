export const getSensorDetailPanelUI = (state) => state.ui.sensors.sensorsPanel;

export const getSensorPhotoExpansion = (state) => state.ui.sensors.sensorsPanel['Overview'].photoExpanded;

export const getSensorPhotoIsLoading = (state) => state.ui.sensors.sensorsPanel['Overview'].photoIsLoading;

export const getSensorPhotoHasError = (state) => state.ui.sensors.sensorsPanel['Overview'].photoHasError;

export const getSensorDetailPanelSlideOutFinished = (state) => state.ui.sensors.sensorsPanel.slideOutFinished;

export const getSensorDetailPanelExpanded = (state) => state.ui.sensors.sensorsPanel.expanded;

export const getSensorDetailSettingsModalOpen = state => state.ui.sensors.sensorsPanel['Settings'].modalOpen;

export const getSensorDetailSettingsModalData = state => state.ui.sensors.sensorsPanel['Settings'].modalData;