import {
    FETCH_PROPERTIES,
    SELECT_PROPERTY_FROM_SEARCH,
    SLIDE_PANEL_END,
    POP_ROW_CLICK,
    POP_ROW_BLUR,
    VIEW_NRS_GRAPH,
    VIEW_NRS_GRAPH_EVENT,
    NAVIGATE_DETAILS_PAGE,
    FETCH_PROPERTY_DETAIL,
    FETCH_SENSOR_DECIBEL_REPORT,
    FETCH_SENSOR_MAC_COUNT_REPORT,
    PAN_GRAPH,
    TOGGLE_DATA_AUTO_REFRESH,
    REFRESH_PROPERTY_DETAIL_SUCCESS,
    TOGGLE_DATA_INTERVAL_CLICK,
    TOGGLE_DATA_INTERVAL_SELECT,
    TOGGLE_DATA_INTERVAL_BLUR,
    TOGGLE_SENSOR_GRAPH_VISIBILITY,
    FETCH_MESSAGES,
    TOGGLE_EDIT_RESERVATION,
    CLOSE_EDIT_RESERVATION,
    ADD_NRS_SETTING,
    DELETE_NRS_SETTING,
    UPDATE_DAY_PICKER_FROM,
    UPDATE_DAY_PICKER_TO,
    TOGGLE_ADD_NOTE,
    OPEN_CONFIRM_DELETE_MODAL,
    CLOSE_CONFIRM_DELETE_MODAL,
    DROPDOWN_SELECT_OPTION,
    DROPDOWN_TOGGLE_CLICK,
    DROPDOWN_BLUR,
} from '../actions';
import tz from 'timezone';
import { dateFormatString } from '../dates';
import { createDefaultDropdownState } from './dropdown';
import createAsyncStateReducer from './async';
import { composeReducers } from './utils';

const fetchPropertiesReducer = createAsyncStateReducer(FETCH_PROPERTIES);

const createDefaultPanelReducer = table => {
    const defaultPanelState = {
        expanded: false,
        slideOutFinished: false,
        panelKey: null,
        detailsPage: 'NRS Graph',
        isLoading: false,
        init: false,
        hasError: null,
        'NRS Graph': {
            interval: { number: 30, unit: 'minutes' },
            intervalExpanded: false,
            sensorGraphsVisible: [],
            displaysMostRecent: false,
            start: tz(new Date(), '-30 minutes', dateFormatString.RFC3339),
            end: tz(new Date(), dateFormatString.RFC3339),
            isLoading: false,
            hasError: null,
            autoRefresh: false,
            eventID: '',
            graphTypeSelected: createDefaultDropdownState({
                value: 'NRS Graph',
                label: 'NRS Graph',
            }).dropdownOptionSelected,
        },
        Sensors: {
            NRSSettingsList: [],
        },
        Messages: {
            isEditingReservation: false,
            isLoading: false,
            hasError: null,
            modalOpen: false,
            modalData: {
                assetName: '',
                assetType: 'reservation',
            },
        },
        Events: {
            start: '2018-06-28 00:00:00',
            end: tz(new Date(), dateFormatString.RFC3339),
            isAddingNote: false,
        },
        Settings: {
            modalOpen: false,
            modalData: {
                assetName: '',
                assetType: 'property',
            },
        },
    };

    const intervalToString = ({ number, unit }) => `${number} ${unit}`;

    const updateToDecibelGraph = (state, action) => {
        let { number: newNumber, unit: newUnit } = state['NRS Graph'].interval;
        let { start: newStart, end: newEnd } = state['NRS Graph'];
        // Decibel graph doesn't support 3hr, 1 day or 7 day views
        const requiresIntervalChange = ['7 days', '1 days', '3 hours'].includes(
            intervalToString(state['NRS Graph'].interval)
        );
        // Decibel graph only allows viewing past hour of data
        const requiresEndsChange = tz(state['NRS Graph'].start) <= tz(new Date(), '-1 hours');
        if (requiresIntervalChange) {
            (newNumber = 1), (newUnit = 'hours');
            newStart = tz(new Date(), '-1 hours', dateFormatString.RFC3339);
            newEnd = tz(new Date(), dateFormatString.RFC3339);
        }
        if (requiresEndsChange) {
            newStart = tz(new Date(), `-${newNumber} ${newUnit}`, dateFormatString.RFC3339);
            newEnd = tz(new Date(), dateFormatString.RFC3339);
        }
        return Object.assign({}, state, {
            'NRS Graph': {
                ...state['NRS Graph'],
                graphTypeSelected: action.option,
                interval: { number: newNumber, unit: newUnit },
                start: newStart,
                end: newEnd,
            },
        });
    };

    const updateToNRSGraph = (state, action) => {
        let { number: newNumber, unit: newUnit } = state['NRS Graph'].interval;
        let { start: newStart, end: newEnd } = state['NRS Graph'];
        // NRS Graph does not allow for 5 minute, 10 minute intervals
        const requiresIntervalChange = ['5 minutes', '10 minutes'].includes(
            intervalToString(state['NRS Graph'].interval)
        );
        if (requiresIntervalChange) {
            // If interval change is required, default to viewing the most recent past 30 minutes
            (newNumber = 30), (newUnit = 'minutes');
            newStart = tz(new Date(), `-${newNumber} ${newUnit}`, dateFormatString.RFC3339);
            newEnd = tz(new Date(), dateFormatString.RFC3339);
        }
        return Object.assign({}, state, {
            'NRS Graph': {
                ...state['NRS Graph'],
                graphTypeSelected: action.option,
                interval: { number: newNumber, unit: newUnit },
                start: newStart,
                end: newEnd,
            },
        });
    };

    const panelReducer = (state, action) => {
        if (state === undefined) {
            state = defaultPanelState;
        }
        if (action.table === table) {
            switch (action.type) {
                case DROPDOWN_SELECT_OPTION:
                    if (action.option.value === 'Decibel Graph') {
                        return updateToDecibelGraph(state, action);
                    } else {
                        return updateToNRSGraph(state, action);
                    }
                case SELECT_PROPERTY_FROM_SEARCH:
                    return Object.assign({}, state, { expanded: true });
                case SLIDE_PANEL_END:
                    return Object.assign({}, state, { slideOutFinished: true });
                case POP_ROW_CLICK:
                    return Object.assign({}, state, { expanded: true, panelKey: action.popKey });
                case POP_ROW_BLUR:
                    return Object.assign({}, state, defaultPanelState);
                case VIEW_NRS_GRAPH:
                    return Object.assign({}, state, {
                        expanded: true,
                        panelKey: action.propertyKey,
                        detailsPage: 'NRS Graph',
                    });
                case VIEW_NRS_GRAPH_EVENT:
                    return Object.assign({}, state, {
                        expanded: true,
                        panelKey: action.propertyKey,
                        detailsPage: 'NRS Graph',
                        'NRS Graph': { ...state['NRS Graph'], eventID: action.eventID },
                    });
                case NAVIGATE_DETAILS_PAGE:
                    return Object.assign({}, state, { detailsPage: action.detailsPage });
                case FETCH_SENSOR_MAC_COUNT_REPORT.BEGIN:
                case FETCH_PROPERTY_DETAIL.BEGIN:
                case FETCH_SENSOR_DECIBEL_REPORT.BEGIN:
                    return Object.assign({}, state, {
                        'NRS Graph': { ...state['NRS Graph'], isLoading: true, hasError: null },
                    });
                // case FETCH_SENSOR_MAC_COUNT_REPORT.SUCCESS: {
                //     let sortedTime = action.json.data
                //         ? action.json.data.map(d => tz(d.timeStamp)).sort()
                //         : [tz(new Date())];
                //     let lastReportedTime = sortedTime.slice(-1);
                //     return Object.assign({}, state, {
                //         init: true,
                //         'NRS Graph': {
                //             ...state['NRS Graph'],
                //             isLoading: false,
                //             hasError: null,
                //             displaysMostRecent: lastReportedTime > tz(new Date(), '-5 minutes'),
                //         },
                //     });
                // } // MAC_FEAT
                case FETCH_PROPERTY_DETAIL.SUCCESS: {
                    let lastReportedTime = action.json.data.map(d => tz(d.time)).slice(-1);
                    return Object.assign({}, state, {
                        init: true,
                        'NRS Graph': {
                            ...state['NRS Graph'],
                            isLoading: false,
                            hasError: null,
                            sensorGraphsVisible: action.json.zones.map(o => true),
                            start: tz(action.json.data[0].time, dateFormatString.RFC3339),
                            end: tz(
                                action.json.data[action.json.data.length - 1].time,
                                dateFormatString.RFC3339
                            ),
                            displaysMostRecent: lastReportedTime > tz(new Date(), '-5 minutes'),
                        },
                    });
                }
                case FETCH_SENSOR_DECIBEL_REPORT.SUCCESS: {
                    let lastReportedTime = action.json
                        .map(sensorData => sensorData.sampleTime)
                        .slice(-1);
                    return Object.assign({}, state, {
                        'NRS Graph': {
                            ...state['NRS Graph'],
                            isLoading: false,
                            hasError: null,
                            displaysMostRecent: lastReportedTime > tz(new Date(), '-5 minutes'),
                        },
                    });
                }
                case FETCH_SENSOR_MAC_COUNT_REPORT.FAILURE:
                case FETCH_PROPERTY_DETAIL.FAILURE:
                case FETCH_SENSOR_DECIBEL_REPORT.FAILURE:
                    return Object.assign({}, state, {
                        'NRS Graph': {
                            ...state['NRS Graph'],
                            isLoading: false,
                            hasError: action.err,
                            displaysMostRecent: false,
                        },
                    });
                case PAN_GRAPH:
                    return Object.assign({}, state, {
                        'NRS Graph': {
                            ...state['NRS Graph'],
                            start: tz(action.start, dateFormatString.RFC3339),
                            end: tz(action.end, dateFormatString.RFC3339),
                        },
                    });
                case TOGGLE_DATA_AUTO_REFRESH:
                    return Object.assign({}, state, {
                        'NRS Graph': {
                            ...state['NRS Graph'],
                            autoRefresh: !state['NRS Graph'].autoRefresh,
                        },
                    });
                case REFRESH_PROPERTY_DETAIL_SUCCESS:
                    return Object.assign({}, state, {
                        init: true,
                        'NRS Graph': {
                            ...state['NRS Graph'],
                            isLoading: false,
                            hasError: null,
                            sensorGraphsVisible: state['NRS Graph'].autoRefresh
                                ? state['NRS Graph'].sensorGraphsVisible
                                : action.json.zones.map(o => true),
                            start: tz(action.json.data[0].time, dateFormatString.RFC3339),
                            end: tz(
                                action.json.data[action.json.data.length - 1].time,
                                dateFormatString.RFC3339
                            ),
                        },
                    });
                case TOGGLE_DATA_INTERVAL_SELECT:
                    return Object.assign({}, state, {
                        'NRS Graph': {
                            ...state['NRS Graph'],
                            interval: { number: action.option.number, unit: action.option.unit },
                            start: action.start,
                            end: action.end,
                        },
                    });
                case TOGGLE_DATA_INTERVAL_BLUR:
                    return Object.assign({}, state, {
                        'NRS Graph': {
                            ...state['NRS Graph'],
                            intervalExpanded: false,
                        },
                    });
                case TOGGLE_SENSOR_GRAPH_VISIBILITY:
                    return Object.assign({}, state, {
                        'NRS Graph': {
                            ...state['NRS Graph'],
                            sensorGraphsVisible: state['NRS Graph'].sensorGraphsVisible.map(
                                (sensor, i) => (i === action.index ? !sensor : sensor)
                            ),
                        },
                    });
                case FETCH_MESSAGES.BEGIN:
                    return Object.assign({}, state, {
                        Messages: {
                            ...state['Messages'],
                            isLoading: true,
                        },
                    });
                case FETCH_MESSAGES.SUCCESS:
                    return Object.assign({}, state, {
                        Messages: {
                            ...state['Messages'],
                            isLoading: false,
                            hasError: null,
                        },
                    });
                case FETCH_MESSAGES.FAILURE:
                    return Object.assign({}, state, {
                        Messages: {
                            ...state['Messages'],
                            isLoading: false,
                            hasError: action.err,
                        },
                    });
                case TOGGLE_EDIT_RESERVATION:
                    return Object.assign({}, state, {
                        Messages: {
                            ...state['Messages'],
                            isEditingReservation: !state['Messages'].isEditingReservation,
                        },
                    });
                case CLOSE_EDIT_RESERVATION:
                    return Object.assign({}, state, {
                        Messages: {
                            ...state['Messages'],
                            isEditingReservation: false,
                        },
                    });
                case ADD_NRS_SETTING:
                    return Object.assign({}, state, {
                        Sensors: {
                            NRSSettingsList: [
                                ...state['Sensors'].NRSSettingsList,
                                {
                                    zoneKey: action.key,
                                    id: Math.random()
                                        .toString(36)
                                        .substr(-5),
                                    threshold: 100,
                                    start: action.start,
                                    end: action.start + 1,
                                },
                            ],
                        },
                    });
                case DELETE_NRS_SETTING:
                    return Object.assign({}, state, {
                        Sensors: {
                            NRSSettingsList: [
                                ...state['Sensors'].NRSSettingsList.filter(
                                    setting => setting.id !== action.id
                                ),
                            ],
                        },
                    });
                case UPDATE_DAY_PICKER_FROM:
                    return Object.assign({}, state, {
                        Events: { ...state['Events'], start: action.day },
                    });
                case UPDATE_DAY_PICKER_TO:
                    return Object.assign({}, state, {
                        Events: { ...state['Events'], end: action.day },
                    });
                case TOGGLE_ADD_NOTE:
                    return Object.assign({}, state, {
                        Events: { ...state['Events'], isAddingNote: !state['Events'].isAddingNote },
                    });
                case OPEN_CONFIRM_DELETE_MODAL:
                    return Object.assign({}, state, {
                        [action.detailsPage]: {
                            ...state[action.detailsPage],
                            modalOpen: true,
                            modalData: action.modalData,
                        },
                    });
                case CLOSE_CONFIRM_DELETE_MODAL:
                    return Object.assign({}, state, {
                        [action.detailsPage]: {
                            ...state[action.detailsPage],
                            modalOpen: false,
                            modalData: defaultPanelState[action.detailsPage].modalData,
                        },
                    });
                default:
                    return state;
            }
        } else {
            return state;
        }
    };
    return {
        defaultState: defaultPanelState,
        reducer: composeReducers([panelReducer, fetchPropertiesReducer], defaultPanelState),
    };
};

export default createDefaultPanelReducer;
