import React from 'react';
import PageLink from '../components/PageLink';
import { Fragment } from 'react';

const MobileNav = ({nav, mobileNav, userName, organizationName}) => (
    <Fragment>
        <div className={nav === 'open'? 'nav-backdrop' : 'nav-backdrop nav-hidden'}>
        </div>
        <nav className={nav === 'open'? 'nav-container' : 'nav-container nav-closed'}>
            <div className='nav-org-user-container'>
                <p className='nav-org-name'>
                    {organizationName}
                </p>
                <p className='nav-user-name'>
                    {`${userName.firstName} ${userName.lastName}`}
                </p>
            </div>
            <div className='nav-item-group'>
                <div className='nav-item'>
                    <PageLink page='admin'>
                        <div className='nav-icon'><i className="fas fa-cog"></i></div>
                        Administrator Tools
                    </PageLink>
                </div>
                <div className='nav-item'>
                    <PageLink page='account'>
                        <div className='nav-icon'><i className="fas fa-user-circle"></i></div>
                        Account Settings
                    </PageLink>
                </div>
            </div>
            <hr className='nav-rule'/>
            <div className='nav-item-group'>
                <div className='nav-item'>
                    <PageLink page='add_property'>
                        <div className='nav-icon'><i className="fas fa-plus"></i></div>
                        Add Property
                    </PageLink>
                </div>
                <div className='nav-item'>
                    <PageLink page='setup_sensor'>
                        <div className='nav-icon'><i className="fas fa-tasks"></i></div>
                        Setup Sensor
                    </PageLink>
                </div>
            </div>
            <hr className='nav-rule'/>
            <div className='nav-item-group'>
                <div className='nav-item'>
                    <PageLink page='store'>
                        <div className='nav-icon'><i className="fas fa-shopping-cart"></i></div>
                        Store
                    </PageLink>
                </div>
                <div className='nav-item'>
                    <div className='nav-icon'><i className='fas fa-sign-out-alt'></i></div>
                    <span className='nav-link'>Sign out</span>
                </div>
            </div>
        
    </nav>
        <nav className={mobileNav === 'default' ? 'nav-container-footer':
                        mobileNav === 'detail'  ? 'hidden':
                        ''}>
            <div className='nav-item-footer'>
                <PageLink page='dashboard' navClassName='nav-link-footer'>
                    <div className='nav-icon-footer'><i className="fas fa-th-large"></i></div>
                    Dashboard
                </PageLink>
            </div>
            <div className='nav-item-footer'>
                <PageLink page='properties' navClassName='nav-link-footer'>
                    <div className='nav-icon-footer'><i className="fas fa-home"></i></div>
                    Properties
                </PageLink>
            </div>
            <div className='nav-item-footer'>
                <PageLink page='sensors' navClassName='nav-link-footer'>
                    <div className='nav-icon-footer'><i className="fas fa-umbrella"></i></div>
                    Sensors
                </PageLink>
            </div>
            <div className='nav-item-footer'>
                <PageLink page='events' navClassName='nav-link-footer'>
                    <div className='nav-icon-footer'><i className="fas fa-exclamation-circle"></i></div>
                    Events
                </PageLink>
            </div>
        </nav>
    </Fragment>
);

export default MobileNav;