import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { searchFocus, searchBlur, toggleUserMenu, searchProperties, logout } from '../actions';
import { toggleNav } from '../actions';
import Header from '../components/Header';
import { searchFocusedSelector } from '../selectors/searchFocusedSelector';
import { getUserOrgName, getUserFirstName, getUserData, getUserMenuExpanded } from '../selectors';
import { getNav } from '../selectors/getNav';
import MobileHeader from '../components-mobile/MobileHeader';
import withResponsive from './withResponsive';

const mapDispatchToProps = dispatch => {
    return {
        onToggleUserMenu: () => dispatch(toggleUserMenu()),
        onSearchFocus: () => dispatch(searchFocus()),
        onSearchBlur: () => dispatch(searchBlur()),
        onToggleClick: () => dispatch(toggleNav()),
        onSearchProperties: search => dispatch(searchProperties(search)),
        onLogout: () => {
            dispatch(push('/login'));
            dispatch(logout());
        },
        dispatch,
    };
};

const mapStateToProps = state => {
    return {
        searchFocused: searchFocusedSelector(state),
        userName: getUserFirstName(state),
        organizationName: getUserOrgName(state),
        user: getUserData(state),
        userMenuExpanded: getUserMenuExpanded(state),
        ...getNav(state),
    };
};

const VisibleHeader = connect(
    mapStateToProps,
    mapDispatchToProps
)(withResponsive({ DesktopComponent: Header, MobileComponent: MobileHeader }));

export default VisibleHeader;
