import React from 'react';
import { connect } from 'react-redux';
import { selectProperty } from '../actions';
import { getSearchAsyncState } from '../selectors';
import { debounce } from '../constants';
import onClickOutside from 'react-onclickoutside';

const PropertySearchOption = ({ id, nickname, city, state, postalCode }) => {
    return (
        <div className="search-option-container" data-id={id}>
            <div className="search-option-header" data-id={id}>
                Property
            </div>
            <div className="search-option-text" data-id={id}>
                {nickname}
            </div>
            <div className="search-option-details" data-id={id}>
                {`${city}, ${state} ${postalCode}`}
            </div>
        </div>
    );
};

const SearchOptionsList = ({ options, onSelectOption }) => (
    <div
        className="search-options-list"
        onClick={e => (e.target.dataset.id ? onSelectOption(e.target.dataset.id) : null)}>
        {options.length ? (
            options.map((option, id) => <PropertySearchOption {...option} key={id} />)
        ) : (
            <div className="search-option-null-result">No Results</div>
        )}
    </div>
);

class SearchSelect extends React.Component {
    constructor() {
        super();
        this.searchOptions = this.searchOptions.bind(this);
        this.selectOption = this.selectOption.bind(this);
        this.toggleOptionsVisible = this.toggleOptionsVisible.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.timeout = null;
        this.state = { value: '', options: [], optionsVisible: false };
        this.debouncedSearch = debounce(search => {
            if (search) {
                this.props.onSearch(search).then(json => {
                    this.setState({ options: json, optionsVisible: true });
                });
            } else {
                this.setState({ options: [] });
            }
        }, 500);
    }
    searchOptions(event) {
        this.setState({ value: event.target.value });
        this.debouncedSearch(event.target.value);
    }
    selectOption(id) {
        this.props.onSelectProperty(id);
        this.setState({ optionsVisible: false });
    }
    toggleOptionsVisible() {
        if (this.state.value) {
            this.setState({ optionsVisible: true });
        }
    }
    handleClickOutside() {
        this.setState({ optionsVisible: false });
    }
    render() {
        return (
            <div className={'search-container'}>
                <input
                    type="text"
                    name="search"
                    className="search-input"
                    value={this.state.value}
                    onChange={this.searchOptions}
                    onFocus={this.toggleOptionsVisible}
                    placeholder={'Search property name...'}
                />
                {this.props.searchAsyncState.isLoading && (
                    <i className="fas fa-circle-notch fa-spin search-spinner"></i>
                )}
                {this.state.optionsVisible && (
                    <SearchOptionsList
                        options={this.state.options}
                        onSelectOption={this.selectOption}
                    />
                )}
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    onSelectProperty: propertyKey => dispatch(selectProperty(propertyKey)),
});

const mapStateToProps = state => ({
    searchAsyncState: getSearchAsyncState(state),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(onClickOutside(SearchSelect));
