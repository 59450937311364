import {
    PROPERTY_DETAIL_ICON_CLICK,
    FETCH_SENSOR_PHOTO,
    CACHE_SENSOR_PHOTO,
    CLEAR_SENSOR_PHOTO,
    TOGGLE_SENSOR_PHOTO,
    CLOSE_SENSOR_PHOTO,
    DELETE_SENSOR,
    POP_ROW_CLICK,
    SLIDE_PANEL_END,
    POP_ROW_BLUR,
    RESET_PANEL,
    NAVIGATE_DETAILS_PAGE,
    OPEN_CONFIRM_DELETE_MODAL,
    CLOSE_CONFIRM_DELETE_MODAL,
} from '../actions';

export const defaultSensorsPanelState = {
    expanded: false,
    slideOutFinished: false,
    detailsPage: 'Overview',
    isLoading: false,
    init: false,
    hasError: null,
    Overview: {
        photoIsLoading: false,
        photoHasError: null,
        photoExpanded: false,
    },
    Settings: {
        modalOpen: false,
        modalData: {
            assetName: '',
            assetType: 'sensor',
        },
    },
};

const sensorsPanel = (state, action) => {
    if (state === undefined) {
        state = defaultSensorsPanelState;
    }
    switch (action.type) {
        case PROPERTY_DETAIL_ICON_CLICK:
            return Object.assign({}, state, defaultSensorsPanelState);
        case FETCH_SENSOR_PHOTO.BEGIN:
            return Object.assign({}, state, {
                Overview: { ...state['Overview'], photoIsLoading: true },
            });
        case CACHE_SENSOR_PHOTO:
            return Object.assign({}, state, {
                Overview: { ...state['Overview'], photoIsLoading: false },
            });
        case FETCH_SENSOR_PHOTO.FAILURE:
            return Object.assign({}, state, {
                Overview: {
                    ...state['Overview'],
                    photoIsLoading: false,
                    photoHasError: action.err,
                },
            });
        case CLEAR_SENSOR_PHOTO:
            return Object.assign({}, state, {
                slideOutFinished: false,
                Overview: defaultSensorsPanelState['Overview'],
            });
        case TOGGLE_SENSOR_PHOTO:
            return Object.assign({}, state, {
                Overview: { ...state['Overview'], photoExpanded: !state['Overview'].photoExpanded },
            });
        case CLOSE_SENSOR_PHOTO:
            return Object.assign({}, state, {
                Overview: { ...state['Overview'], photoExpanded: false },
            });
        case DELETE_SENSOR.SUCCESS:
            return Object.assign({}, state, { detailsPage: 'Overview' });
        case DELETE_SENSOR.FAILURE:
            return Object.assign({}, state, { detailsPage: 'Overview', hasError: action.error });
    }
    if (action.table === 'sensors') {
        switch (action.type) {
            case POP_ROW_CLICK:
                return Object.assign({}, state, { expanded: true });
            case SLIDE_PANEL_END:
                return Object.assign({}, state, { expanded: true, slideOutFinished: true });
            case POP_ROW_BLUR:
            case RESET_PANEL:
                return Object.assign({}, state, defaultSensorsPanelState);
            case NAVIGATE_DETAILS_PAGE:
                return Object.assign({}, state, { detailsPage: action.detailsPage });
            case OPEN_CONFIRM_DELETE_MODAL:
                return Object.assign({}, state, {
                    [action.detailsPage]: {
                        ...state[action.detailsPage],
                        modalOpen: true,
                        modalData: action.modalData,
                    },
                });
            case CLOSE_CONFIRM_DELETE_MODAL:
                return Object.assign({}, state, {
                    [action.detailsPage]: {
                        ...state[action.detailsPage],
                        modalOpen: false,
                        modalData: defaultSensorsPanelState[action.detailsPage].modalData,
                    },
                });
            default:
                return state;
        }
    } else {
        return state;
    }
};

export default sensorsPanel;
