import modalReducer, { defaultModalState } from './modal';
import tz from 'timezone';
import { FETCH_RESERVATIONS, UPDATE_FILTERS, CLEAR_FILTERS } from '../actions';

export const defaultReservationsTableState = {
    headers: {
        Name: null,
        Guests: null,
        Contact: null,
        Dates: null,
        Property: null,
        Status: null,
        '': null,
    },
    init: false,
    rows: [],
    isLoading: true,
    hasError: null,
    modalOpen: defaultModalState.modalOpen,
    modalData: defaultModalState.modalData,
    filters: {
        tags: [],
    },
};

const reservationsTable = (state, action) => {
    if (state === undefined) {
        state = defaultReservationsTableState;
    }
    if (action.table === 'reservations') {
        switch (action.type) {
            case UPDATE_FILTERS:
                return Object.assign({}, state, {
                    filters: { tags: action.filters.tags },
                });
            case CLEAR_FILTERS:
                return Object.assign({}, state, {
                    filters: { tags: [] },
                });
            default:
                return Object.assign(
                    {},
                    state,
                    modalReducer({ modalOpen: state.modalOpen, modalData: state.modalData }, action)
                );
        }
    }
    switch (action.type) {
        case FETCH_RESERVATIONS.SUCCESS:
            // TODO: possible race condition with fetch properties success?
            return Object.assign({}, state, { isLoading: false, hasError: null, init: true });
        case FETCH_RESERVATIONS.FAILURE:
            return Object.assign({}, state, {
                isLoading: false,
                hasError: 'Error fetching reservations',
            });
        default:
            return state;
    }
};

export default reservationsTable;
