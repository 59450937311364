/**
 * @description Composes reducers so that [f,g,h] becomes (state, action) => f(g(h(state, action), action), action)
 * @param {Object} defaultState default state passed to innermost reducer
 * @param {Array} reducers Array of reducers to compose
 */
export const composeReducers = (reducers, defaultState) => (state = defaultState, action) =>
    reducers.reduceRight((acc, curr) => curr(acc, action), state);
/**
 * @description Utility to update a specific object in an array
 * @param {Array} array Relevant slice of state to update
 * @param {Object} functions
 *  @param {Function} functions.find function to determine the object to update. Return a boolean when passed an object in the array.
 *  @param {Function} functions.update function to update the object. Passed the object, should return the updated object
 * @returns The array with the object updated
 */
export const updateObjectInArray = (array, { find = () => false, update = _ => _ }) => {
    return array.map(object => {
        if (find(object)) return update(object);
        return object;
    });
};

/**
 * @description Utility to update a specific object in an array, or add it if it doesn't already exist
 * @param {Array} array Relevant slice of state to update
 * @param {Object} functions
 *  @param {Function} functions.find function to determine the object to update. Return a boolean when passed an object in the array.
 *  @param {Function} functions.update function to update the object. Passed the object (if found) or {} (if not found), should return the updated object
 * @returns The array with the object updated, or added to it if not found
 */

export const addOrUpdateObjectInArray = (array, { find = () => false, update = _ => _ }) => {
    for (let index in array) {
        if (find(array[index])) {
            array[index] = update(array[index]);
            return array;
        }
    }
    return [...array, update({})];
};
