import React from 'react';
import { fetchAppData } from '../actions';

const hasAppData = WrappedComponent => {
    class AppWithData extends React.Component {
        componentDidMount() {
            if (!this.props.appData.hasSuccess) {
                this.props.dispatch(fetchAppData());
            }
        }
        render() {
            return <WrappedComponent {...this.props} />;
        }
    }
    return AppWithData;
};

export default hasAppData;
