import tz from 'timezone';

export const timezoneFormat = tz([
    require('timezone/America'),
    require('timezone/US'),
    require('timezone/Pacific/Honolulu'),
]);

export const dateFormatString = Object.freeze({
    /**
     * Module of date format strings
     * Used with the 'timezone' library (https://www.npmjs.com/package/timezone)
     * Strings follow the 'strftime' syntax (http://man7.org/linux/man-pages/man3/strftime.3.html#DESCRIPTION)
     */
    get RFC3339AndOffset() {
        /**
         * Useful for formatting date-time strings in Redux store when timezone information is available
         * Follows RFC 3339 for date string, with timezone offset in RFC 822
         * Note the offset string supports the GNU extension to RFC 822 (http://bigeasy.github.io/timezone/#section-67)
         *
         * Example: October 31, 2019 at midnight in CDT returns 2019-10-31 00:00:00-05:00
         */
        return '%Y-%m-%d %H:%M:%S%:z';
    },
    get RFC3339() {
        /**
         *
         * This is equivalent to "%F %T" in GNU date, but use this because legibility
         *
         * Example: October 31, 2019 at midnight in CDT returns 2019-10-31 05:00:00 (after timezone conversion)
         */
        return '%Y-%m-%d %H:%M:%S';
    },
    get toServer() {
        /**
         * Equivalent to fullDateTime
         * Useful for formatting dates to make API calls
         * Follows RFC 3339 for date string
         * Assumes time is given in UTC with offset === 0,
         * meaning timezone conversion needs to happen beforehand.
         * This is equivalent to "%F %T" in GNU date, but use this because legibility
         *
         * Example: October 31, 2019 at midnight in CDT returns 2019-10-31 05:00:00 (after timezone conversion)
         */
        return '%Y-%m-%d %H:%M:%S';
    },
    get yearMonthDay() {
        /**
         * Useful for formatting dates to display to the user in areas of application where we only care about the year, month, and day
         *
         * Example: October 31, 2019 at midnight in CDT returns 2019-10-31
         */
        return '%Y-%m-%d';
    },
    get shortDate() {
        /**
         * Useful for presenting a short, human-readable date
         *
         * Example: October 31, 2019 at midnight in CDT returns Thu 31
         */
        return '%a %-d';
    },
    get clockTime() {
        /**
         * Similar to the format displayed on a digital clock
         *
         * Example: October 31, 2019 at midnight in CDT returns 12:00 AM
         */
        return '%-I:%M %p';
    },
    get prettyDate() {
        /**
         * Useful for presenting a verbose, human-readable date
         *
         * Example: October 31, 2019 at midnight in CDT returns Thursday, Oct. 31 2019
         */
        return '%A, %b. %d %Y';
    },
    get prettyLocalDateTime() {
        /**
         * Useful for presenting a verbose, human-readable date-time with timezones
         *
         * Example: October 31, 2019 at midnight in CDT returns 12:00 AM CDT, 10/31/2019
         */
        return '%-I:%M %p %Z, %m/%d/%Y';
    },
});

/**
 * @description Converts a local Date object to a UTC + offset RFC 3339 date-time string with the time set to 00:00:00 (Midnight)
 * Useful when you only care about the date and not the time but you need both
 * @param {Object} argsObj day, timezone
 * @param {Date} argsObj.day Date object representing the day
 * @param {String} argsObj.timezone name of timezone (e.g. 'America/Chicago')
 * @returns a UTC + offset RFC 3339 date-time string (ex. 2019-10-31 00:00:00-05:00)
 */
export const dateToUTCString = ({ day, timezone }) =>
    `${timezoneFormat(day, '%Y-%m-%d', timezone)} 00:00:00${timezoneFormat(day, '%^z', timezone)}`;

/**
 * @description Converts a local Date object to a date-time string with time set to 00:00:00
 * Similar to `dateToUTCString`; resulting string does not specify offset
 * @param {Object} argsObj day, timezone
 *  @param {Date} argsObj.day Date object representing the day
 *  @param {String} argsObj.timezone name of timezone (e.g. 'America/Chicago')
 * @returns RFC 3339 date-time string (ex. 2019-10-31 00:00:00)
 */
export const getFloorDate = ({ day, timezone }) =>
    `${timezoneFormat(day, '%Y-%m-%d', timezone)} 00:00:00`;
