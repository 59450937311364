import { asyncActionCreator } from './actions.async';
import { REFRESH_USER_TOKEN } from './actions.types';
import { authenticationApi, defaultPortalClient } from '../remote2';
import { setRefreshCookies } from '../auth';
import { logout } from './actions.user';

export const refreshUserToken = refreshToken =>
    asyncActionCreator({
        type: REFRESH_USER_TOKEN,
        isProtected: false,
        createThunk: refreshToken => dispatch => {
            return authenticationApi
                .authenticationRefreshWithHttpInfo({ refreshToken })
                .then(({ data, response }) => {
                    const { userToken, expiresAt, refreshToken: newRefreshToken } = data.data;
                    setRefreshCookies({ expiresAt, refreshToken: newRefreshToken });
                    defaultPortalClient.setApiKey(userToken);
                    return data.data;
                })
                .catch(err => {
                    dispatch(logout());
                });
        },
    })(refreshToken);
