export * from './actions.types';
export * from './actions.dashboard';
export * from './actions.dropdown';
export * from './actions.header';
export * from './actions.user';
export * from './actions.properties';
export * from './actions.data';
export * from './actions.panel';
export * from './actions.defaultPanel';
export * from './actions.sensors';
export * from './actions.sensors.panel';
export * from './actions.account';
export * from './actions.addProperty';
export * from './actions.mobile.nav';
export * from './actions.reservations';
export * from './actions.admin';
export * from './actions.adminIntegration';
export * from './actions.messages';
export * from './actions.events';
export * from './actions.filter';
export * from './actions.modal';
export * from './actions.dayPicker';

export const toggleNav = () => {
    return {
        type: 'TOGGLE_NAV',
    };
};
