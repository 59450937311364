import React from 'react';
import loadable from '@loadable/component';
import { Route, Switch, Redirect } from 'react-router-dom';
import RedirectExternal from './RedirectExternal';
import AppRoute from './AppRoute';
import ImpersonateRoute from './ImpersonateRoute';
import AdminRoute from './AdminRoute';

import NoMatch from './NoMatch';

const VisibleDashboard = loadable(() => import(/* webpackPrefetch: true, webpackChunkName: "VisibleDashboard" */ '../containers/VisibleDashboard'));
const VisibleProperties = loadable(() => import(/* webpackPrefetch: true, webpackChunkName: "VisibleProperties" */ '../containers/VisibleProperties'));
const VisibleSensors = loadable(() => import(/* webpackPrefetch: true, webpackChunkName: "VisibleSensors" */ '../containers/VisibleSensors'));
// const VisibleReservations = loadable(() => import(/* webpackPrefetch: true, webpackChunkName: "VisibleReservations" */ '../containers/VisibleReservations'));
const VisibleEvents = loadable(() => import(/* webpackPrefetch: true, webpackChunkName: "VisibleEvents" */ '../containers/VisibleEvents'));
const VisibleAddProperty = loadable(() => import(/* webpackPrefetch: true, webpackChunkName: "VisibleAddProperty" */ '../containers/VisibleAddProperty'));
const VisibleAccount = loadable(() => import(/* webpackPrefetch: true, webpackChunkName: "VisibleAccount" */ '../containers/VisibleAccount'));
const VisibleAdmin = loadable(() => import(/* webpackPrefetch: true, webpackChunkName: "VisibleAdmin" */ '../containers/VisibleAdmin'));

const Main = () => (
    <Switch>
        <ImpersonateRoute path='/impersonate'/>
        <Route path='/store'>
            <RedirectExternal to='https://checkout.noiseaware.io/'/>
        </Route>
        <Route path='/login'>
            <Redirect to='/'/>
        </Route>
        <AppRoute path='/'>
            <Route exact path='/' component={VisibleDashboard}/>
            <Route path='/dashboard' component={VisibleDashboard}/>
            <Route path='/properties' component={VisibleProperties}/>
            <Route path='/sensors' component={VisibleSensors}/>
            {/* <Route path="/reservations" component={VisibleReservations}/> */}
            <Route path='/events' component={VisibleEvents} />
            <Route path='/add_property' component={VisibleAddProperty}/>
            <Route path='/account' component={VisibleAccount}/>
            <AdminRoute path='/admin' component={VisibleAdmin}/>
            <Route path='/dashboard_store'>
                <RedirectExternal to='https://checkout.noiseaware.io/'/>
            </Route>
        </AppRoute>
        <Route component={NoMatch}/>
    </Switch>
);

export default Main;
