import React, { Fragment } from 'react';
import { Desktop, Mobile } from './Responsive';

const withResponsive = ({ DesktopComponent, MobileComponent }) =>
    class ResponsiveWrappedComponent extends React.Component {
        constructor(props) {
            super(props);
        }
        render() {
            return (
                <Fragment>
                    <Desktop>
                        <DesktopComponent {...this.props} />
                    </Desktop>
                    <Mobile>
                        <MobileComponent {...this.props} />
                    </Mobile>
                </Fragment>
            );
        }
    };

export default withResponsive;
