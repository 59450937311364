import { asyncActionCreator } from './actions.async';
import { FETCH_INTEGRATIONS_DATA, FETCH_INTEGRATIONS_LIST_DATA } from './actions.types';
import { integrationSighV1Api } from '../remote2';
import {
    fetchIntegrationPropertiesSuccess,
    fetchIntegrationNewPropertiesBegin,
    fetchIntegrationNewPropertiesSuccess,
} from './actions.admin';

export const fetchIntegrationsList = asyncActionCreator({
    type: FETCH_INTEGRATIONS_LIST_DATA,
    createThunk: () => dispatch => {
        return integrationSighV1Api.integrationSighV1GetIntegrationsForPermittedUserList();
    },
});

export const fetchIntegrations = asyncActionCreator({
    type: FETCH_INTEGRATIONS_DATA,
    createThunk: () => dispatch => {
        return integrationSighV1Api.integrationSighV1GetIntegrationsForPermittedUser();
    },
});

export function fetchIntegrationProperties({ name, ...integrationProps }) {
    return dispatch => {
        return integrationSighV1Api
            .integrationSighV1GetPaginatedProperties(-1, name, 0)
            .then(json =>
                dispatch(fetchIntegrationPropertiesSuccess({ name, ...integrationProps }, json))
            )
            .catch(err => {
                throw new Error('failed to fetch integration properties');
            });
    };
}

export function fetchIntegrationNewProperties({ name, ...integrationProps }) {
    return dispatch => {
        dispatch(fetchIntegrationNewPropertiesBegin());
        return integrationSighV1Api
            .integrationSighV1GetPaginatedUnimportedProperties(-1, name, 0)
            .then(json =>
                dispatch(fetchIntegrationNewPropertiesSuccess({ name, ...integrationProps }, json))
            )
            .catch(err => {
                throw new Error('failed to fetch unimported properties');
            });
    };
}
