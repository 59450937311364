import {
    put,
    take,
    call,
    select,
    fork,
    race,
    cancel,
    flush,
    actionChannel,
    all,
} from 'redux-saga/effects';
import {
    resetPanel,
    fetchPropertyDetailBegin,
    fetchPropertyDetailSuccess,
    fetchPropertyDataSuccess,
    fetchPropertyDataFailure,
    fetchPropertyDetailFailure,
    fetchSensorDecibelReportBegin,
    fetchSensorDecibelReportSuccess,
    fetchSensorDecibelReportFailure,
    fetchSensorMacCountReportBegin,
    fetchSensorMacCountReportSuccess,
    fetchSensorMacCountReportFailure,
} from '../actions';
import {
    POP_ROW_BLUR,
    HIDE_PANEL_CLICK,
    SENSOR_DETAIL_ICON_CLICK,
    FETCH_SENSORS,
    FETCH_PROPERTY_DETAIL,
    FETCH_SENSOR_DECIBEL_REPORT,
    FETCH_SENSOR_MAC_COUNT_REPORT,
    SLIDE_PANEL_END,
    DROPDOWN_SELECT_OPTION,
} from '../actions';
import { getPropertyDetailGraphEndpoints, getPropertySensors } from '../selectors';
import { startAndEndFilters } from '../constants';
import { macApi, miscSighV1Api, propertiesSighV1Api } from '../remote2';

const actionsThatClosePropertyDetailPanel = [
    POP_ROW_BLUR,
    HIDE_PANEL_CLICK,
    '@@router/LOCATION_CHANGE',
    SENSOR_DETAIL_ICON_CLICK,
];

export function* fetchSensorMacCountReport({ table, propertyKey, start, end }) {
    try {
        const data = yield call(
            { context: macApi, fn: macApi.macList },
            {
                filter: [
                    { key: 'key', operator: 'in', value: propertyKey },
                    ...startAndEndFilters(start, end),
                ],
            }
        );
        yield put(fetchSensorMacCountReportSuccess(table, data, propertyKey));
    } catch (err) {
        yield put(fetchSensorMacCountReportFailure(err, table));
    }
}

function* fetchSensorDecibelReport({ table, sensorKey, propertyKey, start, end }) {
    try {
        // const data = yield call({ context: api, fn: api.sensorReportGet }, sensorKey, start, end);
        const data = yield call(
            { context: miscSighV1Api, fn: miscSighV1Api.miscSighV1GetTelemetryData },
            end,
            sensorKey,
            start
        );
        yield put(fetchSensorDecibelReportSuccess('properties', data, sensorKey, propertyKey));
    } catch (err) {
        yield put(fetchSensorDecibelReportFailure(err, table));
    }
}

function* fetchPropertyDecibelData({ table, propertyKey, start, end }) {
    try {
        yield put(fetchSensorDecibelReportBegin(table));
        const sensorsByProperty = yield select(getPropertySensors, { propertyKey });
        yield all(
            sensorsByProperty.map(sensor => {
                return call(fetchSensorDecibelReport, {
                    sensorKey: sensor.key,
                    propertyKey,
                    start,
                    end,
                    table,
                });
            })
        );
    } catch (err) {
        put(fetchSensorDecibelReportFailure(err, table));
    }
}

function* fetchPropertyData({ table, propertyKey, start, end, eventID }) {
    try {
        yield put(fetchPropertyDetailBegin('properties'));

        const data = yield call(
            // { context: api, fn: api.propertiesG},
            {
                context: propertiesSighV1Api,
                fn: propertiesSighV1Api.propertiesSighV1GetGraphData,
            },
            propertyKey,
            {
                start: start,
                end: end,
                eventID,
            }
        );
        yield put(fetchPropertyDataSuccess(data, propertyKey));
        yield put(fetchPropertyDetailSuccess(table, data));
    } catch (err) {
        yield put(fetchPropertyDataFailure('Error: property not found'));
        yield put(fetchPropertyDetailFailure('Error: property not found', table));
    }
}

export function* watchFetchPropertyData({ table, propertyKey, start, end, eventID }) {
    function* propertyDataActions() {
        yield FETCH_PROPERTY_DETAIL.BEGIN;
        yield FETCH_PROPERTY_DETAIL.SUCCESS;
    }
    const fetchPropertyDataSaga = yield fork(fetchPropertyData, {
        propertyKey,
        start,
        end,
        eventID,
        table,
    });
    for (let nextAction of propertyDataActions()) {
        // TODO: refactor to avoid iterating over generator
        let { dataAction, cancelAction } = yield race({
            dataAction: take(nextAction),
            cancelAction: take(actionsThatClosePropertyDetailPanel),
        });
        if (cancelAction) {
            yield cancel(fetchPropertyDataSaga);
            break;
        }
    }
}

function* watchFetchPropertyDecibelData({ table, propertyKey, start, end }) {
    function* propertyDataActions() {
        yield FETCH_SENSOR_DECIBEL_REPORT.BEGIN;
        yield FETCH_SENSOR_DECIBEL_REPORT.SUCCESS;
    }
    const fetchPropertyDecibelDataSaga = yield fork(fetchPropertyDecibelData, {
        propertyKey,
        start,
        end,
        table,
    });
    for (let nextAction of propertyDataActions()) {
        // TODO: refactor to avoid iterating over generator
        let { dataAction, cancelAction } = yield race({
            dataAction: take(nextAction),
            cancelAction: take(actionsThatClosePropertyDetailPanel),
        });
        if (cancelAction) {
            yield cancel(fetchPropertyDecibelDataSaga);
            break;
        }
    }
}

export function* watchFetchPropertyMacCountData({ table, propertyKey, start, end }) {
    function* propertyDataActions() {
        yield FETCH_SENSOR_MAC_COUNT_REPORT.BEGIN;
        yield FETCH_SENSOR_MAC_COUNT_REPORT.SUCCESS;
    }
    const fetchPropertyMacCountDataSaga = yield fork(fetchSensorMacCountReport, {
        table,
        propertyKey,
        start,
        end,
    });
    for (let nextAction of propertyDataActions()) {
        // TODO: refactor to avoid iterating over generator
        let { dataAction, cancelAction } = yield race({
            dataAction: take(nextAction),
            cancelAction: take(actionsThatClosePropertyDetailPanel),
        });
        if (cancelAction) {
            yield cancel(fetchPropertyMacCountDataSaga);
            break;
        }
    }
}

export function* watchGraphTypeChangeAndFetchData({ propertyKey, table }) {
    let lastTask;
    while (true) {
        const action = yield take(DROPDOWN_SELECT_OPTION);
        if (lastTask) yield cancel(lastTask);
        const { start, end } = yield select(getPropertyDetailGraphEndpoints);
        if (action.option.value === 'NRS Graph') {
            lastTask = yield fork(watchFetchPropertyData, { table, propertyKey, start, end });
        } else if (action.option.value === 'Decibel Graph') {
            lastTask = yield fork(watchFetchPropertyDecibelData, {
                table,
                propertyKey,
                start,
                end,
            });
        } else if (action.option.value === 'MacCount Graph') {
            lastTask = yield fork(watchFetchPropertyMacCountData, {
                table,
                propertyKey,
                start,
                end,
            });
        }
    }
}

export function* fetchGraphAssets({ propertyKey, eventID, table }) {
    const { start, end } = yield select(getPropertyDetailGraphEndpoints);
    const cancelActionChannel = yield actionChannel(actionsThatClosePropertyDetailPanel);
    yield fork(watchGraphTypeChangeAndFetchData, { propertyKey, table });
    yield all([
        call(watchFetchPropertyData, { table, propertyKey, start, end, eventID }),
        // call(watchFetchPropertyDecibelData, { propertyKey, start, end }),
        // call(watchFetchPropertyMacCountData, { table, propertyKey, start, end }), // MAC_FEAT
    ]);
    const cancelActions = yield flush(cancelActionChannel);
    if (cancelActions.length) {
        yield put(resetPanel(table));
    }
}

export default function* watchFetchPropertyDetailGraphAssets() {
    // set up a buffer for SLIDE_PANEL_END actions until we have the sensor data
    const slidePanelEndChannel = yield actionChannel(SLIDE_PANEL_END);
    yield take(FETCH_SENSORS.SUCCESS);
    const slidePanelEndActions = yield flush(slidePanelEndChannel);
    if (slidePanelEndActions.length) {
        const propertyPanelAction = slidePanelEndActions.find(
            action => action.table === 'properties'
        );
        if (propertyPanelAction) {
            yield fork(fetchGraphAssets, {
                propertyKey: propertyPanelAction.propertyKey,
                eventID: propertyPanelAction.eventID,
                table: 'properties',
            });
        }
    }
    let lastTask;
    while (true) {
        let action = yield take(SLIDE_PANEL_END);
        if (action.table === 'properties') {
            if (lastTask) yield cancel(lastTask);
            lastTask = yield fork(fetchGraphAssets, {
                propertyKey: action.propertyKey,
                eventID: action.eventID,
                table: 'properties',
            });
        }
    }
}
