import { createSelector } from 'reselect';
import { withRoot } from '../constants';
import { propertiesAppliedFiltersFormatter, getFilters, getFilterOpen } from '../reducers/filter';
import { createTableHeadersDropdownSelector } from '../reducers/tableHeaderDropdown';

const mountSelector = state => state.ui.admin;

export const adminIsAddingUserSelector = state => state.ui.admin.isAddingUser;

export const adminIsEditingUserSelector = state => state.ui.admin.isEditingUser;

export const getAdminAddUserPermissionsColumns = state =>
    state.ui.admin['Property Permissions'].cols;

export const getAdminAddUserPermissionsHeaders = state =>
    state.ui.admin['Property Permissions'].headers;

export const adminAddUserPermissionsHeadersSelector = createTableHeadersDropdownSelector(
    getAdminAddUserPermissionsHeaders
);

export const getAdminAddUserPropertyPermissions = state =>
    state.ui.admin['Property Permissions'].properties;

export const getUserPropertyPermissionsFilters = withRoot(mountSelector, getFilters);

export const getAdminFiltersVisibility = withRoot(mountSelector, getFilterOpen);

export const getAdminModalData = state => state.ui.admin.modalData;

export const getAdminModalOpen = state => state.ui.admin.modalOpen;

export const getAdminPaymentsHeaders = state => state.ui.admin['Payments'].headers;

export const getAdminUsersHeaders = state => state.ui.admin['Users'].headers;

export const adminUsersHeadersSelector = createTableHeadersDropdownSelector(getAdminUsersHeaders);

export const getAdminEditUserIsLoading = state => state.ui.admin['Edit User'].isLoading;

export const getAdminEditUserHasError = state => state.ui.admin['Edit User'].hasError;

export const getExpandedUserRow = state => state.ui.admin.expandedRow;

export const getAdminIntegrations = state => state.ui.admin.integrations;

export const getAdminFiltersApplied = createSelector(
    getUserPropertyPermissionsFilters,
    propertiesAppliedFiltersFormatter
);
